<template>
  <div class="heatmap-container">
    <!-- Render a heatmap for each symbol -->
    <div v-for="symbol in symbols" :key="symbol" class="heatmap-section">
      <div class="symbol-header" v-if="isMultiSymbol">{{ symbol }}</div>
      
      <div class="heatmap-grid">
        <!-- Month headers -->
        <div class="month-headers">
          <div v-for="month in months" :key="month" class="month-header">
            {{ month }}
          </div>
        </div>
        
        <!-- Year rows -->
        <div v-for="year in years" :key="year" class="year-row">
          <!-- Year label -->
          <div class="year-label">{{ year }}</div>
          
          <!-- Month cells -->
          <div class="months-container">
            <div v-for="(month, index) in months" :key="month" class="month-cell-wrapper">
              <div 
                class="month-cell"
                :class="getReturnClass(getMonthReturn(symbol, year, index + 1))"
              >
                <span v-if="getMonthReturn(symbol, year, index + 1) !== null" class="cell-text">
                  {{ formatNumber(getMonthReturn(symbol, year, index + 1)) }}%
                </span>
                <div class="tooltip">
                  {{ formatMonthTooltip(year, month, getMonthReturn(symbol, year, index + 1)) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Average row -->
        <div class="year-row summary-row average-row">
          <div class="year-label">Average</div>
          <div class="months-container">
            <div v-for="(avg, index) in getSymbolSummary(symbol)?.averages" :key="'avg-'+index" class="month-cell-wrapper">
              <div 
                class="month-cell"
                :class="getReturnClass(avg)"
              >
                <span v-if="avg !== null" class="cell-text">{{ formatNumber(avg) }}%</span>
                <div class="tooltip">
                  Average for {{ months[index] }}: {{ formatNumber(avg) }}%
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Median row -->
        <div class="year-row summary-row">
          <div class="year-label">Median</div>
          <div class="months-container">
            <div v-for="(median, index) in getSymbolSummary(symbol)?.medians" :key="'med-'+index" class="month-cell-wrapper">
              <div 
                class="month-cell"
                :class="getReturnClass(median)"
              >
                <span v-if="median !== null" class="cell-text">{{ formatNumber(median) }}%</span>
                <div class="tooltip">
                  Median for {{ months[index] }}: {{ formatNumber(median) }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeatmapMonthly',
  
  props: {
    timeframe: {
      type: String,
      required: true
    },
    monthlyReturns: {
      type: [Array, Object],
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    symbols: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },

  computed: {
    years() {
      // Get all years from all symbols
      const allYears = new Set()
      if (Array.isArray(this.monthlyReturns)) {
        this.monthlyReturns.forEach(r => allYears.add(r.x))
      } else {
        this.symbols.forEach(symbol => {
          const returns = this.monthlyReturns[symbol]?.returns || []
          returns.forEach(r => allYears.add(r.x))
        })
      }
      return [...allYears].sort((a, b) => b - a)
    },

    // Check if we have multiple symbols
    isMultiSymbol() {
      return !Array.isArray(this.monthlyReturns) && this.symbols.length > 1
    }
  },

  methods: {
    getMonthReturn(symbol, year, month) {
      if (Array.isArray(this.monthlyReturns)) {
        const found = this.monthlyReturns.find(r => r.x === year && r.y === month)
        return found ? found.v : null
      }
      
      const symbolData = this.monthlyReturns[symbol]?.returns || []
      const found = symbolData.find(r => r.x === year && r.y === month)
      return found ? found.v : null
    },

    getSymbolSummary(symbol) {
      if (Array.isArray(this.monthlyReturns)) {
        return this.summary
      }
      return this.summary[symbol]
    },

    formatNumber(num) {
      if (num === null || num === undefined) return '0.0'
      return Number(num).toFixed(1)
    },

    getReturnClass(change) {
      if (change === null || change === undefined) return 'neutral'
      if (change <= -15) return 'extreme-negative'
      if (change <= -10) return 'negative'
      if (change <= -5) return 'slight-negative'
      if (change < 0) return 'neutral-negative'
      if (change === 0) return 'neutral'
      if (change <= 5) return 'neutral-positive'
      if (change <= 10) return 'slight-positive'
      if (change <= 15) return 'positive'
      return 'extreme-positive'
    },

    formatMonthTooltip(year, month, value) {
      if (value === null || value === undefined) return 'No data'
      return `${month} ${year}: ${this.formatNumber(value)}%`
    }
  }
}
</script>

<style scoped>
.heatmap-container {
  height: auto;
  width: 100%;
  overflow: auto;
  font: 12px 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heatmap-grid {
  min-width: max-content;
  position: relative;
  width: fit-content;
}

.year-row {
  display: flex;
  align-items: center;
  height: 38px;
  margin-bottom: 2px;
}

.year-label {
  width: 4rem;
  color: #00ff00;
  font-size: 13px;
  text-align: right;
  padding-right: 0.75rem;
  font-weight: bold;
}

.months-container {
  display: flex;
  flex-grow: 1;
  height: 38px;
}

.month-cell-wrapper {
  width: 86px;
  height: 38px;
  padding: 0 1px;
}

.month-cell {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 1px solid currentColor;
  background: currentColor;
  color: currentColor;
  user-select: none;
}

.month-headers {
  position: absolute;
  top: -1.25rem;
  left: 4rem;
  display: flex;
  height: 1.25rem;
}

.month-header {
  width: 86px;
  font-size: 11px;
  color: #00ff00;
  text-align: center;
  padding: 0 1px;
  user-select: none;
}

.tooltip {
  display: none;
  position: absolute;
  background: #000000;
  border: 1px solid #333;
  padding: 0.625rem 0.875rem;
  z-index: 1000;
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.625rem;
  font-size: 14px;
  pointer-events: none;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.month-cell:hover {
  outline: 1px solid #666666;
  z-index: 999;
}

.month-cell:hover .tooltip {
  display: block;
}

.cell-text {
  color: #000000;
  font-size: 13px;
  line-height: 38px;
  font-weight: 500;
  z-index: 1;
}

.summary-section {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--terminal-border);
  padding-bottom: 1rem;
}

.summary-section .year-label {
  color: #00ff00;
}

.extreme-positive { color: #00ff00; }
.positive { color: #00cc00; }
.slight-positive { color: #009900; }
.neutral-positive { color: #006600; }
.neutral { color: #333333; }
.neutral-negative { color: #660000; }
.slight-negative { color: #990000; }
.negative { color: #cc0000; }
.extreme-negative { color: #ff0000; }

/* Return classes with colors (applied to border and background) */
.extreme-negative { color: #ff3b3b; }
.negative { color: #cc2f2f; }
.slight-negative { color: #992323; }
.neutral-negative { color: #661717; }
.neutral { color: #282828; }
.neutral-positive { color: #004400; }
.slight-positive { color: #006600; }
.positive { color: #009900; }
.extreme-positive { color: #00cc00; }

/* Make summary row cells slightly transparent */
.summary-row .month-cell {
  opacity: 0.9;
}

.average-row {
  margin-top: 1rem;
}

.heatmap-section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--terminal-border);
}

.heatmap-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.symbol-header {
  text-align: center;
  color: #00ff00;
  font-size: 1.2em;
  margin-bottom: 2.5rem;
  font-weight: bold;
  letter-spacing: 2px;
}
</style> 