<template>
  <div class="results-table-wrapper">
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>{{ loadingMessage }}</p>
    </div>

    <div v-else-if="error" class="error-message">
      <p>{{ error }}</p>
      <button @click="$emit('retry')">Try Again</button>
    </div>

    <div v-else-if="results.length === 0" class="no-results">
      <p>{{ noResultsMessage }}</p>
      <p>{{ noResultsHint }}</p>
    </div>

    <div v-else class="results-table-container">
      <table class="results-table">
        <thead>
          <tr>
            <th 
              v-for="column in columns" 
              :key="column.key"
              @click="sortBy(column.key)"
              :class="{ 
                sortable: !column.noSort,
                sorted: sortKey === column.key,
                'sort-asc': sortKey === column.key && sortDirection === 'asc',
                'sort-desc': sortKey === column.key && sortDirection === 'desc'
              }"
            >
              {{ column.label }}
              <span v-if="!column.noSort" class="sort-indicator">
                {{ getSortIndicator(column.key) }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(result, index) in sortedResults" 
            :key="index" 
            @click="$emit('row-click', result)"
            :class="{ clickable: hasRowClickListener }"
          >
            <td v-for="column in columns" :key="column.key">
              <template v-if="column.format">
                <span v-html="column.format(result[column.key], result)"></span>
              </template>
              <template v-else>
                {{ result[column.key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultsTable',
  props: {
    results: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    loadingMessage: {
      type: String,
      default: 'Loading results...'
    },
    noResultsMessage: {
      type: String,
      default: 'No results found.'
    },
    noResultsHint: {
      type: String,
      default: 'Try adjusting your filters and search again.'
    },
    initialSortKey: {
      type: String,
      default: ''
    },
    initialSortDirection: {
      type: String,
      default: 'desc'
    }
  },
  data() {
    return {
      sortKey: this.initialSortKey,
      sortDirection: this.initialSortDirection
    };
  },
  computed: {
    sortedResults() {
      if (!this.sortKey) return this.results;
      
      return [...this.results].sort((a, b) => {
        let aValue = a[this.sortKey];
        let bValue = b[this.sortKey];
        
        // Handle null values
        if (aValue === null) return 1;
        if (bValue === null) return -1;
        
        // For string values
        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }
        
        if (this.sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    },
    hasRowClickListener() {
      return this.$attrs['onRow-click'] !== undefined;
    }
  },
  methods: {
    sortBy(key) {
      // Skip sorting if column is marked as not sortable
      const column = this.columns.find(col => col.key === key);
      if (column && column.noSort) return;

      if (this.sortKey === key) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortDirection = 'asc';
      }
      
      this.$emit('sort-change', { key, direction: this.sortDirection });
    },
    getSortIndicator(key) {
      if (this.sortKey !== key) return '⇅';
      return this.sortDirection === 'asc' ? '↑' : '↓';
    }
  }
};
</script>

<style scoped>
.results-table-wrapper {
  position: relative;
  width: 100%;
}

.results-table-container {
  overflow-x: auto;
  max-height: calc(100vh - 400px);
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
}

.results-table th {
  background-color: rgba(51, 255, 51, 0.1);
  padding: 10px;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #33ff33;
}

.results-table th.sortable {
  cursor: pointer;
}

.results-table th.sortable:hover {
  background-color: rgba(51, 255, 51, 0.2);
}

.sort-indicator {
  margin-left: 5px;
  font-size: 0.8em;
  opacity: 0.7;
}

.results-table td {
  padding: 8px 10px;
  border-bottom: 1px solid rgba(51, 255, 51, 0.1);
}

.results-table tr.clickable {
  cursor: pointer;
}

.results-table tr.clickable:hover {
  background-color: rgba(51, 255, 51, 0.05);
}

/* Loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.spinner {
  border: 4px solid rgba(51, 255, 51, 0.1);
  border-radius: 50%;
  border-top: 4px solid #33ff33;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error and no results states */
.error-message, .no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
}

.error-message p {
  margin-bottom: 15px;
  color: #ff3333;
}

.error-message button {
  background: var(--terminal-bg, rgba(0, 0, 0, 0.4));
  border: 1px solid var(--status-error, #ff3333);
  color: var(--status-error, #ff3333);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s;
}

.error-message button:hover {
  background-color: rgba(255, 51, 51, 0.1);
}

.no-results p {
  margin-bottom: 15px;
  color: #aaffaa;
}

/* Responsive styles */
@media (max-width: 768px) {
  .results-table-container {
    max-height: calc(100vh - 300px);
  }
}
</style> 