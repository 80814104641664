<!-- Table View -->
<template>
  <div>
    <!-- Column Controls -->
    <div class="table-controls">
      <!-- Contract Info -->
      <div class="contract-info">
        <div class="info-item">
          <span class="label">Symbol:</span>
          <span class="value">{{ symbol }}</span>
        </div>
        <div class="info-item">
          <span class="label">Strike:</span>
          <span class="value">{{ formatPrice(strike) }}</span>
        </div>
        <div class="info-item">
          <span class="label">Expiry:</span>
          <span class="value">{{ formatDate(expiry) }}</span>
        </div>
        <div class="info-item">
          <span class="label">Type:</span>
          <span class="value">{{ optionType === 'C' ? 'Call' : 'Put' }}</span>
        </div>
      </div>

      <div class="control-group">
        <button class="terminal-button" @click="downloadCSV">
          <span class="button-icon">⬇</span>
          Download CSV
        </button>
        <button class="terminal-button" @click="showColumnMenu = !showColumnMenu">
          <span class="button-icon">⚙</span>
          Configure Columns
        </button>
        <div v-if="showColumnMenu" class="column-menu">
          <div class="menu-header">Show/Hide Columns</div>
          
          <!-- Price Columns -->
          <div class="menu-group">
            <div class="group-header">Price Columns</div>
            <label>
              <input type="checkbox" v-model="visibleColumns.open">
              Open
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.high">
              High
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.low">
              Low
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.close">
              Close
            </label>
          </div>

          <!-- Greeks -->
          <div class="menu-group">
            <div class="group-header">Greeks</div>
            <label>
              <input type="checkbox" v-model="visibleColumns.delta">
              Delta (Δ)
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.gamma">
              Gamma (Γ)
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.theta">
              Theta (Θ)
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.vega">
              Vega (V)
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.rho">
              Rho (ρ)
            </label>
          </div>

          <!-- Additional Metrics -->
          <div class="menu-group">
            <div class="group-header">Additional Metrics</div>
            <label>
              <input type="checkbox" v-model="visibleColumns.iv">
              Implied Volatility
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.timeValue">
              Time Value
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.breakeven">
              Break Even
            </label>
            <label>
              <input type="checkbox" v-model="visibleColumns.volume">
              Volume
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="price-table">
      <table>
        <thead>
          <tr>
            <!-- Basic Info -->
            <th @click="sortBy('date')" :class="{ sortable: true, sorted: sortConfig.key === 'date', asc: sortConfig.key === 'date' && sortConfig.direction === 'asc' }">Date (DTE)</th>
            <th @click="sortBy('eod_stock')" :class="{ sortable: true, sorted: sortConfig.key === 'eod_stock' }">Stock Price</th>
            <th v-show="visibleColumns.breakeven" @click="sortBy('breakeven')" :class="{ sortable: true, sorted: sortConfig.key === 'breakeven' }">Break Even</th>
            
            <!-- Option Prices -->
            <th v-show="visibleColumns.open" @click="sortBy('open')" :class="{ sortable: true, sorted: sortConfig.key === 'open' }">Open</th>
            <th v-show="visibleColumns.close" @click="sortBy('close')" :class="{ sortable: true, sorted: sortConfig.key === 'close' }">Close</th>
            <th v-show="visibleColumns.high" @click="sortBy('high')" :class="{ sortable: true, sorted: sortConfig.key === 'high' }">High</th>
            <th v-show="visibleColumns.low" @click="sortBy('low')" :class="{ sortable: true, sorted: sortConfig.key === 'low' }">Low</th>
            <th v-show="visibleColumns.volume" @click="sortBy('volume')" :class="{ sortable: true, sorted: sortConfig.key === 'volume' }">Volume</th>
            
            <!-- IV -->
            <th v-show="visibleColumns.iv" @click="sortBy('impliedVolatility')" :class="{ sortable: true, sorted: sortConfig.key === 'impliedVolatility' }">IV</th>
            
            <!-- Greeks -->
            <th v-show="visibleColumns.delta" @click="sortBy('delta')" :class="{ sortable: true, sorted: sortConfig.key === 'delta' }">Delta</th>
            <th v-show="visibleColumns.gamma" @click="sortBy('gamma')" :class="{ sortable: true, sorted: sortConfig.key === 'gamma' }">Gamma</th>
            <th v-show="visibleColumns.theta" @click="sortBy('theta')" :class="{ sortable: true, sorted: sortConfig.key === 'theta' }">Theta</th>
            <th v-show="visibleColumns.vega" @click="sortBy('vega')" :class="{ sortable: true, sorted: sortConfig.key === 'vega' }">Vega</th>
            <th v-show="visibleColumns.rho" @click="sortBy('rho')" :class="{ sortable: true, sorted: sortConfig.key === 'rho' }">Rho</th>
            
            <!-- Time Value -->
            <th v-show="visibleColumns.timeValue" @click="sortBy('timeValue')" :class="{ sortable: true, sorted: sortConfig.key === 'timeValue' }">Time Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="day in sortedData" :key="day.date">
            <!-- Basic Info -->
            <td><span class="row-date">{{ formatDate(day.date) }}</span> <span class="row-dte">({{ day.metrics?.daysToExpiry || 'N/A' }} DTE)</span></td>
            <td>{{ formatPrice(day.eod_stock) }}</td>
            <td v-show="visibleColumns.breakeven">{{ formatPrice(day.theoretical?.breakEven) || 'N/A' }}</td>
            
            <!-- Option Prices -->
            <td v-show="visibleColumns.open">{{ formatPrice(day.open) }}</td>
            <td v-show="visibleColumns.close">{{ formatPrice(day.close) }}</td>
            <td v-show="visibleColumns.high">{{ formatPrice(day.high) }}</td>
            <td v-show="visibleColumns.low">{{ formatPrice(day.low) }}</td>
            <td v-show="visibleColumns.volume">{{ day.volume?.toLocaleString() || 'N/A' }}</td>
            
            <!-- IV -->
            <td v-show="visibleColumns.iv">{{ formatPercentage(day.metrics?.impliedVolatility * 100) || 'N/A' }}</td>
            
            <!-- Greeks -->
            <td v-show="visibleColumns.delta" :class="{ 'positive': day.greeks?.delta > 0, 'negative': day.greeks?.delta < 0 }">
              {{ formatNumber(day.greeks?.delta, 4) || 'N/A' }}
            </td>
            <td v-show="visibleColumns.gamma">{{ formatNumber(day.greeks?.gamma, 4) || 'N/A' }}</td>
            <td v-show="visibleColumns.theta" :class="{ 'negative': day.greeks?.theta < 0 }">
              {{ formatNumber(day.greeks?.theta, 4) || 'N/A' }}
            </td>
            <td v-show="visibleColumns.vega">{{ formatNumber(day.greeks?.vega, 4) || 'N/A' }}</td>
            <td v-show="visibleColumns.rho" :class="{ 'positive': day.greeks?.rho > 0, 'negative': day.greeks?.rho < 0 }">
              {{ formatNumber(day.greeks?.rho, 4) || 'N/A' }}
            </td>
            
            <!-- Time Value -->
            <td v-show="visibleColumns.timeValue">{{ formatPrice(day.metrics?.timeValue) || 'N/A' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'OptionsHistoryTable',
  
  props: {
    data: {
      type: Array,
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    strike: {
      type: Number,
      required: true
    },
    optionType: {
      type: String,
      required: true
    },
    expiry: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const showColumnMenu = ref(false)
    const sortConfig = ref({ key: null, direction: 'asc' })
    const visibleColumns = ref({
      // Price columns
      open: true,
      high: true,
      low: true,
      close: true,
      
      // Greeks
      delta: true,
      gamma: false,  // Hidden by default
      theta: true,
      vega: false,   // Hidden by default
      rho: false,    // Hidden by default
      
      // Additional metrics
      iv: true,
      timeValue: false,  // Hidden by default
      breakeven: false,  // Hidden by default
      volume: true
    })

    // Format date in a readable way
    const formatDate = (dateStr) => {
      const date = new Date(dateStr + 'T00:00:00Z')
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC'
      }).format(date)
    }

    // Format price with appropriate decimals
    const formatPrice = (price) => {
      if (price === null || price === undefined) return 'N/A'
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(price)
    }

    // Format percentage
    const formatPercentage = (pct) => {
      if (pct === null || pct === undefined) return 'N/A'
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        signDisplay: 'always'
      }).format(pct / 100)
    }

    // Format number with specified precision
    const formatNumber = (num, precision) => {
      if (num === null || num === undefined) return 'N/A'
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }).format(num)
    }

    // Generate and download CSV
    const downloadCSV = () => {
      // Get visible columns and prepare headers
      const headers = ['Date', 'DTE', 'Stock Price']
      if (visibleColumns.value.breakeven) headers.push('Break Even')
      if (visibleColumns.value.open) headers.push('Open')
      if (visibleColumns.value.close) headers.push('Close')
      if (visibleColumns.value.high) headers.push('High')
      if (visibleColumns.value.low) headers.push('Low')
      if (visibleColumns.value.volume) headers.push('Volume')
      if (visibleColumns.value.iv) headers.push('IV')
      if (visibleColumns.value.delta) headers.push('Delta')
      if (visibleColumns.value.gamma) headers.push('Gamma')
      if (visibleColumns.value.theta) headers.push('Theta')
      if (visibleColumns.value.vega) headers.push('Vega')
      if (visibleColumns.value.rho) headers.push('Rho')
      if (visibleColumns.value.timeValue) headers.push('Time Value')

      // Convert data to CSV rows
      const rows = sortedData.value.map(day => {
        const row = [
          day.date,
          day.metrics?.daysToExpiry || 'N/A',
          day.eod_stock
        ]

        if (visibleColumns.value.breakeven) row.push(day.theoretical?.breakEven || 'N/A')
        if (visibleColumns.value.open) row.push(day.open || 'N/A')
        if (visibleColumns.value.close) row.push(day.close || 'N/A')
        if (visibleColumns.value.high) row.push(day.high || 'N/A')
        if (visibleColumns.value.low) row.push(day.low || 'N/A')
        if (visibleColumns.value.volume) row.push(day.volume || 'N/A')
        if (visibleColumns.value.iv) row.push(day.metrics?.impliedVolatility ? (day.metrics.impliedVolatility * 100).toFixed(2) : 'N/A')
        if (visibleColumns.value.delta) row.push(day.greeks?.delta?.toFixed(4) || 'N/A')
        if (visibleColumns.value.gamma) row.push(day.greeks?.gamma?.toFixed(4) || 'N/A')
        if (visibleColumns.value.theta) row.push(day.greeks?.theta?.toFixed(4) || 'N/A')
        if (visibleColumns.value.vega) row.push(day.greeks?.vega?.toFixed(4) || 'N/A')
        if (visibleColumns.value.rho) row.push(day.greeks?.rho?.toFixed(4) || 'N/A')
        if (visibleColumns.value.timeValue) row.push(day.metrics?.timeValue || 'N/A')

        return row
      })

      // Create CSV content
      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n')

      // Create download link
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      
      // Generate filename using contract details
      const filename = `${props.symbol}_${props.strike}_${props.optionType}_${props.expiry}.csv`
      
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    // Add sorting functionality
    const sortBy = (key) => {
      if (sortConfig.value.key === key) {
        // If clicking the same column, toggle direction
        sortConfig.value.direction = sortConfig.value.direction === 'asc' ? 'desc' : 'asc'
      } else {
        // If clicking a new column, set it with ascending direction
        sortConfig.value.key = key
        sortConfig.value.direction = 'asc'
      }
    }

    // Computed property for sorted data
    const sortedData = computed(() => {
      if (!props.data) return []
      
      const data = [...props.data]
      if (!sortConfig.value.key) return data

      return data.sort((a, b) => {
        let aValue = sortConfig.value.key.split('.').reduce((obj, key) => obj?.[key], a)
        let bValue = sortConfig.value.key.split('.').reduce((obj, key) => obj?.[key], b)

        // Handle nested properties
        if (sortConfig.value.key === 'delta') {
          aValue = a.greeks?.delta
          bValue = b.greeks?.delta
        } else if (sortConfig.value.key === 'gamma') {
          aValue = a.greeks?.gamma
          bValue = b.greeks?.gamma
        } else if (sortConfig.value.key === 'theta') {
          aValue = a.greeks?.theta
          bValue = b.greeks?.theta
        } else if (sortConfig.value.key === 'vega') {
          aValue = a.greeks?.vega
          bValue = b.greeks?.vega
        } else if (sortConfig.value.key === 'rho') {
          aValue = a.greeks?.rho
          bValue = b.greeks?.rho
        } else if (sortConfig.value.key === 'impliedVolatility') {
          aValue = a.metrics?.impliedVolatility
          bValue = b.metrics?.impliedVolatility
        } else if (sortConfig.value.key === 'timeValue') {
          aValue = a.metrics?.timeValue
          bValue = b.metrics?.timeValue
        } else if (sortConfig.value.key === 'breakeven') {
          aValue = a.metrics?.breakeven
          bValue = b.metrics?.breakeven
        }

        if (aValue === bValue) return 0
        if (aValue === null || aValue === undefined) return 1
        if (bValue === null || bValue === undefined) return -1

        const result = aValue < bValue ? -1 : 1
        return sortConfig.value.direction === 'asc' ? result : -result
      })
    })

    return {
      showColumnMenu,
      visibleColumns,
      sortConfig,
      sortBy,
      sortedData,
      formatDate,
      formatPrice,
      formatPercentage,
      formatNumber,
      downloadCSV
    }
  }
}
</script>

<style scoped>
.price-table {
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 12px;
  margin-bottom: -12px;
  max-height: calc(100vh - 22rem);
}

/* .price-chart {
  width: 100%;
  min-height: calc(100vh - 22rem);
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  background: var(--terminal-bg-darker);
} */

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  white-space: nowrap;
}

th {
  color: var(--terminal-cyan);
  padding: 0.75rem 0.5rem;
  text-align: right;
  border-bottom: 1px solid var(--terminal-border);
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
  min-width: 80px;
  background: var(--terminal-bg)!important;
}

td {
  padding: 0.5rem;
  text-align: right;
  border-bottom: 1px solid var(--terminal-border);
  min-width: 80px;
  filter: brightness(0.75);
}

/* First column (Date) stays fixed and left-aligned */
th:first-child,
td:first-child {
  text-align: left;
  left: 0;
  background: var(--terminal-bg);
  z-index: 2;
  min-width: 100px;
  filter: brightness(1);
}

/* Second column (Stock Price) also stays fixed */
th:nth-child(2),
td:nth-child(2) {
  left: 100px;
  background: var(--terminal-bg);
  z-index: 2;
  min-width: 90px;
}

th:first-child, th:nth-child(2) {
  z-index: 1!important;
}

tr:hover td {
  background: var(--terminal-bg-darker);
}

/* Color coding for values */
.positive {
  color: var(--terminal-green);
}

.negative {
  color: var(--terminal-red);
}

/* Scrollbar styling */
.price-table::-webkit-scrollbar {
  height: 12px;
}

.price-table::-webkit-scrollbar-track {
  background: var(--terminal-bg-darker);
}

.price-table::-webkit-scrollbar-thumb {
  background-color: var(--terminal-border);
  border: 3px solid var(--terminal-bg-darker);
  border-radius: 6px;
}

/* Sortable columns styling */
th.sortable {
  padding-right: 1.5rem;
}

th.sortable::after {
  content: '⇅';
  position: absolute;
  right: 0.25rem;
  opacity: 0.5;
  font-size: 1.2em;
  top: 50%;
  transform: translateY(-50%);
}

th.sortable.sorted::after {
  opacity: 1;
  color: var(--terminal-green);
}

th.sortable.sorted.asc::after {
  content: '↑';
}

th.sortable.sorted.desc::after {
  content: '↓';
}

/* Column visibility menu styles */
.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: var(--terminal-bg-darker);
  border-radius: 4px;
}

.control-group {
  position: relative;
  margin-left: auto;
}

.button-icon {
  margin-right: 0.5rem;
  font-size: 1.1em;
}

.column-menu {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  padding: 0;
  z-index: 10;
  min-width: 220px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.menu-header {
  padding: 0.75rem;
  background: var(--terminal-bg-darker);
  border-bottom: 1px solid var(--terminal-border);
  font-weight: bold;
  color: var(--terminal-cyan);
}

.menu-group {
  padding: 0.5rem;
  border-bottom: 1px solid var(--terminal-border);
}

.menu-group:last-child {
  border-bottom: none;
}

.column-menu label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.column-menu label:hover {
  background: var(--terminal-bg-darker);
}

.column-menu input[type="checkbox"] {
  margin: 0;
  accent-color: var(--terminal-green);
}

.group-header {
  color: var(--terminal-cyan);
  font-size: 0.9em;
  padding: 0.25rem 0.75rem;
  opacity: 0.8;
  font-style: italic;
}

.terminal-button {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9em;
  min-width: 120px;
  text-align: center;
  margin-left: 0.5rem;
}

.terminal-button:hover {
  border-color: var(--terminal-green);
  color: var(--terminal-green);
}

.row-dte {
  filter: brightness(0.5);
}

/* Contract info styles */
.contract-info {
  display: flex;
  gap: 1.5rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.info-item .label {
  color: var(--terminal-cyan);
  font-size: 0.9em;
}

.info-item .value {
  color: var(--terminal-green);
  font-size: 0.9em;
}
</style> 