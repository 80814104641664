<template>
  <div class="heatmap-container">
    <template v-if="timeframe === 'daily'">
      <div class="calendar-grid">
        <div v-for="[year, values] in yearGroups" :key="year" class="year-block">
          <!-- Year Label -->
          <div class="year-label">{{ year }}</div>

          <!-- Weekday Labels -->
          <div class="weekday-labels">
            <div 
              v-for="(day, i) in weekdays" 
              :key="day"
              class="weekday-label"
              :style="{ transform: 'translateY(' + (countDay(i) * cellSize) + 'px)' }"
            >
              {{ day }}
            </div>
          </div>

          <!-- Calendar Grid -->
          <div class="calendar-content">
            <!-- Month Labels -->
            <div class="month-labels">
              <div 
                v-for="month in monthsInYear(year, values)" 
                :key="year + '-' + month.index"
                class="month-label"
                :style="{ 
                  transform: 'translateX(' + getMonthX(month.firstDate) + 'px)',
                  width: getMonthWidth(month.firstDate) + 'px'
                }"
              >
                {{ formatMonth(month.firstDate) }}
              </div>
            </div>

            <!-- Days Grid -->
            <div class="days-grid">
              <div 
                v-for="day in values" 
                :key="day.date"
                class="day-cell"
                :class="getReturnClass(day.change)"
                :style="{ 
                  transform: 'translate(' + getDayX(day.date) + 'px, ' + getDayY(day.date) + 'px)',
                  width: (cellSize - 1) + 'px',
                  height: (cellSize - 1) + 'px'
                }"
              >
                {{ getReturnSymbol() }}
                <div class="tooltip">
                  {{ formatDate(day.date) }}: {{ formatNumber(day.change) }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HeatmapGrid',
  
  props: {
    timeframe: {
      type: String,
      required: true
    },
    dailyReturns: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      cellSize: 28
    }
  },

  computed: {
    yearGroups() {
      // Group data by year and sort descending
      const groups = new Map()
      for (const day of this.dailyReturns) {
        const date = moment(day.date)
        const year = date.year()
        if (!groups.has(year)) {
          groups.set(year, [])
        }
        groups.get(year).push({
          date: date,
          change: day.change
        })
      }
      return Array.from(groups.entries())
        .sort(([a], [b]) => b - a) // Sort years descending
    }
  },

  methods: {
    formatNumber(num) {
      return num ? Number(num).toFixed(2) : '0.00'
    },

    formatDate(date) {
      return date.format('YYYY-MM-DD')
    },

    formatMonth(date) {
      return date.format('MMM')
    },

    getReturnClass(change) {
      if (!change) return 'neutral'
      if (change <= -7) return 'extreme-negative'
      if (change <= -5) return 'negative'
      if (change <= -1) return 'slight-negative'
      if (change < 0) return 'neutral-negative'
      if (change === 0) return 'neutral'
      if (change <= 1) return 'neutral-positive'
      if (change <= 3) return 'slight-positive'
      if (change <= 7) return 'positive'
      return 'extreme-positive'
    },

    getReturnSymbol() {
      return '█' // Always use solid block
    },

    countDay(d) {
      return d  // Remove the reversal, now 0 (Sunday) will be at top, 6 (Saturday) at bottom
    },

    timeWeek(date) {
      return moment(date).startOf('week')
    },

    getWeekOfYear(date) {
      // If it's December and week is 1, it means we're in the last week
      if (date.month() === 11 && date.week() === 1) {
        return 52; // Force it to be the last week of December
      }
      return date.week() - 1; // Subtract 1 to start weeks at 0
    },

    getDayX(date) {
      const weekNum = this.getWeekOfYear(date);
      return weekNum * this.cellSize;
    },

    getDayY(date) {
      return this.countDay(date.day()) * this.cellSize
    },

    monthsInYear(year, values) {
      if (!values.length) return []
      
      const months = []
      let currentDate = moment(values[0].date).startOf('month')
      const endDate = moment(values[values.length - 1].date).endOf('month')

      while (currentDate <= endDate) {
        months.push({
          index: currentDate.month(),
          firstDate: currentDate.clone()
        })
        currentDate.add(1, 'month')
      }
      return months
    },

    getMonthX(date) {
      const weekNum = this.getWeekOfYear(date);
      return weekNum * this.cellSize;
    },

    getMonthWidth(date) {
      const startWeek = this.getWeekOfYear(date);
      const endWeek = this.getWeekOfYear(moment(date).endOf('month'));
      return (endWeek - startWeek + 1) * this.cellSize;
    }
  }
}
</script>

<style scoped>
.heatmap-container {
  height: auto;
  max-height: calc(100vh - 6rem); /* Adapt to viewport height */
  width: 100%;
  overflow: auto;
  font: 12px 'JetBrains Mono', monospace; /* Slightly larger base font */
  background: var(--terminal-bg);
  padding: 1rem;
}

.calendar-grid {
  min-width: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto; /* Center the grid */
  max-width: fit-content;
}

.year-block {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 7rem; /* Increased to accommodate year + weekdays */
  height: calc(7 * 28px + 2rem);
}

.year-label {
  position: absolute;
  left: 0;
  top: 1.75rem;
  font-size: 16px;
  color: #00ff00;
  width: 3rem;
  text-align: right;
  padding-right: 0.75rem;
}

.weekday-labels {
  position: absolute;
  left: 3rem; /* Position after year label */
  top: 2rem;
}

.weekday-label {
  position: absolute;
  font-size: 12px;
  color: #00ff00;
  width: 3rem;
  text-align: right;
  padding-right: 0.5rem;
}

.calendar-content {
  position: relative;
  padding-top: 1.75rem;
  min-width: calc(53 * 28px); /* Adjusted for new cell size */
}

.month-labels {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1.75rem;
}

.month-label {
  position: absolute;
  font-size: 14px; /* Larger month labels */
  color: #00ff00;
  text-align: center;
}

.days-grid {
  position: relative;
  height: calc(7 * 28px); /* Adjusted for new cell size */
}

.day-cell {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px; /* Larger solid blocks */
  box-sizing: border-box;
  background: transparent;
  z-index: 1;
}

.day-cell:hover {
  border: 1px solid #666666;
  z-index: 1000; /* Ensure the cell and its tooltip are above other cells when hovered */
}

.day-cell:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: absolute;
  background: #000;
  border: 1px solid #333;
  padding: 0.5rem 0.75rem;
  z-index: inherit; /* Inherit z-index from parent */
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
  font-size: 12px;
  pointer-events: none;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

/* Return classes with refined color intensities */
.extreme-negative { color: #ff3b3b; }
.negative { color: #cc2f2f; }
.slight-negative { color: #992323; }
.neutral-negative { color: #661717; }
.neutral { color: #282828; }
.neutral-positive { color: #004400; }
.slight-positive { color: #006600; }
.positive { color: #009900; }
.extreme-positive { color: #00cc00; }
</style> 