<template>
  <div class="terminal-controls">
    <!-- Asset Selection -->
    <div class="control-group">
      <label>Select Asset:</label>
      
      <!-- Loading state -->
      <div v-if="loading" class="loading-state">
        Loading assets...
      </div>
      
      <!-- Error state -->
      <div v-else-if="error" class="error-state">
        {{ error }}
      </div>
      
      <!-- Asset dropdown -->
      <select 
        v-else
        v-model="selectedAsset"
        class="terminal-select"
        @change="fetchOptionDates"
      >
        <template v-if="assetOptions.grouped">
          <optgroup 
            v-for="(assets, sector) in assetOptions.assets" 
            :key="sector"
            :label="sector.charAt(0).toUpperCase() + sector.slice(1)"
          >
            <option 
              v-for="option in assets" 
              :key="option.value" 
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </optgroup>
        </template>
        <template v-else>
          <option 
            v-for="option in assetOptions.assets" 
            :key="option.value" 
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </template>
      </select>
    </div>

    <!-- Expiry Date Selection -->
    <div class="control-group">
      <label>Expiry Date:</label>
      <select 
        v-model="selectedExpiry"
        class="terminal-select"
        :disabled="!selectedAsset || loadingDates"
        @change="fetchStrikes"
      >
        <option value="">Select date...</option>
        <option 
          v-for="date in expiryDates" 
          :key="date"
          :value="date"
        >
          {{ formatDate(date) }}
        </option>
      </select>
    </div>

    <!-- Strike Price Selection -->
    <div class="control-group">
      <label>Strike Price:</label>
      <select 
        v-model="selectedStrike"
        class="terminal-select"
        :disabled="!selectedExpiry || loadingStrikes"
      >
        <option value="">Select strike...</option>
        <option 
          v-for="strike in strikes" 
          :key="strike"
          :value="strike"
        >
          ${{ formatPrice(strike) }}
        </option>
      </select>
    </div>

    <!-- Option Type Selection -->
    <div class="control-group">
      <label>Type:</label>
      <select 
        v-model="selectedType"
        class="terminal-select"
        :disabled="!selectedStrike"
      >
        <option value="C">Call</option>
        <option value="P">Put</option>
      </select>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'OptionControls',

  props: {
    defaultContract: {
      type: Object,
      default: () => ({
        symbol: '',
        expiry: '',
        strike: '',
        type: 'C'
      })
    }
  },

  emits: ['update:contract', 'update:expiries', 'update:strikes', 'update:ticker'],

  data() {
    return {
      loading: false,
      loadingDates: false,
      loadingStrikes: false,
      error: null,
      assetOptions: { assets: [], grouped: false },
      selectedAsset: this.defaultContract.symbol,
      expiryDates: [],
      selectedExpiry: this.defaultContract.expiry,
      strikes: [],
      selectedStrike: this.defaultContract.strike,
      selectedType: this.defaultContract.type
    }
  },

  watch: {
    // Watch all selections to emit updates
    selectedAsset() {
      this.selectedExpiry = ''
      this.selectedStrike = ''
      this.emitUpdate()
      this.$emit('update:ticker', this.selectedAsset)
    },
    selectedExpiry() {
      this.selectedStrike = ''
      this.emitUpdate()
    },
    selectedStrike() {
      this.emitUpdate()
    },
    selectedType() {
      this.emitUpdate()
    }
  },

  async created() {
    await this.fetchAssets()
    if (this.selectedAsset) {
      await this.fetchOptionDates()
      if (this.selectedExpiry) {
        await this.fetchStrikes()
        // Emit initial contract if all values are present
        this.emitUpdate()
      }
    }
  },

  methods: {
    formatDate(dateStr) {
      return new Date(dateStr).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },

    formatPrice(price) {
      return price.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    async fetchAssets() {
      this.loading = true
      this.error = null
      try {
        const response = await api.get('/assets', { params: { type: 'options' } })
        if (response.data.success) {
          this.assetOptions = response.data
        } else {
          throw new Error(response.data.error || 'Failed to fetch assets')
        }
      } catch (error) {
        console.error('Error fetching assets:', error)
        this.error = error.message
      } finally {
        this.loading = false
      }
    },

    async fetchOptionDates() {
      if (!this.selectedAsset) return
      
      this.loadingDates = true
      this.expiryDates = []
      this.error = null

      try {
        const response = await api.get('/options/history/dates', {
          params: { symbol: this.selectedAsset }
        })
        if (response.data.success) {
          this.expiryDates = response.data.dates
          this.$emit('update:expiries', this.expiryDates)
        } else {
          throw new Error(response.data.error || 'Failed to fetch expiry dates')
        }
      } catch (error) {
        console.error('Error fetching expiry dates:', error)
        this.error = error.message
      } finally {
        this.loadingDates = false
      }
    },

    async fetchStrikes() {
      if (!this.selectedAsset || !this.selectedExpiry) return
      
      this.loadingStrikes = true
      this.strikes = []
      this.error = null

      try {
        const response = await api.get('/options/history/strikes', {
          params: {
            symbol: this.selectedAsset,
            expiry: this.selectedExpiry
          }
        })
        if (response.data.success) {
          this.strikes = response.data.strikes
          this.$emit('update:strikes', this.strikes)
        } else {
          throw new Error(response.data.error || 'Failed to fetch strikes')
        }
      } catch (error) {
        console.error('Error fetching strikes:', error)
        this.error = error.message
      } finally {
        this.loadingStrikes = false
      }
    },

    emitUpdate() {
      if (this.selectedAsset && this.selectedExpiry && this.selectedStrike && this.selectedType) {
        this.$emit('update:contract', {
          symbol: this.selectedAsset,
          expiry: this.selectedExpiry,
          strike: this.selectedStrike,
          type: this.selectedType
        })
      }
    }
  }
}
</script>

<style scoped>
.terminal-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.terminal-select,
.terminal-input {
  background: var(--terminal-bg);
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
  padding: 0.25rem;
  min-width: 150px;
}

.terminal-select:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

label {
  color: #00ff00;
  font-size: 0.9em;
}

.loading-state,
.error-state {
  padding: 0.5rem;
  border: 1px solid var(--terminal-border);
  background: var(--terminal-bg);
  color: var(--terminal-text);
}

.error-state {
  color: #ff0000;
}

</style> 