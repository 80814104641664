<template>
  <div class="terminal-table-wrapper">
    <table>
      <thead>
        <tr class="sticky-header">
          <th>{{ firstColumnHeader }}</th>
          <th v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
      </thead>

      <tbody>
        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
        <template v-for="section in sections" :key="section.id">
          <!-- Section Header -->
          <tr class="section-header" @click="toggleSection(section.id)">
            <td colspan="999">
              <div class="section-header-content">
                <span class="toggle-icon">{{ expandedSections[section.id] ? '▼' : '▶' }}</span>
                <span>{{ section.title }}</span>
              </div>
            </td>
          </tr>

          <!-- Section Rows -->
          <tr 
            v-for="row in section.rows" 
            :key="`${section.id}-${row.label}`"
            v-show="expandedSections[section.id]"
          >
            <td>{{ row.label }}</td>
            <td v-for="header in headers" :key="header">
              {{ formatValue(row.values[header], row.formatter) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TerminalTable',

  props: {
    firstColumnHeader: {
      type: String,
      default: 'Metric'
    },
    headers: {
      type: Array,
      required: true
    },
    sections: {
      type: Array,
      required: true,
      validator: sections => sections.every(section => 
        section.id && 
        section.title && 
        Array.isArray(section.rows) &&
        section.rows.every(row => row.label && row.values)
      )
    },
    initiallyExpanded: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      expandedSections: this.sections.reduce((acc, section) => {
        acc[section.id] = this.initiallyExpanded;
        return acc;
      }, {})
    }
  },

  methods: {
    toggleSection(sectionId) {
      this.expandedSections[sectionId] = !this.expandedSections[sectionId];
    },

    formatValue(value, formatter) {
      if (value === undefined || value === null) return 'N/A';
      return formatter ? formatter(value) : value;
    }
  }
}
</script>

<style scoped>
.terminal-table-wrapper {
  overflow-x: auto;
  max-height: 80vh;
  position: relative;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9em;
  letter-spacing: 0.5px;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--terminal-bg);
}

.sticky-header th {
  background: var(--terminal-bg-darker);
  box-shadow: 0 1px 0 var(--terminal-border);
}

th, td {
  padding: 0.5rem 1rem;
  text-align: right;
  border: 1px solid var(--terminal-border);
}

th:first-child, td:first-child {
  text-align: left;
  color: var(--terminal-cyan);
}

th {
  background: var(--terminal-bg-darker);
  color: var(--terminal-green);
  font-weight: bold;
}

.section-header {
  cursor: pointer;
  user-select: none;
}

.section-header:hover {
  opacity: 0.9;
}

.section-header-content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.toggle-icon {
  font-size: 0.8em;
  color: var(--terminal-green);
  min-width: 1em;
}

tbody tr:not(.section-header) td:first-child {
  color: #00b300;
}

tr:hover:not(.section-header) {
  background: rgba(255, 255, 255, 0.05);
}
</style> 
