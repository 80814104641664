<template>
  <div class="statistics-panel">
    <div class="statistics-header">
      <h3>Price Statistics</h3>
    </div>
    
    <terminal-table
      first-column-header="Metric"
      :headers="symbols"
      :sections="tableSections"
      :initially-expanded="true"
    />
  </div>
</template>

<script>
import TerminalTable from '../shared/TerminalTable.vue'

export default {
  name: 'PriceStatistics',
  
  components: {
    TerminalTable
  },

  props: {
    analysisData: {
      type: Object,
      required: true
    }
  },

  computed: {
    symbols() {
      return Object.keys(this.analysisData);
    },

    tableSections() {
      return [
        {
          id: 'priceMetrics',
          title: 'Price Metrics',
          rows: [
            {
              label: 'Current Price',
              values: this.getMetricValues('athMetrics.current.price'),
              formatter: this.formatPrice
            },
            {
              label: 'All-Time High',
              values: this.getMetricValues('athMetrics.ath.price'),
              formatter: this.formatPrice
            },
            {
              label: 'ATH Date',
              values: this.getMetricValues('athMetrics.ath.date'),
              formatter: this.formatDate
            },
            {
              label: 'Days Since ATH',
              values: this.getMetricValues('athMetrics.ath.daysSince')
            },
            {
              label: 'Current Drawdown',
              values: this.getMetricValues('athMetrics.current.drawdown'),
              formatter: this.formatPercentage
            }
          ]
        },
        {
          id: 'valuation',
          title: 'Valuation',
          rows: [
            {
              label: 'Current Price/Book',
              values: this.getMetricValues('financials.currentPriceToBook'),
              formatter: value => value?.toFixed(2) + 'x'
            },
            {
              label: 'Average Price/Book',
              values: this.getMetricValues('financials.averagePriceToBook'),
              formatter: value => value?.toFixed(2) + 'x'
            },
            {
              label: 'Min Price/Book',
              values: this.getMetricValues('financials.minPriceToBook'),
              formatter: value => value?.toFixed(2) + 'x'
            },
            {
              label: 'Max Price/Book',
              values: this.getMetricValues('financials.maxPriceToBook'),
              formatter: value => value?.toFixed(2) + 'x'
            }
          ]
        },
        {
          id: 'drawdowns',
          title: 'Drawdown Analysis',
          rows: [
            {
              label: 'Total Drawdowns',
              values: this.getMetricValues('drawdowns.totalDrawdowns')
            },
            {
              label: 'Average Recovery (Days)',
              values: this.getMetricValues('drawdowns.avgRecoveryDays'),
              formatter: value => Math.round(value)
            },
            {
              label: 'Longest Recovery (Days)',
              values: this.getMetricValues('drawdowns.longestRecovery')
            },
            {
              label: 'Active Drawdown',
              values: this.getMetricValues('drawdowns.activeDrawdown'),
              formatter: value => value ? this.formatPercentage(value.lowestPoint.drawdown) : 'None'
            },
            {
              label: 'Drawdown 1',
              values: this.getMetricValues('drawdowns.majorDrawdowns.-1'),
              formatter: value => value ? `${this.formatDate(value.startDate)} (${this.formatPercentage(value.lowestPoint.drawdown)})` : 'N/A'
            },
            {
              label: 'Drawdown 2',
              values: this.getMetricValues('drawdowns.majorDrawdowns.-2'),
              formatter: value => value ? `${this.formatDate(value.startDate)} (${this.formatPercentage(value.lowestPoint.drawdown)})` : 'N/A'
            },
            {
              label: 'Drawdown 3',
              values: this.getMetricValues('drawdowns.majorDrawdowns.-3'),
              formatter: value => value ? `${this.formatDate(value.startDate)} (${this.formatPercentage(value.lowestPoint.drawdown)})` : 'N/A'
            }
          ]
        },
        {
          id: 'distribution',
          title: 'Return Distribution',
          rows: [
            {
              label: 'Total Days',
              values: this.getMetricValues('distribution.stats.count')
            },
            {
              label: 'Annualized Volatility',
              values: this.getMetricValues('distribution.stats.volatility'),
              formatter: this.formatPercentage
            },
            {
              label: 'Max Daily',
              values: this.getMetricValues('distribution.stats.max'),
              formatter: this.formatPercentage
            },
            {
              label: 'Best Days (Top 5%)',
              values: this.getMetricValues('distribution.stats.percentiles.95'),
              formatter: this.formatPercentage
            },
            {
              label: 'Strong Days (Top 25%)',
              values: this.getMetricValues('distribution.stats.percentiles.75'),
              formatter: this.formatPercentage
            },
            {
              label: 'Average',
              values: this.getMetricValues('distribution.stats.mean'),
              formatter: this.formatPercentage
            },
            {
              label: 'Typical Day (Median)',
              values: this.getMetricValues('distribution.stats.percentiles.50'),
              formatter: this.formatPercentage
            },
            {
              label: 'Weak Days (Bottom 25%)',
              values: this.getMetricValues('distribution.stats.percentiles.25'),
              formatter: this.formatPercentage
            },
            {
              label: 'Bad Days (Bottom 5%)',
              values: this.getMetricValues('distribution.stats.percentiles.5'),
              formatter: this.formatPercentage
            },
            {
              label: 'Worst Day',
              values: this.getMetricValues('distribution.stats.min'),
              formatter: this.formatPercentage
            }
          ]
        }
      ];
    }
  },

  methods: {
    getMetricValues(path) {
      return this.symbols.reduce((acc, sym) => {
        let value = this.analysisData[sym];
        const keys = path.split('.');
        
        // Handle array indices that need to be accessed from the end
        const lastKey = keys[keys.length - 1];
        if (lastKey.startsWith('-')) {
          // Remove the last key which contains the array index
          keys.pop();
          // Navigate to the array
          for (const key of keys) {
            value = value?.[key];
          }
          // Get the index from the end if it's an array
          const indexFromEnd = parseInt(lastKey.slice(1));
          if (Array.isArray(value)) {
            value = value[value.length - indexFromEnd];
          }
        } else {
          // Normal path traversal
          for (const key of keys) {
            value = value?.[key];
          }
        }
        
        acc[sym] = value;
        return acc;
      }, {});
    },

    formatPrice(price, digits = 2) {
      if (price > 10000) digits = 0;
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }).format(price);
    },

    formatPercentage(value, digits = 2) {
      if (value === undefined || value === null) return 'N/A';
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        signDisplay: 'auto'
      }).format(value / 100);
    },

    formatDate(date) {
      if (date === undefined || date === null) return 'N/A';
      return new Intl.DateTimeFormat('en-US').format(new Date(date));
    }
  }
}
</script>

<style scoped>
.statistics-panel {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
}

.statistics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.statistics-header h3 {
  color: var(--terminal-yellow);
  margin: 0;
  font-size: 1.1em;
}
</style> 
