<template>
  <div class="analysis-view">
    <TerminalHeader title="Asset Analysis" />
    
    <HeaderControls
      :timeframe="timeframe"
      :startYear="startYear"
      :currentYear="currentYear"
      :symbol="symbol"
      :selected-symbols="selectedSymbols"
      :allow-multi-select="true"
      @update:timeframe="timeframe = $event"
      @update:startYear="startYear = $event"
      @update:symbol="symbol = $event"
      @update:selectedSymbols="selectedSymbols = $event"
    />

    <!-- Loading State -->
    <div v-if="loading" class="terminal-loading">
      Loading data... ⣾⣽⣻⢿⡿⣟⣯⣷
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="terminal-error">
      Error: {{ error }}
    </div>

    <!-- Analysis Display -->
    <div v-else class="analysis-display">
      <!-- View Controls -->
      <div class="view-controls">
        <div class="button-group">
          <button 
            class="terminal-button"
            :class="{ active: activeView === 'analysis' }"
            @click="activeView = 'analysis'"
          >
            Distribution
          </button>
          <button 
            class="terminal-button"
            :class="{ active: activeView === 'statistics' }"
            @click="activeView = 'statistics'"
          >
            Price Stats
          </button>
          <button 
            class="terminal-button"
            :class="{ active: activeView === 'seasonal' }"
            @click="activeView = 'seasonal'"
          >
            Seasonality
          </button>
        </div>
      </div>

      <!-- Analysis Content -->
      <div class="analysis-content">
        <template v-if="Object.keys(analysisData).length">
          <AssetAnalysis
            v-if="activeView === 'analysis'"
            :analysisData="analysisData"
            :timeframe="timeframe"
            :asset="displaySymbol"
          />
          <PriceStatistics
            v-else-if="activeView === 'statistics'"
            :analysisData="analysisData"
          />
          <SeasonalStatistics
            v-else-if="activeView === 'seasonal'"
            :analysisData="analysisData"
          />
        </template>
        <div v-else class="terminal-info">
          Select assets and timeframe to begin analysis
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import api from '@/utils/api'
import HeaderControls from '@/components/shared/HeaderControls.vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
import AssetAnalysis from '@/components/analysis/AssetAnalysis.vue'
import PriceStatistics from '@/components/analysis/PriceStatistics.vue'
import SeasonalStatistics from '@/components/analysis/SeasonalStatistics.vue'

export default {
  name: 'AnalysisView',
  
  components: {
    HeaderControls,
    TerminalHeader,
    AssetAnalysis,
    PriceStatistics,
    SeasonalStatistics
  },

  setup() {
    const timeframe = ref('daily')
    const startYear = ref(2021)
    const currentYear = new Date().getFullYear()
    const symbol = ref('BTC-USD')
    const selectedSymbols = ref(['BTC-USD'])
    const loading = ref(false)
    const error = ref(null)
    const analysisData = ref({})
    const activeView = ref('analysis')

    const displaySymbol = computed(() => {
      if (selectedSymbols.value.length > 0) {
        return selectedSymbols.value.length === 1 
          ? selectedSymbols.value[0] 
          : `Multiple (${selectedSymbols.value.length})`
      }
      return symbol.value
    })

    // Format date in a readable way
    const formatDate = (dateStr) => {
      return new Date(dateStr).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }

    // Format price with appropriate decimals
    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(price)
    }

    // Format percentage
    const formatPercentage = (pct) => {
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        signDisplay: 'always'
      }).format(pct / 100)
    }

    // Watch for changes that should trigger data refresh
    watch([timeframe, startYear, symbol, selectedSymbols], () => {
      fetchData()
    })

    // Fetch data from API
    const fetchData = async () => {
      loading.value = true
      error.value = null
      try {
        const symbolParam = selectedSymbols.value.length > 0
          ? selectedSymbols.value.join(',')
          : symbol.value

        const response = await api.get('/analysis', {
          params: {
            symbol: symbolParam,
            timeframe: timeframe.value,
            startYear: startYear.value
          }
        })

        analysisData.value = response.data.results || { [symbolParam]: response.data }
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }

    // Initial data fetch
    onMounted(() => {
      fetchData()
    })

    return {
      timeframe,
      startYear,
      currentYear,
      symbol,
      selectedSymbols,
      loading,
      error,
      displaySymbol,
      analysisData,
      activeView,
      formatDate,
      formatPrice,
      formatPercentage
    }
  }
}
</script>

<style scoped>
.analysis-view {
  font-family: 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
}

.terminal-loading {
  color: var(--terminal-yellow);
  padding: 1rem;
  text-align: center;
}

.terminal-error {
  color: var(--terminal-red);
  padding: 1rem;
  text-align: center;
}

.terminal-header {
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid var(--terminal-border);
}

.terminal-info {
  color: var(--terminal-cyan);
  text-align: center;
  padding: 2rem;
  font-style: italic;
}

.asset-analysis {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
}

.asset-header {
  font-size: 1.1em;
  color: var(--terminal-green);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--terminal-border);
}

.metric-section {
  margin-bottom: 0.75rem;
}

.metric-header {
  color: var(--terminal-yellow);
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.metric-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.metric {
  padding: 1rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
}

.metric-label {
  color: var(--terminal-cyan);
  margin-bottom: 0.8rem;
}

.metric-value {
  font-size: .8em;
  margin-bottom: 0.5rem;
}

.metric-value.negative {
  color: var(--terminal-red);
}

.metric-detail {
  font-size: 0.9em;
  color: var(--terminal-text-dim);
}

.distribution-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.stat {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
}

.stat-label {
  color: var(--terminal-cyan);
}

.stat-value {
  color: var(--terminal-text);
}

/* Add this to your existing CSS variables if not already present */
:root {
  --terminal-bg-darker: #1a1a1a;
}

.metric-grid.compact {
  display: flex;
  gap: 1.5rem;
  padding: 0.5rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  background: var(--terminal-bg-darker);
}

.metric-grid.compact .metric {
  border: none;
  padding: 0;
  flex: 1;
}

.metric-grid.compact .metric-label {
  display: inline;
  margin-right: 0.5rem;
}

.metric-grid.compact .metric-value {
  display: inline;
}

.distribution-stats.compact {
  display: flex;
  gap: 1.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  background: var(--terminal-bg-darker);
}

.distribution-stats.compact .stat {
  border: none;
  padding: 0;
  flex: 1;
  white-space: nowrap;
}

.view-controls {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  background: var(--terminal-bg-darker);
}

.button-group {
  display: flex;
  gap: 2px;
}

.terminal-button {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9em;
  min-width: 120px;
  text-align: center;
}

.terminal-button:hover {
  border-color: var(--terminal-green);
  color: var(--terminal-green);
}

.terminal-button.active {
  background: transparent;
  color: var(--terminal-green);
  border-color: var(--terminal-green);
}
</style> 