<template>
  <div class="collapsible-panel" :class="{ collapsed: isCollapsed }">
    <div class="panel-header">
      <div class="header-left">
        <button class="collapse-btn" @click="toggleCollapse">
          {{ isCollapsed ? '▼' : '▲' }}
        </button>
        <h2>{{ title }}</h2>
      </div>
      <slot name="header-right"></slot>
    </div>
    <div class="panel-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsiblePanel',
  props: {
    title: {
      type: String,
      required: true
    },
    initialCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: this.initialCollapsed
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      this.$emit('collapse-change', this.isCollapsed);
    }
  }
}
</script>

<style scoped>
.collapsible-panel {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #33ff33;
  border-radius: 5px;
  padding: 15px;
  transition: all 0.3s ease;
}

.collapsible-panel.collapsed {
  padding: 10px 15px;
}

.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(51, 255, 51, 0.2);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.collapse-btn {
  background: none;
  border: none;
  color: var(--terminal-text, #33ff33);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.collapse-btn:hover {
  color: var(--terminal-dim, #aaffaa);
}

.collapsible-panel.collapsed .panel-content {
  display: none;
}

h2 {
  color: #33ff33;
  margin: 0;
}
</style> 