<template>
  <div class="terminal-controls">
    <div class="control-group">
      <label>Select Asset{{ allowMultiSelect ? 's' : '' }}:</label>
      
      <!-- Loading state -->
      <div v-if="loading" class="loading-state">
        Loading assets...
      </div>
      
      <!-- Error state -->
      <div v-else-if="error" class="error-state">
        {{ error }}
      </div>
      
      <!-- Single select mode -->
      <select 
        v-else-if="!allowMultiSelect"
        :value="symbol"
        @input="$emit('update:symbol', $event.target.value)"
        class="terminal-select"
      >
        <template v-if="assetOptions.grouped">
          <optgroup 
            v-for="(assets, sector) in groupedAssets" 
            :key="sector"
            :label="sector.charAt(0).toUpperCase() + sector.slice(1)"
          >
            <option 
              v-for="option in assets" 
              :key="option.value" 
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </optgroup>
        </template>
        <template v-else>
          <option 
            v-for="option in flatAssets" 
            :key="option.value" 
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </template>
      </select>

      <!-- Multi select mode -->
      <div v-else class="multi-select-container" @click.stop>
        <div 
          class="multi-select-display"
          :class="{ active: isOpen }"
          @click="toggleDropdown"
        >
          <span v-if="selectedSymbols.length === 0 && !symbol">Select assets...</span>
          <span v-else-if="selectedSymbols.length === 0">{{ getOptionLabel(symbol) }}</span>
          <span v-else>{{ selectedLabels }}</span>
          <span class="arrow">▼</span>
        </div>
        
        <div v-if="isOpen" class="multi-select-dropdown">
          <template v-if="assetOptions.grouped">
            <div v-for="(assets, sector) in groupedAssets" :key="sector">
              <div class="sector-header">{{ sector.charAt(0).toUpperCase() + sector.slice(1) }}</div>
              <div 
                v-for="option in assets" 
                :key="option.value"
                class="multi-select-option"
                :class="{ 
                  selected: selectedSymbols.includes(option.value) || 
                           (selectedSymbols.length === 0 && option.value === symbol)
                }"
                @click="toggleOption(option.value)"
              >
                <span class="checkbox">
                  {{ selectedSymbols.includes(option.value) || 
                     (selectedSymbols.length === 0 && option.value === symbol) ? '☒' : '☐' }}
                </span>
                {{ option.label }}
              </div>
            </div>
          </template>
          <template v-else>
            <div 
              v-for="option in flatAssets" 
              :key="option.value"
              class="multi-select-option"
              :class="{ 
                selected: selectedSymbols.includes(option.value) || 
                         (selectedSymbols.length === 0 && option.value === symbol)
              }"
              @click="toggleOption(option.value)"
            >
              <span class="checkbox">
                {{ selectedSymbols.includes(option.value) || 
                   (selectedSymbols.length === 0 && option.value === symbol) ? '☒' : '☐' }}
              </span>
              {{ option.label }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="control-group">
      <label>Select Timeframe:</label>
      <select 
        :value="timeframe"
        @input="$emit('update:timeframe', $event.target.value)"
        class="terminal-select"
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="quarterly">Quarterly</option>
        <option value="yearly">Yearly</option>
      </select>
    </div>

    <div class="control-group">
      <label>Start Year:</label>
      <input 
        type="number" 
        :value="startYear"
        @input="$emit('update:startYear', parseInt($event.target.value))"
        :min="2013" 
        :max="currentYear"
        class="terminal-input"
      />
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'HeatmapControls',
  
  props: {
    timeframe: {
      type: String,
      required: true
    },
    startYear: {
      type: Number,
      required: true
    },
    currentYear: {
      type: Number,
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    selectedSymbols: {
      type: Array,
      default: () => []
    },
    allowMultiSelect: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:timeframe', 'update:startYear', 'update:symbol', 'update:selectedSymbols'],

  data() {
    return {
      isOpen: false,
      assetOptions: [],
      loading: false,
      error: null
    }
  },

  computed: {
    selectedLabels() {
      if (!this.selectedSymbols.length) {
        return this.symbol ? this.getOptionLabel(this.symbol) : ''
      }
      const labels = this.selectedSymbols
        .map(value => this.getOptionLabel(value))
        .filter(Boolean)
      if (labels.length <= 2) return labels.join(', ')
      return `${labels.length} assets selected`
    },

    // Group assets by sector if available
    groupedAssets() {
      if (!this.assetOptions.grouped) return null;
      return this.assetOptions.assets;
    },

    // Flat list of assets
    flatAssets() {
      if (this.assetOptions.grouped) {
        // Flatten grouped assets
        return Object.values(this.assetOptions.assets).flat();
      }
      return this.assetOptions.assets || [];
    }
  },

  async created() {
    await this.fetchAssets();
  },

  mounted() {
    document.addEventListener('click', this.closeDropdown)
  },

  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown)
  },

  methods: {
    async fetchAssets() {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.get('/assets');
        if (response.data.success) {
          this.assetOptions = response.data;
        } else {
          throw new Error(response.data.error || 'Failed to fetch assets');
        }
      } catch (error) {
        console.error('Error fetching assets:', error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen
    },

    closeDropdown() {
      this.isOpen = false
    },

    getOptionLabel(value) {
      // Check in flat assets list
      const option = this.flatAssets.find(opt => opt.value === value);
      return option ? option.label : value;
    },

    toggleOption(value) {
      if (this.selectedSymbols.length === 0 && value === this.symbol) {
        this.$emit('update:selectedSymbols', [value])
      } else {
        const newSelection = [...this.selectedSymbols]
        const index = newSelection.indexOf(value)
        
        if (index === -1) {
          newSelection.push(value)
        } else {
          newSelection.splice(index, 1)
        }
        
        this.$emit('update:selectedSymbols', newSelection)
      }
    }
  }
}
</script>

<style scoped>
.terminal-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--terminal-border);
}

.terminal-select,
.terminal-input,
.multi-select-display {
  background: var(--terminal-bg);
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
  padding: 0.25rem;
  min-width: 150px;
}

.multi-select-container {
  position: relative;
}

.multi-select-display {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.multi-select-display.active {
  border-color: #00ff00;
}

.arrow {
  font-size: 0.8em;
  margin-left: 0.5rem;
}

.multi-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.multi-select-option {
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.multi-select-option:hover {
  background: rgba(0, 255, 0, 0.1);
}

.multi-select-option.selected {
  background: rgba(0, 255, 0, 0.2);
}

.checkbox {
  color: #00ff00;
  font-family: monospace;
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

label {
  color: #00ff00;
  font-size: 0.9em;
}

.loading-state,
.error-state {
  padding: 0.5rem;
  border: 1px solid var(--terminal-border);
  background: var(--terminal-bg);
  color: var(--terminal-text);
}

.error-state {
  color: #ff0000;
}

.sector-header {
  padding: 0.5rem;
  background: rgba(0, 255, 0, 0.1);
  font-weight: bold;
  color: #00ff00;
  border-bottom: 1px solid var(--terminal-border);
}
</style> 