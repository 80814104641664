<template>
  <div class="dashboard-panel">
    <div class="panel-header">Key Statistics</div>
    <div class="panel-content">
      <div class="stats-grid">
        <div class="stat-item">
          <div class="stat-label">Current Price</div>
          <div class="stat-value" ref="currentPrice">$0</div>
        </div>
        <div class="stat-item">
          <div class="stat-label">Target Price</div>
          <div class="stat-value" ref="targetPrice">$0</div>
        </div>
        <div class="stat-item">
          <div class="stat-label">Total Simulations</div>
          <div class="stat-value" ref="totalSims">0</div>
        </div>
        <div class="stat-item">
          <div class="stat-label">Processing Time</div>
          <div class="stat-value" ref="processingTime">0s</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const currentPrice = ref(null)
const targetPrice = ref(null)
const totalSims = ref(null)
const processingTime = ref(null)

function updateStats(data) {
  if (!currentPrice.value || !targetPrice.value || !totalSims.value || !processingTime.value) return

  currentPrice.value.textContent = data.currentPrice
  targetPrice.value.textContent = data.projections['365']?.target || '$0'
  totalSims.value.textContent = data.totalSimulations
  processingTime.value.textContent = data.processingTime
}

defineExpose({
  updateStats
})
</script> 