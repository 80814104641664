<template>
  <div class="heatmap-view">
    <TerminalHeader title="Returns Heatmap" />
    
    <HeaderControls
      :timeframe="timeframe"
      :startYear="startYear"
      :currentYear="currentYear"
      :symbol="symbol"
      :selected-symbols="selectedSymbols"
      :allow-multi-select="timeframe === 'yearly' || timeframe === 'monthly'"
      @update:timeframe="timeframe = $event"
      @update:startYear="startYear = $event"
      @update:symbol="symbol = $event"
      @update:selectedSymbols="selectedSymbols = $event"
    />

    <!-- Loading State -->
    <div v-if="loading" class="terminal-loading">
      Loading data... ⣾⣽⣻⢿⡿⣟⣯⣷
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="terminal-error">
      Error: {{ error }}
    </div>

    <!-- Heatmap Display -->
    <div v-else class="heatmap-display">
      <div class="terminal-header">
        Asset Return Heatmaps - {{ displaySymbol }}
      </div>
      
      <HeatmapStats 
        v-if="(timeframe !== 'yearly' && timeframe !== 'monthly') || selectedSymbols.length <= 1"
        :summary="summary" 
      />
      
      <HeatmapDaily 
        v-if="timeframe === 'daily'"
        :timeframe="timeframe"
        :dailyReturns="dailyReturns"
      />
      
      <HeatmapWeekly
        v-else-if="timeframe === 'weekly'"
        :timeframe="timeframe"
        :weeklyReturns="weeklyReturns"
        :summary="summary"
      />
      
      <HeatmapMonthly
        v-else-if="timeframe === 'monthly'"
        :timeframe="timeframe"
        :monthlyReturns="monthlyReturns"
        :summary="summary"
        :symbols="selectedSymbols.length ? selectedSymbols : [symbol]"
      />
      
      <HeatmapQuarterly
        v-else-if="timeframe === 'quarterly'"
        :timeframe="timeframe"
        :quarterlyReturns="quarterlyReturns"
        :summary="summary"
      />
      
      <HeatmapYearly
        v-else-if="timeframe === 'yearly'"
        :timeframe="timeframe"
        :yearlyReturns="yearlyReturns"
        :summary="summary"
        :symbols="selectedSymbols.length ? selectedSymbols : [symbol]"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue'
import api from '@/utils/api'
import HeaderControls from '@/components/shared/HeaderControls.vue'
import HeatmapStats from '@/components/heatmaps/HeatmapStats.vue'
import HeatmapDaily from '@/components/heatmaps/HeatmapDaily.vue'
import HeatmapWeekly from '@/components/heatmaps/HeatmapWeekly.vue'
import HeatmapMonthly from '@/components/heatmaps/HeatmapMonthly.vue'
import HeatmapQuarterly from '@/components/heatmaps/HeatmapQuarterly.vue'
import HeatmapYearly from '@/components/heatmaps/HeatmapYearly.vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
// Add HeatmapYearly
// Also consider adding Seasonality Analysis. Which companies do best and worst on certains days of the week? Best worst periods historically? Consecutive streaks of up and down days. Frequency of moves...
export default {
  name: 'HeatmapView',
  
  components: {
    HeaderControls,
    HeatmapStats,
    HeatmapDaily,
    HeatmapWeekly,
    HeatmapMonthly,
    HeatmapQuarterly,
    HeatmapYearly,
    TerminalHeader
  },

  setup() {
    const timeframe = ref('yearly')
    const startYear = ref(2020)
    const currentYear = new Date().getFullYear()
    const symbol = ref('BTC-USD')
    const selectedSymbols = ref(timeframe.value === 'yearly' ? ['BTC-USD', 'QQQ', 'SPY', 'IWM'] : [])
    const loading = ref(false)
    const error = ref(null)
    const dailyReturns = ref([])
    const weeklyReturns = ref([])
    const monthlyReturns = ref([])
    const quarterlyReturns = ref([])
    const yearlyReturns = ref([])
    const summary = ref(null)

    const displaySymbol = computed(() => {
      if ((timeframe.value === 'yearly' || timeframe.value === 'monthly') && selectedSymbols.value.length > 0) {
        return `Multiple (${selectedSymbols.value.length})`
      }
      return symbol.value
    })

    // Watch for changes in timeframe
    watch(timeframe, (newTimeframe, oldTimeframe) => {
      if (newTimeframe === 'yearly' && oldTimeframe !== 'yearly') {
        // Set default tickers only when switching to yearly view
        selectedSymbols.value = ['BTC-USD', 'QQQ', 'SPY', 'IWM']
      } else if (newTimeframe !== 'yearly' && newTimeframe !== 'monthly') {
        // Clear selections when switching to non-multi-select views
        selectedSymbols.value = []
      }
      fetchData()
    })

    // Watch for other control changes
    watch([startYear, symbol, selectedSymbols], () => {
      fetchData()
    })

    // Fetch data from API
    const fetchData = async () => {
      loading.value = true
      error.value = null
      try {
        // Use selectedSymbols for yearly and monthly views when multiple symbols are selected
        const useMultiSymbols = (timeframe.value === 'yearly' || timeframe.value === 'monthly') && 
                               selectedSymbols.value.length > 0;
        
        const symbolParam = useMultiSymbols
          ? selectedSymbols.value.join(',')
          : symbol.value

        const response = await api.get('/heatmap', {
          params: {
            symbol: symbolParam,
            timeframe: timeframe.value,
            startYear: startYear.value
          }
        })

        // Handle response based on whether it's multi-symbol or single symbol
        if (response.data.symbols) {
          // Multi-symbol response
          if (timeframe.value === 'yearly') {
            yearlyReturns.value = response.data.results
          } else if (timeframe.value === 'monthly') {
            monthlyReturns.value = response.data.results
          }
          
          summary.value = Object.keys(response.data.results).reduce((acc, symbol) => {
            acc[symbol] = response.data.results[symbol].summary
            return acc
          }, {})
        } else {
          // Single symbol response - maintain backward compatibility
          dailyReturns.value = response.data.dailyReturns
          weeklyReturns.value = response.data.weeklyReturns
          monthlyReturns.value = response.data.returns
          quarterlyReturns.value = timeframe.value === 'quarterly' ? response.data.returns : []
          yearlyReturns.value = timeframe.value === 'yearly' ? response.data.returns : []
          summary.value = response.data.summary
        }
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }

    // Initial data fetch
    onMounted(() => {
      fetchData()
    })

    return {
      timeframe,
      startYear,
      currentYear,
      symbol,
      selectedSymbols,
      loading,
      error,
      dailyReturns,
      weeklyReturns,
      monthlyReturns,
      quarterlyReturns,
      yearlyReturns,
      summary,
      displaySymbol
    }
  }
}
</script>

<style scoped>
.heatmap-view {
  font-family: 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
}

.terminal-loading {
  color: var(--terminal-yellow);
  padding: 1rem;
  text-align: center;
}

.terminal-error {
  color: var(--terminal-red);
  padding: 1rem;
  text-align: center;
}

.terminal-header {
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid var(--terminal-border);
}
</style> 