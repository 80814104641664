<template>
  <div class="chart-section">
    <h3>{{ title }}</h3>
    <div class="chart-container" ref="chartContainer"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'

const props = defineProps({
  title: {
    type: String,
    default: 'Projection Chart'
  },
  startDate: {
    type: Date,
    default: () => new Date('2025-01-01')
  },
  assets: {
    type: Object,
    required: true
  },
  monthsToProject: {
    type: Number,
    default: 61
  }
})

const chartContainer = ref(null)

function calculateProjectionData() {
  const months = Array.from({ length: props.monthsToProject }, (_, i) => {
    const date = new Date(props.startDate)
    date.setMonth(props.startDate.getMonth() + i)
    return date
  })

  // Sort assets by their initial value (largest to smallest)
  const sortedAssets = Object.entries(props.assets)
    .sort((a, b) => b[1].value - a[1].value)
    .map(([key]) => key)

  const lineData = []
  const totalValues = new Array(months.length).fill(0)

  // Create individual lines for each asset
  sortedAssets.forEach(key => {
    const asset = props.assets[key]
    const values = months.map((_, i) => {
      const value = asset.value * Math.pow(1 + asset.return / 100 / 12, i) / 1000 // Convert to thousands
      totalValues[i] += value
      return value
    })

    lineData.push({
      x: months,
      y: values,
      type: 'scatter',
      mode: 'lines',
      name: formatLabel(key),
      line: {
        color: key === 'stocks' ? '#0000ff' :
               key === 'realEstate' ? '#008080' :
               key === 'business' ? '#ff0000' :
               key === 'bitcoin' ? '#ffff00' :
               '#ff00ff',
        width: 2
      }
    })
  })

  // Add total line
  lineData.push({
    x: months,
    y: totalValues,
    type: 'scatter',
    mode: 'lines',
    name: 'Net Worth',
    line: {
      color: '#33ff33',
      width: 2,
      dash: 'dot'
    }
  })

  // Calculate max value for y-axis range
  const maxValue = Math.max(...totalValues)
  const yAxisMax = Math.ceil(maxValue / 1000) * 1000

  return {
    data: lineData,
    yAxisMax
  }
}

const chartLayout = {
  paper_bgcolor: 'rgba(0,0,0,1)',
  plot_bgcolor: 'rgba(0,0,0,1)',
  font: {
    color: '#33ff33',
    family: 'JetBrains Mono, monospace',
    size: 10
  },
  margin: {
    l: 60,
    r: 20,
    t: 20,
    b: 60
  },
  xaxis: {
    showgrid: true,
    gridcolor: '#333333',
    gridwidth: 1,
    type: 'date',
    tickformat: '%b %Y',
    tickangle: -45,
    dtick: 'M6',
    showline: false,
    tickfont: {
      family: 'JetBrains Mono, monospace',
      color: '#33ff33'
    }
  },
  yaxis: {
    showgrid: true,
    gridcolor: '#333333',
    gridwidth: 1,
    tickprefix: '$',
    ticksuffix: 'K',
    showline: false,
    tickfont: {
      family: 'JetBrains Mono, monospace',
      color: '#33ff33'
    },
    tickformat: ',d'
  },
  showlegend: true,
  legend: {
    orientation: 'h',
    y: -0.15,
    x: 0.5,
    xanchor: 'center',
    yanchor: 'top',
    font: {
      size: 10,
      family: 'JetBrains Mono, monospace',
      color: '#33ff33'
    },
    bgcolor: 'rgba(0,0,0,1)',
    tracegroupgap: 5
  },
  hovermode: 'x unified',
  autosize: true
}

function formatLabel(key) {
  return key.replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function initChart() {
  if (!chartContainer.value) return

  const { data, yAxisMax } = calculateProjectionData()
  const layout = { 
    ...chartLayout, 
    width: chartContainer.value.offsetWidth,
    yaxis: {
      ...chartLayout.yaxis,
      range: [0, yAxisMax]
    }
  }
  
  Plotly.newPlot(chartContainer.value, data, layout, {
    displayModeBar: false,
    responsive: true
  })
}

// Add resize handler
let resizeTimeout
function handleResize() {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    if (chartContainer.value) {
      Plotly.relayout(chartContainer.value, {
        width: chartContainer.value.offsetWidth
      })
    }
  }, 100)
}

// Lifecycle hooks
onMounted(() => {
  window.addEventListener('resize', handleResize)
  initChart()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})

// Watch for changes in assets to update chart
watch(() => props.assets, () => {
  initChart()
}, { deep: true })
</script>

<style scoped>
.chart-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--terminal-bg);
  min-height: 450px;
}

.chart-container {
  flex: 1;
  min-height: 450px;
  border-radius: 4px;
  padding: 0;
  margin-top: 0;
  position: relative;
}

h3 {
  color: var(--terminal-text);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
</style> 