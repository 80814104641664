<template>
  <div :class="containerClass" :style="containerStyle">
    <h3 v-if="showHeader">{{ title }}</h3>
    <div ref="logContent" class="terminal-content" :style="contentStyle"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  title: {
    type: String,
    default: 'Terminal Output'
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  containerClass: {
    type: String,
    default: 'dashboard-panel'
  },
  containerStyle: {
    type: String,
    default: ''
  },
  contentStyle: {
    type: String,
    default: 'height: 120px; overflow-y: auto;'
  }
})

const logContent = ref(null)

const appendLog = (message) => {
  if (!logContent.value) return

  const entry = document.createElement('div')
  entry.className = 'log-entry'
  const timestamp = new Date().toLocaleTimeString()

  // Handle table formatting if present
  if (message.includes('│')) {
    entry.innerHTML = `<span class="timestamp">[${timestamp}]</span> ${convertTableToHtml(message)}`
  } else {
    // Handle regular log with ANSI colors
    const formattedMessage = convertAnsiToHtml(message)
    entry.innerHTML = `<span class="timestamp">[${timestamp}]</span> ${formattedMessage}`
  }

  logContent.value.appendChild(entry)
  logContent.value.scrollTop = logContent.value.scrollHeight
}

const convertAnsiToHtml = (text) => {
  return text
    .replace(/\[32m/g, '<span class="green">')  // Green
    .replace(/\[31m/g, '<span class="red">')    // Red
    .replace(/\[37m/g, '<span class="white">')  // White
    .replace(/\[33m/g, '<span class="yellow">')  // Yellow
    .replace(/\[36m/g, '<span class="cyan">')   // Cyan
    .replace(/\[0m/g, '</span>')                // Reset
    .replace(/\[1m/g, '<span class="bold">')    // Bold
}

const convertTableToHtml = (text) => {
  const lines = text.split('\n')
  let tableHtml = '<div class="table-container"><table class="terminal-table">'

  lines.forEach(line => {
    if (line.trim() && !line.includes('─')) {
      const cells = line.split('│').map(cell => cell.trim()).filter(cell => cell)
      const isHeader = line.includes('(index)')

      if (cells.length) {
        tableHtml += '<tr>'
        cells.forEach(cell => {
          tableHtml += isHeader ? `<th>${cell}</th>` : `<td>${cell}</td>`
        })
        tableHtml += '</tr>'
      }
    }
  })

  return tableHtml + '</table></div>'
}

defineExpose({
  appendLog
})
</script>

<style scoped>
.terminal-content {
  font-family: 'JetBrains Mono', monospace;
}
</style> 