<template>
  <div class="options-view">
    <TerminalHeader title="Options Analysis" />
    
    <!-- Controls Row -->
    <div class="controls-row">
      <!-- Asset Selection -->
      <OptionControls 
        :default-contract="defaultContract"
        @update:contract="handleContractUpdate" 
      />

      <!-- View Toggle Buttons -->
      <div class="button-group">
        <button 
          class="terminal-button"
          :class="{ active: activeView === 'optionChart' }"
          @click="activeView = 'optionChart'"
        >
          Option Chart
        </button>
        <button 
          class="terminal-button"
          :class="{ active: activeView === 'history' }"
          @click="activeView = 'history'"
        >
          Price History
        </button>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="terminal-loading">
      Loading data... ⣾⣽⣻⢿⡿⣟⣯⣷
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="terminal-error">
      Error: {{ error }}
    </div>

    <!-- Options Analysis Display -->
    <div v-else class="analysis-display">
      <!-- Analysis Content -->
      <div class="analysis-content">
        <div v-if="priceHistory">
          <!-- Chart -->
          <div v-show="activeView === 'optionChart'">
            <OptionsHistoryChart 
              :data="priceHistory.data"
              :symbol="priceHistory.symbol"
              :strike="Number(priceHistory.strike)"
              :option-type="priceHistory.optionType"
              :expiry="priceHistory.expiry"
            />
          </div>

          <!-- Table View -->
          <div v-show="activeView === 'history'">
            <OptionsHistoryTable 
              :data="priceHistory.data" 
              :symbol="priceHistory.symbol"
              :strike="Number(priceHistory.strike)"
              :option-type="priceHistory.optionType"
              :expiry="priceHistory.expiry"
            />
          </div>
        </div>
        <div v-else class="terminal-info">
          Select an option contract to begin analysis
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import api from '@/utils/api'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
import OptionControls from '@/components/shared/OptionControls.vue'
import OptionsHistoryTable from '@/components/price-history/OptionsHistoryTable.vue'
import OptionsHistoryChart from '@/components/price-history/OptionsHistoryChart.vue'

export default {
  name: 'OptionsView',
  
  components: {
    TerminalHeader,
    OptionControls,
    OptionsHistoryTable,
    OptionsHistoryChart
  },

  setup() {
    const loading = ref(false)
    const error = ref(null)
    const activeView = ref('optionChart')
    const priceHistory = ref(null)

    // Add default contract configuration
    const defaultContract = {
      symbol: 'IREN',
      expiry: '2026-01-16',
      strike: 20,
      type: 'C'
    }

    // Fetch data for default contract on mount
    onMounted(async () => {
      await handleContractUpdate(defaultContract)
    })

    // Handle contract selection/updates from OptionControls
    const handleContractUpdate = async (contract) => {
      loading.value = true
      error.value = null

      try {
        const response = await api.get('/price-history', {
          params: {
            type: 'option',
            symbol: contract.symbol,
            strike: contract.strike,
            expiry: contract.expiry,
            optionType: contract.type,
            days: 500 // TODO - add support for different timeframes
          }
        })

        priceHistory.value = response.data
      } catch (err) {
        console.error('Error fetching price history:', err)
        error.value = err.message || 'Failed to fetch price history'
      } finally {
        loading.value = false
      }
    }

    // Format date in a readable way
    const formatDate = (dateStr) => {
      // Create date with UTC to prevent timezone shifting
      const date = new Date(dateStr + 'T00:00:00Z');
      
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC'  // Force UTC to prevent timezone shifting
      }).format(date);
    }

    // Format price with appropriate decimals
    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(price)
    }

    return {
      loading,
      error,
      activeView,
      priceHistory,
      formatDate,
      formatPrice,
      handleContractUpdate,
      defaultContract
    }
  }
}
</script>

<style scoped>
.options-view {
  font-family: 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
}

.terminal-loading {
  color: var(--terminal-yellow);
  padding: 1rem;
  text-align: center;
}

.terminal-error {
  color: var(--terminal-red);
  padding: 1rem;
  text-align: center;
}

.terminal-header {
  text-align: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--terminal-border);
}

.terminal-info {
  color: var(--terminal-cyan);
  text-align: center;
  padding: 2rem;
  font-style: italic;
}

.view-controls {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  background: var(--terminal-bg-darker);
}

.button-group {
  display: flex;
  gap: 2px;
}

.terminal-button {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9em;
  min-width: 120px;
  text-align: center;
}

.terminal-button:hover {
  border-color: var(--terminal-green);
  color: var(--terminal-green);
}

.terminal-button.active {
  background: transparent;
  color: var(--terminal-green);
  border-color: var(--terminal-green);
}

.analysis-display {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
}

.contract-info {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  background: var(--terminal-bg-darker);
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.info-item {
  display: flex;
  gap: 0.5rem;
}

.info-item .label {
  color: var(--terminal-cyan);
}

.info-item .value {
  color: var(--terminal-green);
}

/* Controls row layout */
.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: var(--terminal-bg-darker);
  border-bottom: 1px solid var(--terminal-border);
}

.button-group {
  display: flex;
  gap: 2px;
}

.terminal-button {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9em;
  min-width: 120px;
  text-align: center;
}

.terminal-button:hover {
  border-color: var(--terminal-green);
  color: var(--terminal-green);
}

.terminal-button.active {
  background: transparent;
  color: var(--terminal-green);
  border-color: var(--terminal-green);
}
</style> 