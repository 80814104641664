<template>
  <div class="distribution-panel">
    <div ref="chartContainer" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'

const chartContainer = ref(null)
const props = defineProps({
  asset: {
    type: String,
    required: true
  },
  distribution: {
    type: Object,
    required: true
  },
  timeframe: {
    type: String,
    required: true
  }
})

// Chart configuration (matching terminal theme)
const chartLayout = {
  paper_bgcolor: '#000000',
  plot_bgcolor: '#000000',
  font: {
    color: '#33ff33',
    family: 'JetBrains Mono, monospace',
    size: 12
  },
  margin: {
    l: 60,
    r: 60,
    t: 90,
    b: 40
  },
  showlegend: true,
  legend: {
    x: 0.02,
    y: 0.98,
    bgcolor: 'rgba(0,0,0,0.7)',
    bordercolor: '#33ff33',
    borderwidth: 1,
    font: {
      color: '#33ff33',
      size: 11
    }
  },
  xaxis: {
    showgrid: true,
    gridcolor: '#1a1a1a',
    gridwidth: 1,
    zerolinecolor: '#33ff33',
    zerolinewidth: 2,
    tickfont: {
      color: '#33ff33',
      size: 11
    },
    ticksuffix: '%'
  },
  yaxis: {
    showgrid: true,
    gridcolor: '#1a1a1a',
    gridwidth: 1,
    zerolinecolor: '#1a1a1a',
    tickfont: {
      color: '#33ff33',
      size: 11
    },
    title: {
      text: 'Frequency',
      font: {
        color: '#33ff33',
        size: 12
      },
      standoff: 10
    },
    type: 'log',
    dtick: 'D2',
    tickformat: 'd',
    autorange: true
  },
  bargap: 0.1,
  bargroupgap: 0.1
}

const chartConfig = {
  responsive: true,
  displayModeBar: false
}

// Initialize distribution chart
function initChart() {
  if (!chartContainer.value) return

  // Initial empty distribution
  const data = [{
    name: 'Distribution',
    type: 'bar',
    x: [],
    y: [],
    marker: {
      color: '#33ff33',
      line: {
        color: '#1a1a1a',
        width: 1
      }
    },
    opacity: 0.8
  }, {
    name: 'Cumulative %',
    type: 'scatter',
    x: [],
    y: [],
    yaxis: 'y2',
    line: {
      color: '#ffffff',
      width: 2
    },
    opacity: 0.6
  }]

  const layout = {
    ...chartLayout,
    title: {
      text: `${props.asset} Return Distribution (${props.timeframe})`,
      font: { color: '#33ff33' },
      y: 0.98,
      pad: { b: 30, t: 20 }
    },
    xaxis: {
      ...chartLayout.xaxis
    },
    yaxis: {
      ...chartLayout.yaxis,
      title: {
        text: 'Frequency',
        font: { color: '#33ff33' }
      }
    },
    yaxis2: {
      title: {
        text: 'Cumulative %',
        font: { color: '#ffffff' }
      },
      overlaying: 'y',
      side: 'right',
      range: [0, 100],
      ticksuffix: '%',
      showgrid: false,
      tickfont: { color: '#ffffff' }
    },
    shapes: [{
      type: 'line',
      x0: 0,
      x1: 0,
      y0: 0,
      y1: 1,
      yref: 'paper',
      line: {
        color: '#ffff33',
        width: 2,
        dash: 'dash'
      }
    }]
  }

  Plotly.newPlot(chartContainer.value, data, layout, chartConfig)
}

// Update chart with new data
const updateChart = () => {
  if (!chartContainer.value || !props.distribution || !props.distribution.bucketRanges || !props.distribution.bucketDates) return

  try {
    const { distribution, bucketRanges, bucketDates, stats } = props.distribution

    // Convert bucket ranges to percentages for x-axis
    const xValues = bucketRanges.map(range => range.min)
    const yValues = distribution

    // Calculate cumulative distribution
    const total = distribution.reduce((a, b) => a + b, 0)
    let cumSum = 0
    const cumulativeY = distribution.map(count => {
      cumSum += count
      return (cumSum / total) * 100
    })

    // Create plot data
    const plotData = [{
      name: 'Distribution',
      type: 'bar',
      x: xValues,
      y: yValues,
      marker: {
        color: xValues.map(x => {
          if (x < 0) return '#ff3333'      // Red for negative returns
          return '#33ff33'                   // Green for positive returns
        }),
        line: {
          color: '#000000',
          width: 1
        }
      },
      opacity: 0.8,
      customdata: bucketRanges.map((range, i) => ({
        lastDate: bucketDates[i] && bucketDates[i].length > 0 ? bucketDates[i][bucketDates[i].length - 1] : '',
        maxReturn: range.max
      })),
      hovertemplate: 'Return Range: %{x:.1f}% to %{customdata.maxReturn:.1f}%<br>Count: %{y}<br>Last Date: %{customdata.lastDate}<extra></extra>'
    }, {
      name: 'Cumulative %',
      type: 'scatter',
      x: xValues,
      y: cumulativeY,
      yaxis: 'y2',
      line: {
        color: '#ffffff',
        width: 2
      },
      opacity: 0.7,
      hovertemplate: 'Return: %{x:.1f}%<br>Cumulative: %{y:.1f}%<extra></extra>'
    }]

    const layout = {
      ...chartLayout,
      title: {
        text: `${props.asset} (${props.timeframe} returns distribution)`,
        font: { 
          color: '#33ff33',
          size: 14
        },
        y: 0.98,
        pad: { b: 30, t: 20 }
      },
      yaxis2: {
        title: {
          text: 'Cumulative %',
          font: {
            color: '#ffffff',
            size: 12
          },
          standoff: 10
        },
        overlaying: 'y',
        side: 'right',
        range: [0, 100],
        ticksuffix: '%',
        showgrid: false,
        tickfont: { 
          color: '#ffffff',
          size: 11
        }
      },
      shapes: [
        // Zero line
        {
          type: 'line',
          x0: 0,
          x1: 0,
          y0: 0,
          y1: 1,
          yref: 'paper',
          line: {
            color: '#33ff33',
            width: 2,
            dash: 'dot'
          }
        },
        // Mean line (only if mean exists)
        ...(stats.mean != null ? [{
          type: 'line',
          x0: stats.mean,
          x1: stats.mean,
          y0: 0,
          y1: 1,
          yref: 'paper',
          line: {
            color: '#00ffff',
            width: 2,
            dash: 'dash'
          }
        }] : [])
      ],
      annotations: stats.mean != null ? [{
        x: stats.mean,
        y: 1,
        xref: 'x',
        yref: 'paper',
        text: `Mean: ${stats.mean.toFixed(2)}%`,
        showarrow: true,
        arrowhead: 2,
        arrowcolor: '#00ffff',
        font: {
          color: '#00ffff',
          size: 11
        },
        bgcolor: 'rgba(0,0,0,0.7)',
        bordercolor: '#00ffff',
        borderwidth: 1,
        borderpad: 4,
        yshift: -20,
        xshift: 0
      }] : []
    }

    Plotly.react(chartContainer.value, plotData, layout, chartConfig)
  } catch (error) {
    console.error('Error updating distribution chart:', error)
  }
}

// Watch for changes in props
watch(() => props.distribution, () => {
  updateChart()
}, { deep: true })

watch(() => props.timeframe, () => {
  updateChart()
})

onMounted(() => {
  initChart()
  updateChart()
})

onUnmounted(() => {
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})
</script>

<style scoped>
.distribution-panel {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  border-bottom: none;
  border-radius: 4px;
  padding: 0;
  margin-top: 1rem;
}

.distribution-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.distribution-header h3 {
  color: var(--terminal-yellow);
  margin: 0;
  font-size: 1.1em;
}
</style> 
