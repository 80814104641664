import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/Home.vue'
import SimulationView from '@/views/Simulation.vue'
import LoanView from '@/views/Loan.vue'
import BTCExitView from '@/views/BTCExit.vue'
import NetWorthView from '@/views/NetWorth.vue'
import CostBasisView from '@/views/CostBasis.vue'
import BTCFireView from '@/views/BTCFire.vue'
import HeatmapView from '@/views/HeatmapView.vue'
import AnalysisView from '@/views/AnalysisView.vue'
import PriceHistory from '@/views/PriceHistory.vue'
import OptionSellersView from '@/views/OptionSellers.vue'
import OptionScreenerView from '@/views/OptionScreener.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/simulation',
      name: 'simulation',
      component: SimulationView
    },
    {
      path: '/loan',
      name: 'loan',
      component: LoanView
    },
    {
      path: '/btc-exit',
      name: 'BTCExit',
      component: BTCExitView
    },
    {
      path: '/wealth',
      name: 'networth',
      component: NetWorthView
    },
    {
      path: '/cost-basis',
      name: 'costBasis',
      component: CostBasisView
    },
    {
      path: '/btc-fire',
      name: 'btc-fire',
      component: BTCFireView
    },
    {
      path: '/heatmap',
      name: 'heatmap',
      component: HeatmapView
    },
    {
      path: '/analysis',
      name: 'analysis',
      component: AnalysisView
    },
    {
      path: '/price-history',
      name: 'price-history',
      component: PriceHistory
    },
    {
      path: '/option-sellers',
      name: 'option-sellers',
      component: OptionSellersView
    },
    {
      path: '/option-screener',
      name: 'option-screener',
      component: OptionScreenerView
    }
  ]
})

export default router
