<template>
  <div class="loan-panel">
    <h3>Risk Scenarios</h3>
    <div ref="scenariosTable"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue'

const props = defineProps({
  scenarios: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every(scenario => 
        typeof scenario === 'object' && 
        scenario !== null
      )
    }
  }
})

const scenariosTable = ref(null)
const TABLE_HEADERS = ['BTC Price', 'Market Condition', 'LTV', 'Status', 'Price Change']

const updateTable = (scenarios) => {
  console.log('Updating scenarios table with:', scenarios)
  if (!scenarios || !Array.isArray(scenarios)) {
    console.warn('Invalid scenarios data received:', scenarios)
    return
  }

  nextTick(() => {
    const table = document.createElement('table')
    table.className = 'scenario-table'

    // Add header
    const header = table.createTHead()
    const headerRow = header.insertRow()
    TABLE_HEADERS.forEach(text => {
      const th = document.createElement('th')
      th.textContent = text
      headerRow.appendChild(th)
    })

    // Add data rows
    const tbody = table.createTBody()
    scenarios.forEach(scenario => {
      if (!scenario) {
        console.warn('Invalid scenario entry:', scenario)
        return
      }
      
      const row = tbody.insertRow()
      
      // Add cells in order
      Object.values(scenario).forEach(value => {
        const cell = row.insertCell()

        // Add status-based styling
        if (value === 'HEALTHY') {
          cell.className = 'status-healthy'
          cell.textContent = value
        } else if (value === 'MARGIN_CALL') {
          cell.className = 'status-warning'
          cell.innerHTML = `<span style="color: #ffff33">MARGIN_CALL</span>`
        } else if (value === 'LIQUIDATION') {
          cell.className = 'status-danger'
          cell.innerHTML = `<span style="color: #ff3333">LIQUIDATION</span>`
        } else {
          cell.textContent = value
        }
      })
    })

    if (scenariosTable.value) {
      scenariosTable.value.innerHTML = ''
      scenariosTable.value.appendChild(table)
    } else {
      console.error('Could not find scenarios table container')
    }
  })
}

// Watch for changes in scenarios prop
watch(() => props.scenarios, (newScenarios) => {
  if (newScenarios) {
    updateTable(newScenarios)
  }
}, { immediate: true })

onMounted(() => {
  if (props.scenarios) {
    updateTable(props.scenarios)
  }
})
</script> 