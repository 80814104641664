<template>
  <div class="terminal">

    <div class="terminal-content">
      <div class="ascii-art">
        <pre>
  ████████╗███████╗██████╗ ███╗   ███╗███████╗██╗
  ╚══██╔══╝██╔════╝██╔══██╗████╗ ████║██╔════╝██║
     ██║   █████╗  ██████╔╝██╔████╔██║█████╗  ██║
     ██║   ██╔══╝  ██╔══██╗██║╚██╔╝██║██╔══╝  ██║
     ██║   ███████╗██║  ██║██║ ╚═╝ ██║██║     ██║
     ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝     ╚═╝
        </pre>
      </div>

      <div class="menu-container">
        <div class="menu-header">SELECT AN OPTION:</div>
        <div class="menu-items">
          <router-link to="/price-history" class="menu-item">
            <span class="menu-number">📈</span>
            <span class="menu-text">Price History</span>
            <span class="menu-description">View asset price history chart and data</span>
          </router-link>

          <router-link to="/heatmap" class="menu-item">
            <span class="menu-number">🗺️</span>
            <span class="menu-text">Asset Heatmaps</span>
            <span class="menu-description">Asset performance daily, monthly, and annual</span>
          </router-link>

          <router-link to="/analysis" class="menu-item">
            <span class="menu-number">🤓</span>
            <span class="menu-text">Nerd Mode</span>
            <span class="menu-description">Explore asset price trends and data</span>
          </router-link>

          <router-link to="/loan" class="menu-item">
            <span class="menu-number">🏦</span>
            <span class="menu-text">Loan Terminal</span>
            <span class="menu-description">Monitor Bitcoin loan risk scenarios</span>
          </router-link>

          <router-link to="/simulation" class="menu-item">
            <span class="menu-number">📊</span>
            <span class="menu-text">Simulations</span>
            <span class="menu-description">Monte Carlo simulations for Bitcoin</span>
          </router-link>
          
          <router-link to="/option-screener" class="menu-item">
            <span class="menu-number">💰</span>
            <span class="menu-text">Option Screener</span>
            <span class="menu-description">Find cash secured puts opportunities</span>
          </router-link>

          <router-link to="/option-sellers" class="menu-item">
            <span class="menu-number">💰</span>
            <span class="menu-text">Option Calculators</span>
            <span class="menu-description">Calculate option returns and test scenarios</span>
          </router-link>

          <router-link to="/wealth" class="menu-item">
            <span class="menu-number">🏝️</span>
            <span class="menu-text">Net Worth Terminal</span>
            <span class="menu-description">Track and project your path to $10M</span>
          </router-link>

          <router-link to="/btc-fire" class="menu-item">
            <span class="menu-number">🔥</span>
            <span class="menu-text">BTC Fire</span>
            <span class="menu-description">Bitcoin FIRE calculator for retirement</span>
          </router-link>

          <router-link to="/btc-exit" class="menu-item">
            <span class="menu-number">📟</span>
            <span class="menu-text">BTC Exit Planner</span>
            <span class="menu-description">Optimize your Bitcoin exit strategy</span>
          </router-link>

          <router-link to="/cost-basis" class="menu-item">
            <span class="menu-number">💰</span>
            <span class="menu-text">Cost Basis</span>
            <span class="menu-description">Calculate adjusted cost basis for assets</span>
          </router-link>
        </div>

        <div class="system-info">
          <div class="info-item">SYSTEM STATUS: <span class="status-healthy">OPERATIONAL</span></div>
          <div class="info-item">LAST UPDATE: {{ currentTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

defineOptions({
  name: 'HomeView'
})

const currentTime = ref(new Date().toLocaleString())
const statusIndicator = ref(null)
const statusText = ref(null)
let timer
let connectionCheckTimer
let isConnected = ref(false)
let connectionAttempts = 0
const MAX_RETRY_ATTEMPTS = 3
const INITIAL_CONNECTION_DELAY = 3000 // 3 seconds before showing disconnected

function updateConnectionStatus(connected, isInitialCheck = false) {
  if (!statusIndicator.value || !statusText.value) return

  // Remove all possible states
  statusIndicator.value.classList.remove('connected', 'disconnected', 'connecting')
  statusText.value.classList.remove('connected', 'disconnected', 'connecting')

  if (connected) {
    statusIndicator.value.classList.add('connected')
    statusText.value.classList.add('connected')
    statusText.value.textContent = 'READY'
    isConnected.value = true
  } else if (!isInitialCheck) {
    statusIndicator.value.classList.add('disconnected')
    statusText.value.classList.add('disconnected')
    statusText.value.textContent = 'DISCONNECTED'
    isConnected.value = false
  }
}

async function checkServerConnection(isInitialCheck = false) {
  try {
    const response = await fetch('/api/ping')
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    if (!isConnected.value) {
      updateConnectionStatus(true)
      // Clear the interval after successful connection
      if (connectionCheckTimer) {
        clearInterval(connectionCheckTimer)
        connectionCheckTimer = null
      }
    }
    connectionAttempts = 0
  } catch (error) {
    console.error('Error checking server connection:', error)
    // Show disconnected if:
    // 1. We were previously connected (lost connection)
    // 2. This is not the initial check and we've tried at least once
    if (isConnected.value || (!isInitialCheck && connectionAttempts > 0)) {
      updateConnectionStatus(false, isInitialCheck)
    }
    connectionAttempts++
    if (connectionAttempts >= MAX_RETRY_ATTEMPTS) {
      clearInterval(connectionCheckTimer)
    }
  }
}

onMounted(() => {
  timer = setInterval(() => {
    currentTime.value = new Date().toLocaleString()
  }, 1000)

  // Initial connection check with delay
  setTimeout(() => {
    checkServerConnection(true)
    // Start regular connection checks
    connectionCheckTimer = setInterval(() => {
      if (connectionAttempts < MAX_RETRY_ATTEMPTS) {
        checkServerConnection()
      }
    }, 3000)
  }, INITIAL_CONNECTION_DELAY)
})

onUnmounted(() => {
  clearInterval(timer)
  clearInterval(connectionCheckTimer)
})
</script>

<style scoped>
.terminal-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: calc(100vh - 80px); /* Account for header height */
  overflow-y: auto;
}

.ascii-art {
  color: var(--terminal-text);
  text-align: center;
  margin-bottom: 0rem;
  padding-top: 1rem;
}

.ascii-art pre {
  font-family: monospace;
  line-height: 1.2;
  margin: 0;
  font-size: 0.8rem;
}

.menu-container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 0.5rem;
}

.menu-header {
  color: var(--terminal-dim);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--terminal-border);
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.menu-item {
  display: grid;
  grid-template-columns: 2rem 12rem 1fr;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  text-decoration: none;
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.menu-item:hover {
  border-color: var(--terminal-text);
  background: rgba(51, 255, 51, 0.05);
}

.menu-number {
  color: var(--terminal-dim);
  font-weight: bold;
  font-size: 1.5rem;
}

.menu-text {
  font-weight: bold;
}

.menu-description {
  color: var(--terminal-dim);
  font-size: 0.9em;
}

.system-info {
  margin-top: 2.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid var(--terminal-border);
  display: flex;
  justify-content: space-between;
  color: var(--terminal-dim);
  font-size: 0.9em;
}

.info-item {
  display: flex;
  gap: 0.5rem;
}

.status-healthy {
  color: var(--status-healthy);
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status-indicator.connected {
  background-color: #33ff33;
  animation: blink 1s infinite;
}

.status-indicator.disconnected {
  background-color: #ff3333;
  animation: blink 1s infinite;
}

.status-indicator.connecting {
  background-color: #ffff33;
  animation: blink 1s infinite;
}

.status-text.connected {
  color: #33ff33;
}

.status-text.disconnected {
  color: #ff3333;
}

.status-text.connecting {
  color: #ffff33;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
</style> 