<template>
  <div class="dashboard-panel">
    <div class="panel-header">Projection Values</div>
    <div class="panel-content">
      <table class="projections-table">
        <thead>
          <tr>
            <th>Period</th>
            <th>Average</th>
            <th>Target</th>
            <th>Highest</th>
          </tr>
        </thead>
        <tbody ref="tableBody">
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const tableBody = ref(null)

function updateTable(data) {
  if (!tableBody.value || !data) return

  const periods = ['30', '180', '365', '730', '1460']

  // Clear existing rows
  tableBody.value.innerHTML = ''

  // Add rows for each time period
  periods.forEach(period => {
    const projection = data.projections[period]
    if (projection) {
      const row = document.createElement('tr')
      row.innerHTML = `
        <td>${period}d</td>
        <td>$${projection.average.toLocaleString()}</td>
        <td>$${projection.target.toLocaleString()}</td>
        <td>$${projection.highest.toLocaleString()}</td>
      `
      tableBody.value.appendChild(row)
    }
  })
}

defineExpose({
  updateTable
})
</script> 