<template>
  <div class="statistics-panel">
    <div class="statistics-header">
      <h3>Seasonal Statistics</h3>
    </div>
    
    <terminal-table
      first-column-header="Period"
      :headers="symbols"
      :sections="tableSections"
      :initially-expanded="true"
    />
  </div>
</template>

<script>
import TerminalTable from '../shared/TerminalTable.vue'

export default {
  name: 'SeasonalStatistics',
  
  components: {
    TerminalTable
  },

  props: {
    analysisData: {
      type: Object,
      required: true
    }
  },

  computed: {
    symbols() {
      return Object.keys(this.analysisData);
    },

    tableSections() {
      return [
        {
          id: 'monthly',
          title: 'Monthly Returns',
          rows: [
            ...this.expandTopPerformers('monthly', 'best3', 'Best Month'),
            ...this.expandTopPerformers('monthly', 'worst3', 'Worst Month')
          ]
        },
        {
          id: 'quarterly',
          title: 'Quarterly Returns',
          rows: [
            ...this.expandTopPerformers('quarterly', 'best3', 'Best Quarter', 'Q'),
            ...this.expandTopPerformers('quarterly', 'worst3', 'Worst Quarter', 'Q')
          ]
        },
        {
          id: 'weekly',
          title: 'Weekly Patterns',
          rows: [
            ...this.expandTopPerformers('weekly', 'best3', 'Best Week', 'Week'),
            ...this.expandTopPerformers('weekly', 'worst3', 'Worst Week', 'Week')
          ]
        },
        {
          id: 'daily',
          title: 'Day of Week Patterns',
          rows: [
            ...this.expandTopPerformers('daily', 'best3', 'Best Day'),
            ...this.expandTopPerformers('daily', 'worst3', 'Worst Day')
          ]
        },
        {
          id: 'monthDay',
          title: 'Day of Month Patterns',
          rows: [
            ...this.expandTopPerformers('monthDay', 'best3', 'Best Day', 'Day'),
            ...this.expandTopPerformers('monthDay', 'worst3', 'Worst Day', 'Day')
          ]
        }
      ];
    }
  },

  methods: {
    getMetricValues(path) {
      return this.symbols.reduce((acc, sym) => {
        let value = this.analysisData[sym];
        for (const key of path.split('.')) {
          value = value?.[key];
        }
        acc[sym] = value;
        return acc;
      }, {});
    },

    getTopPerformers(interval, type) {
      return this.symbols.reduce((acc, sym) => {
        const performers = this.analysisData[sym]?.seasonality?.[interval]?.[type] || [];
        acc[sym] = performers;
        return acc;
      }, {});
    },

    expandTopPerformers(interval, type, labelPrefix, valuePrefix = '') {
      // Create three rows for each top/bottom performer
      return [0, 1, 2].map(index => ({
        label: `${labelPrefix} #${index + 1}`,
        values: this.symbols.reduce((acc, sym) => {
          const performers = this.analysisData[sym]?.seasonality?.[interval]?.[type] || [];
          const performer = performers[index];
          if (!performer || isNaN(performer.avgReturn)) {
            acc[sym] = 'N/A';
          } else {
            const periodLabel = valuePrefix ? `${valuePrefix}${performer.period}` : performer.period;
            acc[sym] = `${periodLabel} (${this.formatPercentage(performer.avgReturn)})`;
          }
          return acc;
        }, {})
      }));
    },

    formatPercentage(value, digits = 2) {
      if (value === undefined || value === null || isNaN(value)) return 'N/A';
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        signDisplay: 'auto'
      }).format(value / 100);
    }
  }
}
</script>

<style scoped>
.statistics-panel {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
}

.statistics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.statistics-header h3 {
  color: var(--terminal-yellow);
  margin: 0;
  font-size: 1.1em;
}
</style> 
