<template>
  <div class="heatmap-container">
    <div class="heatmap-grid">
      <!-- Quarter headers -->
      <div class="quarter-headers">
        <div v-for="quarter in quarters" :key="quarter" class="quarter-header">
          {{ quarter }}
        </div>
      </div>

      <!-- Year rows -->
      <div v-for="year in years" :key="year" class="year-row">
        <!-- Year label -->
        <div class="year-label">{{ year }}</div>
        
        <!-- Quarter cells -->
        <div class="quarters-container">
          <div v-for="(quarter, index) in quarters" :key="quarter" class="quarter-cell-wrapper">
            <div 
              class="quarter-cell"
              :class="getReturnClass(getQuarterReturn(year, index + 1))"
            >
              <span v-if="getQuarterReturn(year, index + 1) !== null" class="cell-text">
                {{ formatNumber(getQuarterReturn(year, index + 1)) }}%
              </span>
              <div class="tooltip">
                {{ formatQuarterTooltip(year, quarter, getQuarterReturn(year, index + 1)) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Average row -->
      <div class="year-row summary-row average-row">
        <div class="year-label">Average</div>
        <div class="quarters-container">
          <div v-for="(avg, index) in summary?.averages" :key="'avg-'+index" class="quarter-cell-wrapper">
            <div 
              class="quarter-cell"
              :class="getReturnClass(avg)"
            >
              <span v-if="avg !== null" class="cell-text">{{ formatNumber(avg) }}%</span>
              <div class="tooltip">
                Average for {{ quarters[index] }}: {{ formatNumber(avg) }}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Median row -->
      <div class="year-row summary-row">
        <div class="year-label">Median</div>
        <div class="quarters-container">
          <div v-for="(median, index) in summary?.medians" :key="'med-'+index" class="quarter-cell-wrapper">
            <div 
              class="quarter-cell"
              :class="getReturnClass(median)"
            >
              <span v-if="median !== null" class="cell-text">{{ formatNumber(median) }}%</span>
              <div class="tooltip">
                Median for {{ quarters[index] }}: {{ formatNumber(median) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeatmapQuarterly',
  
  props: {
    timeframe: {
      type: String,
      required: true
    },
    quarterlyReturns: {
      type: Array,
      required: true
    },
    summary: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      quarters: ['Q1', 'Q2', 'Q3', 'Q4']
    }
  },

  computed: {
    years() {
      if (!this.quarterlyReturns?.length) return []
      // Extract unique years and sort in descending order
      const years = [...new Set(this.quarterlyReturns.map(r => r.x))]
      return years.sort((a, b) => b - a)
    },

    // Create a map for quick lookup of returns
    returnsMap() {
      if (!this.quarterlyReturns?.length) return new Map()
      const map = new Map()
      this.quarterlyReturns.forEach(r => {
        map.set(`${r.x}-${r.y}`, r.v)
      })
      return map
    }
  },

  methods: {
    getQuarterReturn(year, quarter) {
      const key = `${year}-${quarter}`
      const value = this.returnsMap.get(key)
      return value !== undefined ? value : null
    },

    formatNumber(num) {
      if (num === null || num === undefined) return '0.0'
      return Number(num).toFixed(1)
    },

    getReturnClass(change) {
      if (change === null || change === undefined) return 'neutral'
      if (change <= -15) return 'extreme-negative'
      if (change <= -10) return 'negative'
      if (change <= -5) return 'slight-negative'
      if (change < 0) return 'neutral-negative'
      if (change === 0) return 'neutral'
      if (change <= 5) return 'neutral-positive'
      if (change <= 10) return 'slight-positive'
      if (change <= 15) return 'positive'
      return 'extreme-positive'
    },

    formatQuarterTooltip(year, quarter, value) {
      if (value === null || value === undefined) return 'No data'
      return `${quarter} ${year}: ${this.formatNumber(value)}%`
    }
  }
}
</script>

<style scoped>
.heatmap-container {
  height: auto;
  max-height: calc(100vh - 6rem);
  width: 100%;
  overflow: auto;
  font: 12px 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.heatmap-grid {
  min-width: max-content;
  position: relative;
  width: fit-content;
}

.year-row {
  display: flex;
  align-items: center;
  height: 38px;
  margin-bottom: 2px;
}

.year-label {
  width: 4rem;
  color: #00ff00;
  font-size: 13px;
  text-align: right;
  padding-right: 0.75rem;
  font-weight: bold;
}

.quarters-container {
  display: flex;
  flex-grow: 1;
  height: 38px;
}

.quarter-cell-wrapper {
  width: 86px;
  height: 38px;
  padding: 0 1px;
}

.quarter-cell {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 1px solid currentColor;
  background: currentColor;
  color: currentColor;
  user-select: none;
}

.quarter-headers {
  position: absolute;
  top: -1.25rem;
  left: 4rem;
  display: flex;
  height: 1.25rem;
}

.quarter-header {
  width: 86px;
  font-size: 11px;
  color: #00ff00;
  text-align: center;
  padding: 0 1px;
  user-select: none;
}

.tooltip {
  display: none;
  position: absolute;
  background: #000000;
  border: 1px solid #333;
  padding: 0.625rem 0.875rem;
  z-index: 1000;
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.625rem;
  font-size: 14px;
  pointer-events: none;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.quarter-cell:hover {
  outline: 1px solid #666666;
  z-index: 999;
}

.quarter-cell:hover .tooltip {
  display: block;
}

.cell-text {
  color: #000000;
  font-size: 13px;
  line-height: 38px;
  font-weight: 500;
  z-index: 1;
}

.summary-section {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--terminal-border);
  padding-bottom: 1rem;
}

.summary-section .year-label {
  color: #00ff00;
}

/* Return classes with colors (applied to border and background) */
.extreme-negative { color: #ff3b3b; }
.negative { color: #cc2f2f; }
.slight-negative { color: #992323; }
.neutral-negative { color: #661717; }
.neutral { color: #282828; }
.neutral-positive { color: #004400; }
.slight-positive { color: #006600; }
.positive { color: #009900; }
.extreme-positive { color: #00cc00; }

/* Make summary row cells slightly transparent */
.summary-row .quarter-cell {
  opacity: 0.9;
}

.average-row {
  margin-top: 1rem;
}
</style> 