<template>
  <!-- Main Terminal Container -->
  <div class="terminal">
    <TerminalHeader 
      title="BTC FIRE Calculator"
      @connection-change="handleConnectionChange"
    />

    <!-- Main Grid Layout -->
    <div class="flex-1 grid grid-cols-[48%_52%] grid-rows-[46%_54%] gap-2 overflow-hidden max-md:grid-cols-1 max-md:grid-rows-none max-md:overflow-auto">
      <!-- Input Panel -->
      <div class="flex flex-col items-center justify-center !mt-2 h-full border border-(--terminal-dim) max-md:h-auto max-md:mb-4">
        <div class="grid grid-cols-2 gap-4 max-md:grid-cols-1 max-md:gap-6 max-md:w-full max-md:px-4">
          <!-- Left Column - Basic Settings -->
          <div class="space-y-2 !ml-4">
            <!-- BTC Stack Input -->
            <div class="flex flex-col gap-1.5 !mt-3 max-md:gap-0">
              <label for="btcStack">Bitcoin Stack (BTC)</label>
              <input type="number" v-model="btcStack" step="0.0001" min="0">
            </div>

            <!-- Initial CAGR Input -->
            <div class="flex flex-col gap-1.5 !mt-3 max-md:gap-0">
              <label for="diminishingInitialCagr">Initial CAGR (%)</label>
              <input type="number" v-model="diminishingInitialCagr" step="0.1" min="0" max="100">
            </div>

            <!-- CAGR Decay Rate Input -->
            <div class="flex flex-col gap-1.5 !mt-3 max-md:gap-0">
              <label for="diminishingRate">CAGR Decay Rate (%)</label>
              <input type="number" v-model="diminishingRate" step="0.1" min="0" max="100">
            </div>

            <!-- Starting Year Selection -->
            <div class="flex flex-col gap-1.5 !mt-3 max-md:gap-0">
              <label for="startYear">Starting Year</label>
              <select v-model="startYear">
                <option v-for="year in startYears" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>

          <!-- Right Column - Advanced Settings -->
          <div class="space-y-2 !mr-4 mobile-margin-left">
            <!-- Starting Price Input -->
            <div class="flex flex-col gap-1.5 !mt-3">
              <label for="startPrice">Starting Price ($)</label>
              <input type="number" v-model="startPrice" min="0" step="1000">
            </div>

            <!-- Withdrawal Year Selection -->
            <div class="flex flex-col gap-1.5 !mt-3">
              <label for="withdrawalYear">Withdrawal Starting Year</label>
              <select v-model="withdrawalYear">
                <option v-for="year in withdrawalYears" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>

            <!-- Withdrawal Configuration -->
            <div class="flex flex-col gap-1.5 !mt-3">
              <label for="withdrawalType">Annual Withdrawal</label>
              <div class="flex items-center gap-2">
                <select v-model="withdrawalType" class="flex-1">
                  <option value="percentage">Percent (%)</option>
                  <option value="amount">Amount ($)</option>
                </select>
                <input type="number" v-model="withdrawalAmount" step="1" min="0" :placeholder="withdrawalType === 'percentage' ? 'Percentage' : 'Amount'" class="flex-1">
              </div>
            </div>

            <!-- Tax Rate Input -->
            <div class="flex flex-col gap-1.5 !mt-3">
              <label for="taxPercentage">Tax Rate (%)</label>
              <input type="number" v-model="taxPercentage" step="0.1" min="0" max="100">
            </div>
          </div>
        </div>

        <!-- Calculate Button -->
        <div class="flex items-center justify-center mt-12 mobile-margin-top">
          <button class="calculate-btn" @click="calculateResults">Calculate Projections</button>
        </div>
      </div>

      <!-- Results Panel -->
      <div v-if="showResults" 
           class="flex flex-col col-start-2 !m-[0.5rem_1rem_1rem_0] row-span-2 border border-(--terminal-dim) max-md:col-auto max-md:row-span-1 max-md:m-0 max-md:mb-4">
        <div class="results-table max-md:max-h-[500px]">
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>CAGR</th>
                <th>Price</th>
                <th>Return $</th>
                <th>Withdraw(%)</th>
                <th>Withdraw($)</th>
                <th>Total BTC</th>
                <th>Net Worth</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in results" :key="row.year">
                <td>{{ row.year }}</td>
                <td>{{ formatPercent(row.cagr) }}%</td>
                <td>${{ formatCurrency(row.price) }}</td>
                <td>${{ formatCurrency(row.portfolioReturn) }}</td>
                <td>{{ formatPercent(row.withdrawalPercentage) }}%</td>
                <td>${{ formatCurrency(row.withdrawalAmount) }}</td>
                <td>{{ formatBTC(row.remainingBtc) }}</td>
                <td>${{ formatCurrency(row.totalValue) }}</td>
              </tr>
            </tbody>
          </table>
        </div>        
      </div>

      <!-- Chart Panel -->
      <div v-if="showResults" 
           class="flex flex-col border !mt-2 !mb-4 border-(--terminal-dim) max-md:h-[400px]">
        <div class="h-16 flex justify-center items-center gap-4 max-md:h-auto max-md:mt-4 max-md:flex-wrap">
          <button class="px-4 py-2 text-sm min-w-[120px]" :class="{ active: selectedChart === 'netWorth' }" @click="selectedChart = 'netWorth'">
            Net Worth
          </button>
          <button class="px-4 py-2 text-sm min-w-[120px]" :class="{ active: selectedChart === 'btcPrice' }" @click="selectedChart = 'btcPrice'">
            BTC Price
          </button>
          <button class="px-4 py-2 text-sm min-w-[120px]" :class="{ active: selectedChart === 'stackSize' }" @click="selectedChart = 'stackSize'">
            Stack Size
          </button>
        </div>
        <div class="flex-1 w-full" ref="chartContainer"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted, nextTick, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'

// ==================
// State Management
// ==================

// Input Fields
const btcStack = ref(15)
const diminishingInitialCagr = ref(25)
const diminishingRate = ref(3)
const withdrawalYear = ref(2026)
const withdrawalType = ref('percentage')
const withdrawalAmount = ref(5)
const taxPercentage = ref(15)
const startYear = ref(new Date().getFullYear())
const startPrice = ref(98000)

// UI State
const showResults = ref(false)
const results = ref([])
const chartContainer = ref(null)
const selectedChart = ref('stackSize')

// ==================
// Computed Values
// ==================
const currentYear = new Date().getFullYear()

const withdrawalYears = computed(() => {
  const years = []
  for (let year = currentYear; year <= currentYear + 29; year++) {
    years.push(year)
  }
  return years
})

const startYears = computed(() => {
  const years = []
  for (let year = currentYear; year <= currentYear + 5; year++) {
    years.push(year)
  }
  return years
})

// ==================
// Calculation Logic
// ==================

// Calculate diminishing CAGR based on year
function getDiminishingCagr(year, initialCagr) {
  const yearsSinceStart = year - startYear.value
  return Math.max(initialCagr * Math.pow(1 - diminishingRate.value / 100, yearsSinceStart), 0.05)
}

// Main calculation function
function calculateResults() {
  let currentBtc = btcStack.value
  let assumedPrice = startPrice.value
  const yearLabels = []
  const totalValueData = []
  const resultRows = []

  for (let year = startYear.value; year <= 2053; year++) {
    // Calculate CAGR based on diminishing model
    const currentCagr = getDiminishingCagr(year, diminishingInitialCagr.value / 100)

    // Calculate values
    const portfolioValue = currentBtc * assumedPrice
    const portfolioReturn = portfolioValue * currentCagr
    let withdrawalPercentage = 0
    let actualWithdrawal = 0

    if (year >= withdrawalYear.value) {
      if (withdrawalType.value === 'amount') {
        actualWithdrawal = withdrawalAmount.value
        withdrawalPercentage = (withdrawalAmount.value / portfolioValue * 100)
      } else if (withdrawalType.value === 'percentage') {
        withdrawalPercentage = withdrawalAmount.value
        actualWithdrawal = (portfolioValue * withdrawalAmount.value / 100)
      }
    }

    // Calculate remaining BTC after withdrawal
    if (year >= withdrawalYear.value) {
      currentBtc -= (actualWithdrawal / assumedPrice)
    }

    // Store results
    resultRows.push({
      year,
      cagr: currentCagr * 100,
      price: assumedPrice,
      withdrawalPercentage,
      portfolioReturn,
      withdrawalAmount: actualWithdrawal,
      remainingBtc: currentBtc,
      totalValue: portfolioValue
    })

    // Store chart data
    yearLabels.push(year)
    totalValueData.push(Math.round(portfolioValue))

    // Increase price using current year's CAGR
    assumedPrice *= (1 + currentCagr)
  }

  results.value = resultRows
  showResults.value = true
  
  // Wait for next tick to ensure DOM is updated
  nextTick(() => {
    updateChart()
  })
}

// ==================
// Chart Management
// ==================

// Update chart based on selected view
function updateChart() {
  // Ensure chart container exists
  if (!chartContainer.value) return

  let traces = [];
  let layout = {
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    font: {
      color: '#33ff33',
      family: 'monospace'
    },
    xaxis: {
      gridcolor: '#1a1a1a',
      zerolinecolor: '#1a1a1a'
    },
    margin: {
      l: 60,
      r: 60,
      t: 10,
      b: 60
    },
    showlegend: false,
    autosize: true
  }

  if (selectedChart.value === 'stackSize') {
    // Line trace for BTC stack
    traces.push({
      x: results.value.map(r => r.year),
      y: results.value.map(r => r.remainingBtc),
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Stack Size',
      line: {
        color: '#33ff33',
        width: 2
      },
      marker: {
        color: '#33ff33',
        size: 6
      }
    });

    // Bar trace for withdrawals
    traces.push({
      x: results.value.map(r => r.year),
      y: results.value.map(r => r.withdrawalAmount),
      type: 'bar',
      name: 'Withdrawals',
      yaxis: 'y2',
      marker: {
        color: 'rgba(51, 255, 51, 0.2)'
      }
    });

    // Add second y-axis for withdrawals
    layout.yaxis = {
      title: 'BTC',
      gridcolor: '#1a1a1a',
      zerolinecolor: '#1a1a1a',
      tickformat: '.2f',
      titlefont: { color: '#33ff33' },
      tickfont: { color: '#33ff33' }
    };
    layout.yaxis2 = {
      title: 'Withdrawal Amount ($)',
      overlaying: 'y',
      side: 'right',
      gridcolor: '#1a1a1a',
      zerolinecolor: '#1a1a1a',
      tickformat: '$.2s',
      titlefont: { color: 'rgba(51, 255, 51, 0.6)' },
      tickfont: { color: 'rgba(51, 255, 51, 0.6)' }
    };
    layout.showlegend = true;
    layout.legend = {
      x: 0.5,
      y: 1.1,
      xanchor: 'center',
      orientation: 'h',
      font: { color: '#33ff33' }
    };
  } else {
    // Original single trace for other views
    const chartData = selectedChart.value === 'btcPrice' 
      ? results.value.map(r => r.price)
      : results.value.map(r => r.totalValue);

    traces.push({
      x: results.value.map(r => r.year),
      y: chartData,
      type: 'scatter',
      mode: 'lines+markers',
      name: selectedChart.value,
      line: {
        color: '#33ff33',
        width: 2
      },
      marker: {
        color: '#33ff33',
        size: 6
      }
    });

    layout.yaxis = {
      gridcolor: '#1a1a1a',
      zerolinecolor: '#1a1a1a',
      tickformat: '$.2s'
    };
  }

  const config = {
    displayModeBar: false,
    responsive: true
  }

  // Only create new plot if container exists
  if (chartContainer.value) {
    Plotly.newPlot(chartContainer.value, traces, layout, config)
  }
}

// ==================
// Formatting Helpers
// ==================
function formatCurrency(value) {
  return Math.round(value).toLocaleString()
}

function formatPercent(value) {
  return Number(value).toFixed(1)
}

function formatBTC(value) {
  return value.toFixed(4)
}

// ==================
// Lifecycle Hooks
// ==================
onMounted(() => {
  calculateResults()
})

onUnmounted(() => {
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})

// ==================
// Watchers
// ==================
watch(selectedChart, () => {
  updateChart()
})

// ==================
// Methods
// ==================
function handleConnectionChange(connected) {
  // Update UI or state based on connection status
  console.log('Connection status:', connected ? 'Connected' : 'Disconnected')
}
</script>

<style scoped>
.terminal {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.terminal-header {
  flex: none; /* or flex-shrink: 0 */
  height: 60px;
}

label {
  font-size: 0.9rem;
  color: var(--terminal-dim);
  font-weight: 500;
}

input, select {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  transition: all 0.2s ease;
}

input:hover, select:hover {
  border-color: var(--terminal-dim);
}

input:focus, select:focus {
  outline: none;
  border-color: var(--terminal-text);
  box-shadow: 0 0 0 1px var(--terminal-text);
  background: rgba(51, 255, 51, 0.05);
}

/* Results table improvements */
.results-table {
  width: 100%;
  font-size: 0.85rem;
  overflow-y: auto;
  max-height: 100%;
}

.results-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.results-table th,
.results-table td {
  padding: 0.30rem .25rem;
  text-align: center;
  border: 1px solid var(--terminal-border);
}

.results-table th {
  background: var(--terminal-bg);
  font-weight: 600;
  color: var(--terminal-dim);
  font-size: 0.85rem;
  letter-spacing: 0.5px;
}

.results-table tr:hover {
  background: rgba(51, 255, 51, 0.05);
}

button {
  background: none;
  border: 1px solid var(--terminal-border);
  color: var(--terminal-dim);
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.calculate-btn {
  width: 100%;
  margin-top: 2rem;
  font-size: 0.85rem;
}

button:hover {
  border-color: var(--terminal-text);
  color: var(--terminal-text);
  background: rgba(51, 255, 51, 0.05);
}

button:active,
button.active  {
  background: rgba(51, 255, 51, 0.1);
  border-color: var(--terminal-text);
  color: var(--terminal-text);
}

@media (max-width: 768px) {
  .mobile-margin-left {
    margin-left: 1rem !important;
  }
  .mobile-margin-top {
    margin-top: 0rem !important;
  }
  .calculate-btn {
    width: 100%;
    margin: 1rem 0;
    font-size: 0.85rem;
  }
  label {
    font-size: 0.8rem;
    color: var(--terminal-dim);
    font-weight: 500;
  }

  input, select {
    background: var(--terminal-bg);
    border: 1px solid var(--terminal-border);
    color: var(--terminal-text);
    padding: 0.25rem;
    border-radius: 4px;
    width: 100%;
    font-size: 0.8rem;
    transition: all 0.2s ease;
  }
}
</style> 