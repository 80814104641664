<template>
  <!-- 
  TODO - consider making this vertical to be more mobile friendly. 
  - Years would be at the top for columns 
  - Weeks would be the rows in ascending order
  - Median and average would go to the far left or right.  
  -->
  <div class="heatmap-container">
    <div class="heatmap-grid">
      <!-- Week numbers header -->
      <div class="week-headers">
        <div v-for="week in 53" :key="week" class="week-header">
          W{{ week }}
        </div>
      </div>

      <!-- Year rows -->
      <div v-for="year in years" :key="year" class="year-row">
        <!-- Year label -->
        <div class="year-label">{{ year }}</div>
        
        <!-- Week cells -->
        <div class="weeks-container">
          <div v-for="week in 53" :key="week" class="week-cell-wrapper">
            <div 
              class="week-cell"
              :class="getReturnClass(getWeekReturn(year, week))"
            >
              <span v-if="getWeekReturn(year, week) !== null" class="cell-text">{{ formatNumber(getWeekReturn(year, week)) }}%</span>
              <div class="tooltip">
                {{ formatWeekTooltip(year, week) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Average row -->
      <div class="year-row summary-row average-row">
        <div class="year-label">Average</div>
        <div class="weeks-container">
          <div v-for="(avg, index) in summary?.averages" :key="'avg-'+index" class="week-cell-wrapper">
            <div 
              class="week-cell"
              :class="getReturnClass(avg)"
            >
              <span v-if="avg !== null" class="cell-text">{{ formatNumber(avg) }}%</span>
              <div class="tooltip">
                Average for Week {{ index + 1 }}: {{ formatNumber(avg) }}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Median row -->
      <div class="year-row summary-row">
        <div class="year-label">Median</div>
        <div class="weeks-container">
          <div v-for="(median, index) in summary?.medians" :key="'med-'+index" class="week-cell-wrapper">
            <div 
              class="week-cell"
              :class="getReturnClass(median)"
            >
              <span v-if="median !== null" class="cell-text">{{ formatNumber(median) }}%</span>
              <div class="tooltip">
                Median for Week {{ index + 1 }}: {{ formatNumber(median) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HeatmapWeekly',
  
  props: {
    timeframe: {
      type: String,
      required: true
    },
    weeklyReturns: {
      type: Array,
      required: true
    },
    summary: {
      type: Object,
      required: true
    }
  },

  computed: {
    years() {
      return [...new Set(this.weeklyReturns.map(r => r.x))].sort((a, b) => b - a)
    },

    // Create a map for quick lookup of returns
    returnsMap() {
      const map = new Map()
      this.weeklyReturns.forEach(r => {
        map.set(`${r.x}-${r.y}`, r.v)
      })
      return map
    }
  },

  methods: {
    getWeekReturn(year, week) {
      return this.returnsMap.get(`${year}-${week}`)
    },

    formatNumber(num) {
      return num ? Number(num).toFixed(1) : '0.0'
    },

    getReturnClass(change) {
      if (!change) return 'neutral'
      if (change <= -7) return 'extreme-negative'
      if (change <= -5) return 'negative'
      if (change <= -1) return 'slight-negative'
      if (change < 0) return 'neutral-negative'
      if (change === 0) return 'neutral'
      if (change <= 1) return 'neutral-positive'
      if (change <= 3) return 'slight-positive'
      if (change <= 7) return 'positive'
      return 'extreme-positive'
    },

    getReturnSymbol() {
      return '█'
    },

    formatWeekTooltip(year, week) {
      const value = this.getWeekReturn(year, week)
      if (value === null || value === undefined) return 'No data'
      
      const startDate = moment().year(year).week(week).startOf('week')
      const endDate = moment().year(year).week(week).endOf('week')
      
      return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}: ${this.formatNumber(value)}%`
    }
  }
}
</script>

<style scoped>
.heatmap-container {
  height: auto;
  max-height: calc(100vh - 6rem);
  width: 100%;
  overflow: auto;
  font: 12px 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  padding: 1rem;
  padding-top: 2rem;
}

.heatmap-grid {
  min-width: max-content;
  position: relative;
}

.year-row {
  display: flex;
  align-items: center;
  height: 38px;
  margin-bottom: 2px;
}

.year-label {
  width: 4rem;
  color: #00ff00;
  font-size: 13px;
  text-align: right;
  padding-right: 0.75rem;
  font-weight: bold;
}

.weeks-container {
  display: flex;
  flex-grow: 1;
  height: 38px;
}

.week-cell-wrapper {
  width: 43px;
  height: 38px;
  padding: 0 1px;
}

.week-cell {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 1px solid currentColor;
  background: currentColor;
  color: currentColor;
  user-select: none;
}

.week-headers {
  position: absolute;
  top: -1.25rem;
  left: 4rem;
  display: flex;
  height: 1.25rem;
}

.week-header {
  width: 43px;
  font-size: 11px;
  color: #00ff00;
  text-align: center;
  padding: 0 1px;
  user-select: none;
}

.tooltip {
  display: none;
  position: absolute;
  background: #000000;
  border: 1px solid #333;
  padding: 0.625rem 0.875rem;
  z-index: 1000;
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.625rem;
  font-size: 14px;
  pointer-events: none;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.week-cell:hover {
  outline: 1px solid #666666;
  z-index: 999;
}

.week-cell:hover .tooltip {
  display: block;
}

.cell-text {
  font-size: 11px;
  font-weight: normal;
  line-height: 38px;
  display: block;
  color: #000000;
  z-index: 1;
}

/* Return classes with colors (applied to border and background) */
.extreme-negative { color: #ff3b3b; }
.negative { color: #cc2f2f; }
.slight-negative { color: #992323; }
.neutral-negative { color: #661717; }
.neutral { color: #282828; }
.neutral-positive { color: #004400; }
.slight-positive { color: #006600; }
.positive { color: #009900; }
.extreme-positive { color: #00cc00; }

/* Make summary row cells slightly transparent */
.summary-row .week-cell {
  opacity: 0.9;
}

.average-row {
  margin-top: 0.5rem;
}
</style> 