<template>
  <div class="heatmap-container">
    <div class="heatmap-grid-container">
      <!-- Asset columns -->
      <div v-for="asset in assetNames" :key="asset" class="heatmap-grid">
        <!-- Asset name header -->
        <div class="asset-header">{{ getAssetDisplayName(asset) }}</div>
        
        <!-- Year rows -->
        <div v-for="year in years" :key="year" class="year-row">
          <!-- Year label (only show in first column) -->
          <div v-if="asset === assetNames[0]" class="year-label">{{ year }}</div>
          <div v-else class="year-label-spacer"></div>
          
          <!-- Year cell -->
          <div class="year-cell-wrapper">
            <div 
              class="year-cell"
              :class="getReturnClass(getYearReturn(year, asset))"
            >
              <span v-if="getYearReturn(year, asset) !== null" class="cell-text">
                {{ formatNumber(getYearReturn(year, asset)) }}%
              </span>
              <div class="tooltip">
                {{ formatYearTooltip(year, getYearReturn(year, asset), getAssetDisplayName(asset)) }}
              </div>
            </div>
          </div>
        </div>

        <!-- Average row -->
        <div class="year-row summary-row average-row">
          <div v-if="asset === assetNames[0]" class="year-label">Average</div>
          <div v-else class="year-label-spacer"></div>
          <div class="year-cell-wrapper">
            <div 
              class="year-cell"
              :class="getReturnClass(getSummaryForAsset(asset, 'average'))"
            >
              <span v-if="getSummaryForAsset(asset, 'average') !== null" class="cell-text">
                {{ formatNumber(getSummaryForAsset(asset, 'average')) }}%
              </span>
              <div class="tooltip">
                {{ getAssetDisplayName(asset) }} Average Return: {{ formatNumber(getSummaryForAsset(asset, 'average')) }}%
              </div>
            </div>
          </div>
        </div>

        <!-- Median row -->
        <div class="year-row summary-row">
          <div v-if="asset === assetNames[0]" class="year-label">Median</div>
          <div v-else class="year-label-spacer"></div>
          <div class="year-cell-wrapper">
            <div 
              class="year-cell"
              :class="getReturnClass(getSummaryForAsset(asset, 'median'))"
            >
              <span v-if="getSummaryForAsset(asset, 'median') !== null" class="cell-text">
                {{ formatNumber(getSummaryForAsset(asset, 'median')) }}%
              </span>
              <div class="tooltip">
                {{ getAssetDisplayName(asset) }} Median Return: {{ formatNumber(getSummaryForAsset(asset, 'median')) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeatmapYearly',
  
  props: {
    timeframe: {
      type: String,
      required: true
    },
    yearlyReturns: {
      type: [Array, Object],
      required: true
    },
    summary: {
      type: [Object, Array],
      required: true
    },
    symbols: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    /**
     * Computes the list of years to display in the heatmap.
     * Handles three possible data structures:
     * 1. Array of returns (single asset, direct array)
     * 2. Object with returns property (single asset, nested)
     * 3. Object of assets (multi-asset mode)
     * @returns {Array} Sorted array of years in descending order
     */
    years() {
      console.log('yearlyReturns received:', this.yearlyReturns)
      if (Array.isArray(this.yearlyReturns)) {
        // Case 1: Direct array of returns [{x: year, v: value}, ...]
        console.log('Processing as array')
        if (!this.yearlyReturns?.length) return []
        const years = [...new Set(this.yearlyReturns.map(r => r.x))]
        const sorted = years.sort((a, b) => b - a)
        console.log('Computed years:', sorted)
        return sorted
      } else if (this.yearlyReturns.returns) {
        // Case 2: Object with returns property {returns: [{x: year, v: value}, ...]}
        console.log('Processing with returns property')
        return [...new Set(this.yearlyReturns.returns.map(r => r.x))].sort((a, b) => b - a)
      } else {
        // Case 3: Multi-asset object {symbol: {returns: [...]}, ...}
        console.log('Processing as multi-asset')
        const allYears = new Set()
        Object.values(this.yearlyReturns).forEach(assetData => {
          const returns = assetData.returns || assetData
          returns.forEach(r => allYears.add(r.x))
        })
        return [...allYears].sort((a, b) => b - a)
      }
    },

    /**
     * Creates a map of year -> return value for each asset.
     * Uses 'single' as the key for single asset view, and symbol as key for multi-asset view.
     * This mapping is crucial for data lookup in the template.
     * @returns {Object} Map of assets to their year->value maps
     */
    returnsMap() {
      console.log('Building returnsMap from:', this.yearlyReturns)
      if (Array.isArray(this.yearlyReturns)) {
        // Case 1: Direct array - store under 'single' key
        if (!this.yearlyReturns?.length) return { single: new Map() }
        const map = new Map()
        this.yearlyReturns.forEach(r => {
          map.set(r.x, r.v)
        })
        console.log('Created single map:', Object.fromEntries(map))
        return { single: map }
      } else if (this.yearlyReturns.returns) {
        // Case 2: Nested returns - store under 'single' key
        const map = new Map()
        this.yearlyReturns.returns.forEach(r => {
          map.set(r.x, r.v)
        })
        return { single: map }
      } else {
        // Case 3: Multi-asset - create a map for each asset
        const maps = {}
        Object.entries(this.yearlyReturns).forEach(([symbol, data]) => {
          const map = new Map()
          const returns = data.returns || data
          returns.forEach(r => {
            map.set(r.x, r.v)
          })
          maps[symbol] = map
        })
        return maps
      }
    },

    /**
     * Determines which asset names to display in the heatmap.
     * For single asset view, returns ['single'] to match the returnsMap key.
     * For multi-asset view, returns the array of symbol names.
     * @returns {Array} Array of asset identifiers
     */
    assetNames() {
      console.log('Computing assetNames, symbols:', this.symbols)
      // For single asset view, always use 'single' as the key
      // This matches the key used in returnsMap
      if (!this.symbols.length || Array.isArray(this.yearlyReturns)) {
        return ['single']
      }
      return this.symbols
    }
  },

  methods: {
    /**
     * Retrieves the return value for a specific year and asset.
     * Maps between display names and internal keys ('single' vs actual symbol).
     * @param {number} year - The year to look up
     * @param {string} asset - The asset identifier (either 'single' or symbol)
     * @returns {number|null} The return value or null if not found
     */
    getYearReturn(year, asset) {
      console.log('Getting return for year:', year, 'asset:', asset)
      // Map the asset name to the correct key in returnsMap
      const mapKey = (!this.symbols.length || Array.isArray(this.yearlyReturns)) ? 'single' : asset
      const map = this.returnsMap[mapKey]
      console.log('Found map:', map ? Object.fromEntries(map) : null)
      if (!map) return null
      const value = map.get(year)
      console.log('Return value:', value)
      return value !== undefined ? value : null
    },

    /**
     * Gets the summary statistics (average/median) for an asset.
     * Handles both single and multi-asset data structures.
     * @param {string} asset - The asset identifier
     * @param {string} type - Either 'average' or 'median'
     * @returns {number|null} The summary value or null if not found
     */
    getSummaryForAsset(asset, type) {
      console.log('Getting summary for asset:', asset, 'type:', type, 'summary:', this.summary)
      if (!this.summary) return null
      
      if (Array.isArray(this.summary.averages)) {
        // Single asset case with direct arrays
        const value = type === 'average' ? this.summary.averages[0] : this.summary.medians[0]
        console.log('Single asset summary value:', value)
        return value
      }
      
      // Map the asset name to the correct key in summary object
      const summaryKey = (!this.symbols.length || Array.isArray(this.yearlyReturns)) ? 'single' : asset
      const assetSummary = this.summary[summaryKey]
      console.log('Asset summary:', assetSummary)
      if (!assetSummary) return null
      
      const value = type === 'average' ? 
        assetSummary.averages?.[0] : 
        assetSummary.medians?.[0]
      console.log('Multi-asset summary value:', value)
      return value
    },

    formatNumber(num) {
      if (num === null || num === undefined) return '0.0'
      return Number(num).toFixed(1)
    },

    getReturnClass(change) {
      if (change === null || change === undefined) return 'neutral'
      if (change <= -15) return 'extreme-negative'
      if (change <= -10) return 'negative'
      if (change <= -5) return 'slight-negative'
      if (change < 0) return 'neutral-negative'
      if (change === 0) return 'neutral'
      if (change <= 5) return 'neutral-positive'
      if (change <= 10) return 'slight-positive'
      if (change <= 15) return 'positive'
      return 'extreme-positive'
    },

    formatYearTooltip(year, value, asset) {
      if (value === null || value === undefined) return 'No data'
      return `${asset} ${year}: ${this.formatNumber(value)}%`
    },

    /**
     * Maps between internal asset keys and display names.
     * Handles the special 'single' key for single asset view.
     * @param {string} symbol - The asset identifier ('single' or symbol)
     * @returns {string} The display name for the asset
     */
    getAssetDisplayName(symbol) {
      // For single asset view, use the actual symbol from props
      if (symbol === 'single') {
        return this.symbols.length ? this.getSymbolDisplayName(this.symbols[0]) : 'Bitcoin'
      }
      return this.getSymbolDisplayName(symbol)
    },

    /**
     * Maps symbol codes to human-readable names.
     * @param {string} symbol - The asset symbol (e.g., 'BTC-USD')
     * @returns {string} The display name (e.g., 'Bitcoin')
     */
    getSymbolDisplayName(symbol) {
      const nameMap = {
        'BTC-USD': 'Bitcoin',
        'SPY': 'S&P 500',
        'QQQ': 'NASDAQ 100'
      }
      return nameMap[symbol] || symbol
    }
  }
}
</script>

<style scoped>
.heatmap-container {
  height: auto;
  max-height: calc(100vh - 6rem);
  width: 100%;
  overflow: auto;
  font: 12px 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.heatmap-grid-container {
  display: flex;
  gap: 2rem;
  min-width: max-content;
}

.heatmap-grid {
  position: relative;
  width: fit-content;
}

.asset-header {
  color: #00ff00;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  padding-left: 4rem;
}

.year-row {
  display: flex;
  align-items: center;
  height: 38px;
  margin-bottom: 2px;
}

.year-label, .year-label-spacer {
  width: 4rem;
  color: #00ff00;
  font-size: 13px;
  text-align: right;
  padding-right: 0.75rem;
  font-weight: bold;
}

.year-label-spacer {
  opacity: 0;
}

.year-cell-wrapper {
  width: 200px;
  height: 38px;
  padding: 0 1px;
}

.year-cell {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 1px solid currentColor;
  background: currentColor;
  color: currentColor;
  user-select: none;
}

.tooltip {
  display: none;
  position: absolute;
  background: #000000;
  border: 1px solid #333;
  padding: 0.625rem 0.875rem;
  z-index: 1000;
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.625rem;
  font-size: 14px;
  pointer-events: none;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.year-cell:hover {
  outline: 1px solid #666666;
  z-index: 999;
}

.year-cell:hover .tooltip {
  display: block;
}

.cell-text {
  color: #000000;
  font-size: 13px;
  line-height: 38px;
  font-weight: 500;
  z-index: 1;
}

/* Return classes with colors (applied to border and background) */
.extreme-negative { color: #ff3b3b; }
.negative { color: #cc2f2f; }
.slight-negative { color: #992323; }
.neutral-negative { color: #661717; }
.neutral { color: #282828; }
.neutral-positive { color: #004400; }
.slight-positive { color: #006600; }
.positive { color: #009900; }
.extreme-positive { color: #00cc00; }

/* Make summary row cells slightly transparent */
.summary-row .year-cell {
  opacity: 0.9;
}

.average-row {
  margin-top: 1rem;
}
</style> 