<!-- Chart View -->
<template>
  <div>
    <!-- Chart Controls -->
    <div class="chart-controls">
      <!-- Contract Info -->
      <div class="contract-info">
        <div class="info-item">
          <span class="label">Symbol:</span>
          <span class="value">{{ symbol }}</span>
        </div>
        <div class="info-item">
          <span class="label">Strike:</span>
          <span class="value">{{ formatPrice(strike) }}</span>
        </div>
        <div class="info-item">
          <span class="label">Expiry:</span>
          <span class="value">{{ formatDate(expiry) }}</span>
        </div>
        <div class="info-item">
          <span class="label">Type:</span>
          <span class="value">{{ optionType === 'C' ? 'Call' : 'Put' }}</span>
        </div>
      </div>

      <div class="control-group">
        <div class="control-section">
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.option">
            Option $
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.stock">
            Stock $
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.iv">
            IV
          </label>
        </div>
        <div class="control-section greeks">
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.delta">
            Delta
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.gamma">
            Gamma
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.theta">
            Theta
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.vega">
            Vega
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.rho">
            Rho
          </label>
        </div>
        <div class="control-section fundamentals">
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.marketCap">
            M Cap
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.sharesOutstanding">
            Total Shares
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.priceToBook">
            Price/Book
          </label>
          <label class="control-label">
            <input type="checkbox" v-model="visibleSeries.bookValue">
            Book Value
          </label>
        </div>
      </div>
    </div>

    <!-- Chart Container -->
    <div ref="chartContainer" class="price-chart"></div>
  </div>
</template>

<script>
import { onMounted, watch, nextTick, ref } from 'vue'
import Plotly from 'plotly.js-dist-min'

export default {
  name: 'OptionsHistoryChart',
  
  props: {
    data: {
      type: Array,
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    strike: {
      type: Number,
      required: true
    },
    optionType: {
      type: String,
      required: true
    },
    expiry: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const chartContainer = ref(null)
    const visibleSeries = ref({
      option: true,
      stock: true,
      iv: false,
      delta: false,
      gamma: false,
      theta: false,
      vega: false,
      rho: false,
      marketCap: false,
      sharesOutstanding: false,
      priceToBook: false,
      bookValue: false
    })

    // Format date in a readable way
    const formatDate = (dateStr) => {
      const date = new Date(dateStr + 'T00:00:00Z')
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC'
      }).format(date)
    }

    // Format price with appropriate decimals
    const formatPrice = (price) => {
      if (price === null || price === undefined) return 'N/A'
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(price)
    }

    // Create price chart
    const createChart = () => {
      if (!props.data || !chartContainer.value) {
        console.log('Missing required data:', {
          hasContainer: !!chartContainer.value,
          hasData: !!props.data
        })
        return
      }

      // Clear existing chart if any
      Plotly.purge(chartContainer.value)

      const dates = props.data.map(d => d.date)
      const traces = []
      const visibleTraces = []
      
      // Track which series are visible
      if (visibleSeries.value.option) visibleTraces.push('option')
      if (visibleSeries.value.stock) visibleTraces.push('stock')
      if (visibleSeries.value.iv) visibleTraces.push('iv')
      if (visibleSeries.value.delta) visibleTraces.push('delta')
      if (visibleSeries.value.gamma) visibleTraces.push('gamma')
      if (visibleSeries.value.theta) visibleTraces.push('theta')
      if (visibleSeries.value.vega) visibleTraces.push('vega')
      if (visibleSeries.value.rho) visibleTraces.push('rho')
      if (visibleSeries.value.marketCap) visibleTraces.push('marketCap')
      if (visibleSeries.value.sharesOutstanding) visibleTraces.push('sharesOutstanding')
      if (visibleSeries.value.priceToBook) visibleTraces.push('priceToBook')
      if (visibleSeries.value.bookValue) visibleTraces.push('bookValue')

      // Option price trace
      if (visibleSeries.value.option) {
        traces.push({
          name: 'Option Price',
          x: dates,
          y: props.data.map(d => d.close),
          type: 'scatter',
          line: { color: '#00ff00', width: 2 },
          marker: { color: '#00ff00', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: 'y'
        })
      }

      // Stock price trace
      if (visibleSeries.value.stock) {
        traces.push({
          name: 'Stock Price',
          x: dates,
          y: props.data.map(d => d.eod_stock),
          type: 'scatter',
          line: { color: '#0088ff', width: 2 },
          marker: { color: '#0088ff', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          // Use y or y2 based on whether it's first or second visible trace
          yaxis: visibleTraces.indexOf('stock') === 0 ? 'y' : 'y2'
        })
      }

      // IV trace
      if (visibleSeries.value.iv) {
        traces.push({
          name: 'IV %',
          x: dates,
          y: props.data.map(d => (d.metrics?.impliedVolatility || 0) * 100),
          type: 'scatter',
          line: { color: '#ff00ff', width: 2 },
          marker: { color: '#ff00ff', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          // Use y or y2 based on whether it's first or second visible trace
          yaxis: visibleTraces.indexOf('iv') === 0 ? 'y' : 'y2'
        })
      }

      // Greeks traces
      if (visibleSeries.value.delta) {
        traces.push({
          name: 'Delta',
          x: dates,
          y: props.data.map(d => d.greeks?.delta || 0),
          type: 'scatter',
          line: { color: '#ff8c00', width: 2 },
          marker: { color: '#ff8c00', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('delta') === 0 ? 'y' : 'y2'
        })
      }

      if (visibleSeries.value.gamma) {
        traces.push({
          name: 'Gamma',
          x: dates,
          y: props.data.map(d => d.greeks?.gamma || 0),
          type: 'scatter',
          line: { color: '#ff1493', width: 2 },
          marker: { color: '#ff1493', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('gamma') === 0 ? 'y' : 'y2'
        })
      }

      if (visibleSeries.value.theta) {
        traces.push({
          name: 'Theta',
          x: dates,
          y: props.data.map(d => d.greeks?.theta || 0),
          type: 'scatter',
          line: { color: '#9370db', width: 2 },
          marker: { color: '#9370db', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('theta') === 0 ? 'y' : 'y2'
        })
      }

      if (visibleSeries.value.vega) {
        traces.push({
          name: 'Vega',
          x: dates,
          y: props.data.map(d => d.greeks?.vega || 0),
          type: 'scatter',
          line: { color: '#20b2aa', width: 2 },
          marker: { color: '#20b2aa', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('vega') === 0 ? 'y' : 'y2'
        })
      }

      if (visibleSeries.value.rho) {
        traces.push({
          name: 'Rho',
          x: dates,
          y: props.data.map(d => d.greeks?.rho || 0),
          type: 'scatter',
          line: { color: '#daa520', width: 2 },
          marker: { color: '#daa520', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('rho') === 0 ? 'y' : 'y2'
        })
      }

      // Market Cap trace
      if (visibleSeries.value.marketCap) {
        traces.push({
          name: 'Market Cap',
          x: dates,
          y: props.data.map(d => d.financials?.market_cap ? d.financials.market_cap / 1000000 : null),
          type: 'scatter',
          line: { color: '#4B0082', width: 2 },
          marker: { color: '#4B0082', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('marketCap') === 0 ? 'y' : 'y2',
          hovertemplate: '%{y:,.1f}M<extra>Market Cap</extra>'
        })
      }

      // Shares Outstanding trace
      if (visibleSeries.value.sharesOutstanding) {
        traces.push({
          name: 'Shares Outstanding',
          x: dates,
          y: props.data.map(d => d.financials?.shares_outstanding ? d.financials?.shares_outstanding / 1000000 : null),
          type: 'scatter',
          line: { color: '#008B8B', width: 2 },
          marker: { color: '#008B8B', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('sharesOutstanding') === 0 ? 'y' : 'y2',
          hovertemplate: '%{y:,.2f}M<extra>Shares Outstanding</extra>'
        })
      }

      // Price to Book trace
      if (visibleSeries.value.priceToBook) {
        traces.push({
          name: 'Price/Book',
          x: dates,
          y: props.data.map(d => d.financials?.priceToBook || null),
          type: 'scatter',
          line: { color: '#BA55D3', width: 2 },
          marker: { color: '#BA55D3', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('priceToBook') === 0 ? 'y' : 'y2',
          hovertemplate: '%{y:.2f}x<extra>Price/Book</extra>'
        })
      }

      // Book Value trace
      if (visibleSeries.value.bookValue) {
        traces.push({
          name: 'Book Value',
          x: dates,
          y: props.data.map(d => d.financials?.bookValue || null),
          type: 'scatter',
          line: { color: '#98FB98', width: 2 },
          marker: { color: '#98FB98', size: 6 },
          hoverlabel: {
            font: { family: 'JetBrains Mono, monospace', size: 12 }
          },
          yaxis: visibleTraces.indexOf('bookValue') === 0 ? 'y' : 'y2',
          hovertemplate: '$%{y:.2f}<extra>Book Value</extra>'
        })
      }

      // Base layout
      const layout = {
        title: {
          text: `${props.symbol} $${props.strike} ${props.optionType === 'C' ? 'Call' : 'Put'} - ${formatDate(props.expiry)}`,
          font: {
            family: 'JetBrains Mono, monospace',
            color: '#00ff00',
            size: 16
          }
        },
        paper_bgcolor: '#000000',
        plot_bgcolor: '#000000',
        font: {
          family: 'JetBrains Mono, monospace',
          color: '#00ff00',
          size: 12
        },
        showlegend: true,
        legend: {
          x: 0,
          y: 1,
          bgcolor: '#000000',
          bordercolor: '#1a1a1a',
          font: {
            family: 'JetBrains Mono, monospace',
            size: 12
          }
        },
        xaxis: {
          gridcolor: '#1a1a1a',
          zerolinecolor: '#1a1a1a',
          tickfont: {
            family: 'JetBrains Mono, monospace',
            color: '#00ff00',
            size: 12
          },
          tickformat: '%b %d<br>%Y',
          domain: [0, 1]
        }
      }

      // Dynamically configure y-axes based on visible traces
      if (visibleTraces.length > 0) {
        const firstTrace = visibleTraces[0]
        layout.yaxis = {
          title: {
            text: firstTrace === 'option' ? 'Option Price ($)' : 
                  firstTrace === 'stock' ? 'Stock Price ($)' : 
                  firstTrace === 'iv' ? 'Implied Volatility (%)' :
                  firstTrace === 'delta' ? 'Delta' :
                  firstTrace === 'gamma' ? 'Gamma' :
                  firstTrace === 'theta' ? 'Theta' :
                  firstTrace === 'vega' ? 'Vega' :
                  firstTrace === 'rho' ? 'Rho' :
                  firstTrace === 'marketCap' ? 'Market Cap (M)' :
                  firstTrace === 'sharesOutstanding' ? 'Shares Outstanding (M)' :
                  firstTrace === 'priceToBook' ? 'Price/Book (x)' :
                  firstTrace === 'bookValue' ? 'Book Value ($)' :
                  '',
            font: {
              family: 'JetBrains Mono, monospace',
              color: firstTrace === 'option' ? '#00ff00' : 
                     firstTrace === 'stock' ? '#0088ff' : 
                     firstTrace === 'iv' ? '#ff00ff' :
                     firstTrace === 'delta' ? '#ff8c00' :
                     firstTrace === 'gamma' ? '#ff1493' :
                     firstTrace === 'theta' ? '#9370db' :
                     firstTrace === 'vega' ? '#20b2aa' :
                     firstTrace === 'rho' ? '#daa520' :
                     firstTrace === 'marketCap' ? '#4B0082' :
                     firstTrace === 'sharesOutstanding' ? '#008B8B' :
                     firstTrace === 'priceToBook' ? '#BA55D3' :
                     firstTrace === 'bookValue' ? '#98FB98' :
                     '#00ff00',
              size: 14
            }
          },
          gridcolor: '#1a1a1a',
          zerolinecolor: '#1a1a1a',
          tickfont: {
            family: 'JetBrains Mono, monospace',
            color: firstTrace === 'option' ? '#00ff00' : 
                   firstTrace === 'stock' ? '#0088ff' : 
                   firstTrace === 'iv' ? '#ff00ff' :
                   firstTrace === 'delta' ? '#ff8c00' :
                   firstTrace === 'gamma' ? '#ff1493' :
                   firstTrace === 'theta' ? '#9370db' :
                   firstTrace === 'vega' ? '#20b2aa' :
                   firstTrace === 'rho' ? '#daa520' :
                   firstTrace === 'marketCap' ? '#4B0082' :
                   firstTrace === 'sharesOutstanding' ? '#008B8B' :
                   firstTrace === 'priceToBook' ? '#BA55D3' :
                   firstTrace === 'bookValue' ? '#98FB98' :
                   '#00ff00',
            size: 12
          },
          side: 'left',
          tickprefix: firstTrace === 'option' || firstTrace === 'stock' || firstTrace === 'bookValue' ? '$' : '',
          tickformat: firstTrace === 'marketCap' ? ',.1f' : 
                     firstTrace === 'sharesOutstanding' ? ',.2f' : undefined,
          ticksuffix: firstTrace === 'marketCap' || firstTrace === 'sharesOutstanding' ? 'M' : ''
        }

        if (visibleTraces.length > 1) {
          const secondTrace = visibleTraces[1]
          layout.yaxis2 = {
            title: {
              text: secondTrace === 'option' ? 'Option Price ($)' : 
                    secondTrace === 'stock' ? 'Stock Price ($)' : 
                    secondTrace === 'iv' ? 'Implied Volatility (%)' :
                    secondTrace === 'delta' ? 'Delta' :
                    secondTrace === 'gamma' ? 'Gamma' :
                    secondTrace === 'theta' ? 'Theta' :
                    secondTrace === 'vega' ? 'Vega' :
                    secondTrace === 'rho' ? 'Rho' :
                    secondTrace === 'marketCap' ? 'Market Cap (M)' :
                    secondTrace === 'sharesOutstanding' ? 'Shares Outstanding (M)' :
                    secondTrace === 'priceToBook' ? 'Price/Book (x)' :
                    secondTrace === 'bookValue' ? 'Book Value ($)' :
                    '',
              font: {
                family: 'JetBrains Mono, monospace',
                color: secondTrace === 'option' ? '#00ff00' : 
                       secondTrace === 'stock' ? '#0088ff' : 
                       secondTrace === 'iv' ? '#ff00ff' :
                       secondTrace === 'delta' ? '#ff8c00' :
                       secondTrace === 'gamma' ? '#ff1493' :
                       secondTrace === 'theta' ? '#9370db' :
                       secondTrace === 'vega' ? '#20b2aa' :
                       secondTrace === 'rho' ? '#daa520' :
                       secondTrace === 'marketCap' ? '#4B0082' :
                       secondTrace === 'sharesOutstanding' ? '#008B8B' :
                       secondTrace === 'priceToBook' ? '#BA55D3' :
                       secondTrace === 'bookValue' ? '#98FB98' :
                       '#00ff00',
                size: 14
              }
            },
            tickfont: {
              family: 'JetBrains Mono, monospace',
              color: secondTrace === 'option' ? '#00ff00' : 
                     secondTrace === 'stock' ? '#0088ff' : 
                     secondTrace === 'iv' ? '#ff00ff' :
                     secondTrace === 'delta' ? '#ff8c00' :
                     secondTrace === 'gamma' ? '#ff1493' :
                     secondTrace === 'theta' ? '#9370db' :
                     secondTrace === 'vega' ? '#20b2aa' :
                     secondTrace === 'rho' ? '#daa520' :
                     secondTrace === 'marketCap' ? '#4B0082' :
                     secondTrace === 'sharesOutstanding' ? '#008B8B' :
                     secondTrace === 'priceToBook' ? '#BA55D3' :
                     secondTrace === 'bookValue' ? '#98FB98' :
                     '#00ff00',
              size: 12
            },
            overlaying: 'y',
            side: 'right',
            showgrid: false,
            tickprefix: secondTrace === 'option' || secondTrace === 'stock' || secondTrace === 'bookValue' ? '$' : '',
            tickformat: secondTrace === 'marketCap' ? ',.1f' : 
                       secondTrace === 'sharesOutstanding' ? ',.2f' : undefined,
            ticksuffix: secondTrace === 'marketCap' || secondTrace === 'sharesOutstanding' ? 'M' : ''
          }
        }
      }

      layout.margin = {
        l: 60,
        r: 60,
        t: 40,
        b: 40
      }

      layout.hoverlabel = {
        bgcolor: '#1a1a1a',
        bordercolor: '#333333',
        font: {
          family: 'JetBrains Mono, monospace',
          size: 12
        }
      }

      layout.hovermode = 'x unified'

      const config = {
        responsive: true,
        displayModeBar: false
      }

      try {
        Plotly.newPlot(chartContainer.value, traces, layout, config)
      } catch (err) {
        console.error('Error in Plotly.newPlot:', err)
      }
    }

    // Watch for data or visibility changes to update chart
    watch([() => props.data, visibleSeries], () => {
      nextTick(() => {
        createChart()
      })
    }, { deep: true })

    // Initialize chart on mount
    onMounted(() => {
      nextTick(() => {
        createChart()
      })
    })

    return {
      visibleSeries,
      chartContainer,
      formatDate,
      formatPrice
    }
  }
}
</script>

<style scoped>
.price-chart {
  width: 100%;
  min-height: calc(100vh - 22rem);
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  background: var(--terminal-bg-darker);
}

.chart-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: var(--terminal-bg-darker);
  border-radius: 4px;
}

.contract-info {
  display: flex;
  gap: 1.5rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.info-item .label {
  color: var(--terminal-cyan);
  font-size: 0.9em;
}

.info-item .value {
  color: var(--terminal-green);
  font-size: 0.9em;
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: auto;
}

.control-section {
  display: flex;
  gap: 1rem;
}

.control-section.greeks {
  padding-top: 0.25rem;
  border-top: 1px solid var(--terminal-bg);
}

.control-section.fundamentals {
  padding-top: 0.25rem;
  border-top: 1px solid var(--terminal-bg);
}

.control-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--terminal-text);
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.control-label:hover {
  background: var(--terminal-bg);
}

.control-label input[type="checkbox"] {
  margin: 0;
  accent-color: var(--terminal-green);
}
</style> 