import axios from 'axios';

const getBaseURL = () => {
  const apiURL = import.meta.env.VITE_API_URL;
  const fallbackURL = import.meta.env.VITE_FALLBACK_API_URL;
  const baseURL = import.meta.env.VITE_API_BASE_URL;

  // Log environment variables for debugging
  console.log('Environment:', import.meta.env.MODE);
  console.log('API Configuration:', {
    VITE_API_URL: apiURL,
    VITE_API_BASE_URL: baseURL,
    VITE_FALLBACK_API_URL: fallbackURL
  });

  return apiURL || (baseURL ? `${baseURL}/api` : 'https://termfi.onrender.com/api');
};

const requestConfig = {
  baseURL: getBaseURL(),
  timeout: parseInt(import.meta.env.VITE_API_TIMEOUT || '30000'),
  headers: {
    'Content-Type': 'application/json'
  }
};

// Log the final configuration
console.log('Final API Configuration:', requestConfig);

const api = axios.create(requestConfig);

// Add request interceptor for debugging
api.interceptors.request.use(
  config => {
    console.log(`🚀 Making ${config.method?.toUpperCase()} request to: ${config.baseURL}${config.url}`);
    return config;
  },
  error => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  response => {
    console.log(`✅ Successful response from: ${response.config.url}`);
    return response;
  },
  error => {
    if (error.response) {
      console.error('API Error:', {
        url: error.config?.url,
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
    } else if (error.request) {
      console.error('Network Error:', {
        url: error.config?.url,
        method: error.config?.method,
        error: error.message
      });
    } else {
      console.error('Request Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api; 