<template>
  <div class="terminal-header">
    <div class="terminal-title">
      <div class="terminal-logo">{{ title }}</div>
      <router-link to="/" class="nav-link">TermFi Home</router-link>
    </div>
    <div class="terminal-status">
      <slot name="extra"></slot>
      <div :class="['status-indicator', connectionStatus]"></div>
      <span :class="['status-text', connectionStatus]">{{ statusText }}</span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import api from '@/utils/api'

export default {
  name: 'TerminalHeader',

  props: {
    title: {
      type: String,
      required: true
    }
  },

  emits: ['connection-change'],

  setup(props, { emit }) {
    const connectionStatus = ref('connecting')
    const statusText = ref('CONNECTING')
    let pingInterval = null
    const PING_INTERVAL = 10000 // Check every 10 seconds

    const updateConnectionStatus = (connected) => {
      const newStatus = connected ? 'connected' : 'disconnected'
      const newText = connected ? 'LIVE' : 'DISCONNECTED'
      
      // Only update if status actually changed
      if (connectionStatus.value !== newStatus) {
        connectionStatus.value = newStatus
        statusText.value = newText
        emit('connection-change', connected)
      }
    }

    const checkConnection = async () => {
      try {
        const response = await api.get('/ping')
        updateConnectionStatus(response.data.status === 'ok')
      } catch (error) {
        console.error('Connection check failed:', error)
        updateConnectionStatus(false)
      }
    }

    onMounted(() => {
      // Initial connection check
      checkConnection()
      
      // Set up regular ping interval
      pingInterval = setInterval(checkConnection, PING_INTERVAL)
    })

    onUnmounted(() => {
      if (pingInterval) {
        clearInterval(pingInterval)
        pingInterval = null
      }
    })

    return {
      connectionStatus,
      statusText
    }
  }
}
</script>

<style scoped>
.terminal-header {
  background-color: var(--terminal-header);
  padding: 15px 20px;
  border-bottom: 1px solid var(--terminal-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.terminal-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.terminal-logo {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nav-link {
  color: var(--terminal-dim);
  text-decoration: none;
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  margin-top: 4px;
}

.nav-link:hover {
  opacity: 1;
  color: var(--terminal-text);
}

.nav-link::before {
  content: '>';
  font-weight: bold;
  font-size: 10px;
}

.terminal-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--status-error);
}

.status-indicator.connected {
  background-color: var(--status-healthy);
  animation: blink 1s infinite;
}

.status-indicator.connecting {
  background-color: var(--status-warning);
  animation: blink 1s infinite;
}

.status-text.connected {
  color: var(--status-healthy);
}

.status-text.disconnected {
  color: var(--status-error);
}

.status-text.connecting {
  color: var(--status-warning);
}

@keyframes blink {
  50% { opacity: 0.5; }
}
</style> 