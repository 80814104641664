<template>
  <div class="asset-analysis-panel">
    <template v-if="Object.keys(analysisData).length">
      <div v-for="(data, sym) in analysisData" :key="sym" class="asset-analysis">
        
        <!-- Return Distribution -->
        <div class="metric-section">
          <ReturnDistribution 
            :distribution="data.distribution"
            :timeframe="timeframe"
            :asset="sym"
            :showAssetInTitle="true"
          />
          <div class="metrics-grid">
            <div class="metric-group">
              <div class="metric-row">
                <span class="metric-label">ATH:</span>
                <span class="metric-value">{{ formatPrice(data.athMetrics.ath.price) }} ({{ formatDate(data.athMetrics.ath.date) }})</span>
              </div>
              <div class="metric-row">
                <span class="metric-label">Current:</span>
                <span class="metric-value">{{ formatPrice(data.athMetrics.current.price) }} ({{ formatDate(data.athMetrics.current.date) }})</span>
              </div>
            </div>
            <div class="metric-group">
              <div class="metric-row">
                <span class="metric-label">Drawdown:</span>
                <span class="metric-value">{{ formatPercentage(data.athMetrics.current.drawdown) }} ({{ data.athMetrics.ath.daysSince }} days)</span>
              </div>
              <div class="metric-row">
                <span class="metric-label">Mean Return:</span>
                <span class="metric-value">{{ formatPercentage(data.distribution.stats.mean) }}</span>
              </div>
            </div>
            <div class="metric-group">
              <div class="metric-row">
                <span class="metric-label">Max Loss:</span>
                <span class="metric-value">{{ formatPercentage(data.distribution.stats.min) }}</span>
              </div>
              <div class="metric-row">
                <span class="metric-label">Max Return:</span>
                <span class="metric-value">{{ formatPercentage(data.distribution.stats.max) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="terminal-info">
      Select assets and timeframe to begin analysis
    </div>
  </div>
</template>

<script>
import ReturnDistribution from './ReturnDistribution.vue'

export default {
  name: 'AssetAnalysis',
  
  components: {
    ReturnDistribution
  },

  props: {
    analysisData: {
      type: Object,
      required: true
    },
    timeframe: {
      type: String,
      required: true
    },
    asset: {  // Add this prop
      type: String,
      required: true
    }
  },

  methods: {
    formatDate(dateStr) {
      return new Date(dateStr).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\//g, '-');
    },

    formatPrice(price) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(price);
    },

    formatPercentage(pct) {
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        signDisplay: 'always'
      }).format(pct / 100);
    }
  }
}
</script>

<style scoped>
.asset-analysis-panel {
  background: var(--terminal-bg);
}

.asset-analysis {
  padding: 0 1rem;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 0 1.5rem 1rem;
  margin: 0 0 2rem;
  background: #000000;
  border: 1px solid var(--terminal-border);
  border-top: none;
}

.metric-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
}

.metric-row {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  white-space: nowrap;
}

.metric-label {
  font-size: 0.9rem;
  color: var(--terminal-cyan);
  min-width: 120px;
}

.metric-value {
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--terminal-green);
}

.metric-date {
  color: var(--terminal-text-dim);
  font-size: 0.9em;
}

.metric-detail {
  font-size: 0.95rem;
}

.terminal-info {
  color: var(--terminal-cyan);
  text-align: center;
  padding: 2rem;
  font-style: italic;
}
</style> 