<template>
  <div class="options-view">
    <TerminalHeader title="Option Sellers" />

    <div class="controls-row">
      <!-- Asset Selection -->
      <OptionControls 
        :default-contract="defaultContract"
        @update:contract="handleContractUpdate"
        @update:expiries="expiries = $event"
        @update:strikes="strikes = $event"
        @update:ticker="handleTickerUpdate"
      />
    </div>

    <div class="dashboard-container">
      <!-- Strategy Visualization -->
      <div class="dashboard-panel">
        <div class="tool-header">
          <h2>📈 Strategy Visualizer</h2>
          <div class="strategy-buttons">
            <button 
              :class="{ active: selectedStrategy === 'covered-call' }"
              @click="selectedStrategy = 'covered-call'"
            >CC</button>
            <button 
              :class="{ active: selectedStrategy === 'cash-secured-put' }"
              @click="selectedStrategy = 'cash-secured-put'"
            >CSP</button>
          </div>
        </div>
        <div class="tool-content">
          <div class="input-row">
            <div class="input-group">
              <label>Stock Price</label>
              <input type="text" v-model="stockPriceFormatted" placeholder="Enter stock price">
            </div>
            <div class="input-group">
              <label>Strike Price</label>
              <input type="text" v-model="strikePriceFormatted" placeholder="Enter strike price">
            </div>
            <div class="input-group">
              <label>Premium</label>
              <input type="text" v-model="premiumFormatted" placeholder="Enter premium">
            </div>
          </div>
          <div class="visualization" ref="profitLossChart">
            <!-- Chart will be rendered here -->
          </div>
        </div>
      </div>

      <!-- Position Sizing Calculator -->
      <div class="dashboard-panel">
        <div class="tool-header">
          <h2>💰 Position Sizing & Strategy</h2>
        </div>
        <div class="tool-content">
          <div class="input-row">
            <div class="input-group">
              <label>Account Size</label>
              <input type="text" v-model="accountSizeFormatted" placeholder="Enter account size">
            </div>
            <div class="input-group">
              <label>Risk Tolerance (%)</label>
              <input type="number" v-model="riskTolerance" placeholder="Enter risk tolerance" min="1" max="100">
            </div>
          </div>
          
          <div class="input-row">
            <div class="input-group">
              <label>Target Monthly Income</label>
              <input type="text" v-model="targetMonthlyIncomeFormatted" placeholder="Target monthly income">
            </div>
            <div class="input-group">
              <label>Investor Profile</label>
              <select v-model="investorProfile" class="terminal-select">
                <option value="conservative">Conservative (Low Risk)</option>
                <option value="balanced">Balanced (Moderate Risk)</option>
                <option value="aggressive">Aggressive (Higher Risk)</option>
              </select>
            </div>
          </div>
          
          <div class="results">
            <div class="result-item">
              <span>Max Position Size:</span>
              <span>{{ formatUSD(maxPositionSize) }}</span>
            </div>
            <div class="result-item">
              <span>Max Loss Target:</span>
              <span>{{ formatUSD(maxLossTarget) }}</span>
            </div>
            <div class="result-item">
              <span>Contracts Needed:</span>
              <span>{{ contractsNeeded }}</span>
            </div>
          </div>
          
          <div class="strategy-recommendation">
            <h3>Recommended Strategy</h3>
            <div class="recommendation-content">
              <p>{{ strategyRecommendation }}</p>
              <div class="recommendation-details">
                <div class="detail-item">
                  <span>Strategy:</span>
                  <span>{{ recommendedStrategy }}</span>
                </div>
                <div class="detail-item">
                  <span>Delta Target:</span>
                  <span>{{ recommendedDelta }}</span>
                </div>
                <div class="detail-item">
                  <span>DTE Range:</span>
                  <span>{{ recommendedDTE }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Premium Calculator -->
      <div class="dashboard-panel">
        <div class="tool-header">
          <h2>🧮 Return Calculator</h2>
        </div>
        <div class="tool-content">
          <div class="input-row">
            <div class="input-group">
              <label>Strike Price</label>
              <input type="text" v-model="calcStrikeFormatted" placeholder="Enter strike price">
            </div>
            <div class="input-group">
              <label>Premium</label>
              <input type="text" v-model="calcPremiumFormatted" placeholder="Enter premium">
            </div>
            <div class="input-group">
              <label>Days to Expiration</label>
              <input type="number" v-model="daysToExpiration" placeholder="Enter DTE">
            </div>
          </div>
          <div class="results">
            <div class="result-item">
              <span>Annual Return:</span>
              <span>{{ annualizedReturn }}%</span>
            </div>
            <div class="result-item">
              <span>Return on Risk:</span>
              <span>{{ returnOnRisk }}%</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Trade Templates -->
      <div class="dashboard-panel">
        <div class="tool-header">
          <h2>📋 Screener</h2>
          <div class="template-buttons">
            <button class="template-btn" @click="applyTemplate('30-delta-put')">
              30Δ CSPs
            </button>
            <button class="template-btn" @click="applyTemplate('30-delta-call')">
              30Δ Calls
            </button>
            <button class="template-btn" @click="applyTemplate('monthly')">
              Monthly ATM
            </button>
            <button class="template-btn" @click="applyTemplate('high-iv')">
              High IV Rank
            </button>
          </div>
        </div>
        <div class="tool-content">
          <div v-if="recentContracts.length" class="screener-section">
            <div class="table-container">
              <table class="contracts-table">
                <thead>
                  <tr>
                    <th @click="sortTable('contract')">Contract</th>
                    <th @click="sortTable('ask')">Ask</th>
                    <th @click="sortTable('bid')">Bid</th>
                    <th @click="sortTable('delta')">Delta</th>
                    <th @click="sortTable('iv')">IV</th>
                    <th @click="sortTable('volume')">Volume</th>
                    <th @click="sortTable('oi')">OI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contract in sortedContracts" 
                      :key="contract.expiry + contract.strike + contract.type"
                      @click="selectContract(contract)"
                      class="contract-row"
                      :class="{ 'selected-contract': isSelectedContract(contract) }">
                    <td>{{ contract.expiry }} ${{ contract.strike }} {{ contract.type.substring(0, 1).toUpperCase() }}</td>
                    <td>{{ formatUSD(contract.ask, 2, false) }}</td>
                    <td>{{ formatUSD(contract.bid, 2, false) }}</td>
                    <td>{{ contract.delta.toFixed(3) }}</td>
                    <td>{{ (contract.iv * 100).toFixed(2) }}%</td>
                    <td>{{ contract.volume }}</td>
                    <td>{{ contract.oi }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
import OptionControls from '@/components/shared/OptionControls.vue'
import { parseISO, isWithinInterval, addMonths } from 'date-fns'
import api from '@/utils/api'

// Default contract configuration
const defaultContract = {
  symbol: 'IREN',
  expiry: '2026-01-16',
  strike: 20,
  type: 'C',
  eod_stock: 10,
}

// Strategy Visualizer
const selectedStrategy = ref('covered-call')
const stockPrice = ref(100)
const strikePrice = ref(105)
const premium = ref(2)
const profitLossChart = ref(null)

// Position Sizing
const accountSize = ref(100000)
const riskTolerance = ref(2)
const maxPositionSize = computed(() => {
  return Math.floor(accountSize.value * (riskTolerance.value / 100))
})
const maxLossTarget = computed(() => {
  return Math.floor(accountSize.value * (riskTolerance.value / 100) * 0.5)
})

// Premium Calculator
const calcStrike = ref(100)
const calcPremium = ref(2)
const daysToExpiration = ref(45)
const annualizedReturn = computed(() => {
  if (!calcStrike.value || !calcPremium.value || !daysToExpiration.value) return 0
  return ((calcPremium.value / calcStrike.value) * (365 / daysToExpiration.value) * 100).toFixed(2)
})
const returnOnRisk = computed(() => {
  if (!calcStrike.value || !calcPremium.value) return 0
  return ((calcPremium.value / calcStrike.value) * 100).toFixed(2)
})

// Add state for expiries and strikes
const expiries = ref([])
const strikes = ref([])

const recentContracts = ref([]);

const sortedContracts = computed(() => {
  return [...recentContracts.value].sort((a, b) => {
    const order = sortOrder.value ? 1 : -1;
    if (sortKey.value === 'iv') {
      return (b[sortKey.value] - a[sortKey.value]) * order;
    }
    return (a[sortKey.value] > b[sortKey.value] ? 1 : -1) * order;
  });
});

const sortKey = ref('contract');
const sortOrder = ref(true);

// Format USD values
function formatUSD(value, decimals = 2, includeSymbol = true) {
  if (value === undefined || value === null) return includeSymbol ? '$0.00' : '0.00';
  
  const formatter = new Intl.NumberFormat('en-US', {
    style: includeSymbol ? 'currency' : 'decimal',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  
  return formatter.format(value);
}

// Parse currency string to number
function parseCurrency(value) {
  if (!value) return 0;
  return parseFloat(value.replace(/[$,]/g, ''));
}

// Formatted input fields with getters and setters
const stockPriceFormatted = computed({
  get: () => formatUSD(stockPrice.value, 2, true),
  set: (val) => { stockPrice.value = parseCurrency(val) }
});

const strikePriceFormatted = computed({
  get: () => formatUSD(strikePrice.value, 2, true),
  set: (val) => { strikePrice.value = parseCurrency(val) }
});

const premiumFormatted = computed({
  get: () => formatUSD(premium.value, 2, true),
  set: (val) => { premium.value = parseCurrency(val) }
});

const calcStrikeFormatted = computed({
  get: () => formatUSD(calcStrike.value, 2, true),
  set: (val) => { calcStrike.value = parseCurrency(val) }
});

const calcPremiumFormatted = computed({
  get: () => formatUSD(calcPremium.value, 2, true),
  set: (val) => { calcPremium.value = parseCurrency(val) }
});

const accountSizeFormatted = computed({
  get: () => formatUSD(accountSize.value, 2, true),
  set: (val) => { accountSize.value = parseCurrency(val) }
});

const targetMonthlyIncome = ref(500)
const targetMonthlyIncomeFormatted = computed({
  get: () => formatUSD(targetMonthlyIncome.value, 2, true),
  set: (val) => { targetMonthlyIncome.value = parseCurrency(val) }
});

// Handle contract updates from OptionControls
const handleContractUpdate = async (contract) => {
  console.log('Contract updated:', contract);
  // Update the strategy visualizer with the new contract data
  stockPrice.value = contract.eod_stock || contract.underlyingPrice || stockPrice.value;
  strikePrice.value = contract.strike;
  premium.value = contract.premium || premium.value;
  
  createChart();

  // Fetch recent contracts for the selected ticker
  try {
    const response = await fetchContracts(contract);
    if (response.success) {
      recentContracts.value = response.contracts;
      // Handle the contracts data as needed
    } else {
      throw new Error(response.error || 'Failed to fetch recent contracts');
    }
  } catch (error) {
    console.error('Error fetching recent contracts:', error);
  }
};

// Handle ticker updates from OptionControls
const handleTickerUpdate = async (ticker) => {
  console.log('Ticker update received:', ticker);
  // Fetch recent contracts for the selected ticker
  try {
    const response = await fetchTickerContracts(ticker);
    if (response.success) {
      recentContracts.value = response.contracts;
      // Handle the contracts data as needed
    } else {
      throw new Error(response.error || 'Failed to fetch recent contracts');
    }
  } catch (error) {
    console.error('Error fetching recent contracts:', error);
  }
};

// Chart initialization and updates
onMounted(() => {
  createChart()
})

watch([selectedStrategy, stockPrice, strikePrice, premium, daysToExpiration], () => {
  createChart()
})

function createChart() {
  const priceRange = generatePriceRange()
  
  // Create the price-based P&L trace
  const pricePLTrace = {
    x: priceRange,
    y: calculateProfitLoss(priceRange),
    type: 'scatter',
    mode: 'lines',
    name: 'P/L by Price',
    line: {
      color: '#33ff33',
      width: 2
    },
    fill: 'tonexty',
    fillcolor: 'rgba(51, 255, 51, 0.1)'
  }
  
  const layout = {
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    margin: { t: 30, r: 20, b: 50, l: 50 },
    showlegend: true,
    legend: {
      x: 0,
      y: 1,
      orientation: 'h',
      font: { color: '#33ff33' }
    },
    xaxis: {
      title: 'Stock Price',
      gridcolor: 'rgba(51, 255, 51, 0.1)',
      zerolinecolor: 'rgba(51, 255, 51, 0.2)',
      tickfont: { color: '#33ff33' },
      titlefont: { color: '#33ff33' }
    },
    yaxis: {
      title: 'Profit/Loss',
      gridcolor: 'rgba(51, 255, 51, 0.1)',
      zerolinecolor: 'rgba(51, 255, 51, 0.2)',
      tickfont: { color: '#33ff33' },
      titlefont: { color: '#33ff33' }
    }
  }

  const config = {
    responsive: true,
    displayModeBar: false
  }

  Plotly.newPlot(profitLossChart.value, [pricePLTrace], layout, config)
}

function generatePriceRange() {
  const range = []
  // For puts, we want to show more downside risk
  // For calls, we want to show more upside potential
  let minPriceFactor = selectedStrategy.value === 'cash-secured-put' ? 0.5 : 0.7
  let maxPriceFactor = selectedStrategy.value === 'cash-secured-put' ? 1.2 : 1.5
  
  const minPrice = Math.max(0, stockPrice.value * minPriceFactor)
  const maxPrice = stockPrice.value * maxPriceFactor
  
  // Create more data points for smoother curve
  const step = (maxPrice - minPrice) / 50
  for (let i = minPrice; i <= maxPrice; i += step) {
    range.push(i)
  }
  return range
}

function calculateProfitLoss(priceRange) {
  return priceRange.map(price => {
    if (selectedStrategy.value === 'covered-call') {
      return calculateCoveredCallPL(Number(price))
    } else {
      return calculateCashSecuredPutPL(Number(price))
    }
  })
}

function calculateCoveredCallPL(price) {
  const pl = Math.min(strikePrice.value - stockPrice.value, price - stockPrice.value) + premium.value
  return pl
}

function calculateCashSecuredPutPL(price) {
  const pl = Math.min(premium.value, premium.value - (strikePrice.value - price))
  return pl
}

function applyTemplate(template) {
  let filteredContracts = [];
  switch (template) {
    case '30-delta-put':
      // This is tied to the return calculator. We should be able to click on a contract in our screener to toggle the udpates in the Strategy visualizer panel and the Return Calculator
      // daysToExpiration.value = 45;
      filteredContracts = recentContracts.value.filter(contract => {
        const isPut = contract.type === 'put';
        const deltaInRange = contract.delta <= -0.28 && contract.delta >= -0.32;
        return isPut && deltaInRange;
      });
      break;
    case '30-delta-call':
      // This is tied to the return calculator. We should be able to click on a contract in our screener to toggle the udpates in the Strategy visualizer panel and the Return Calculator
      // daysToExpiration.value = 3;
      filteredContracts = recentContracts.value.filter(contract => {
        const isCall = contract.type === 'call';
        const deltaInRange = contract.delta <= 0.28 && contract.delta >= 0.32;
        return isCall && deltaInRange;
      });
      break;
    case 'monthly':
      // This is tied to the return calculator. We should be able to click on a contract in our screener to toggle the udpates in the Strategy visualizer panel and the Return Calculator
      // daysToExpiration.value = 25;
      filteredContracts = recentContracts.value.filter(contract => {
        const expiryDate = parseISO(contract.expiry);
        const now = new Date();
        return isWithinInterval(expiryDate, { start: now, end: addMonths(now, 1) });
      });
      break;
    case 'high-iv':
      filteredContracts = recentContracts.value.filter(contract =>
        contract.iv > 1.0
      );
      break;
  }
  recentContracts.value = filteredContracts;
}

function sortTable(key) {
  if (sortKey.value === key) {
    sortOrder.value = !sortOrder.value;
  } else {
    sortKey.value = key;
    sortOrder.value = true;
  }
}

function selectContract(contract) {
  console.log('Contract selected:', contract);
  
  // Update Strategy Visualizer
  stockPrice.value = contract.eod_stock || contract.underlyingPrice || stockPrice.value;
  strikePrice.value = contract.strike;
  premium.value = (contract.ask + contract.bid) / 2; // Use mid-price as premium
  
  // Update strategy type based on contract type
  selectedStrategy.value = contract.type === 'call' ? 'covered-call' : 'cash-secured-put';
  
  // Update Return Calculator
  calcStrike.value = contract.strike;
  calcPremium.value = (contract.ask + contract.bid) / 2;
  
  // Calculate days to expiration
  const expiryDate = parseISO(contract.expiry);
  const now = new Date();
  const diffTime = Math.abs(expiryDate - now);
  daysToExpiration.value = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  // Store the selected contract for highlighting
  selectedContractRef.value = contract;
  
  // Update chart
  createChart();
}

// Add this computed property to track the selected contract
const selectedContractRef = ref(null);

function isSelectedContract(contract) {
  if (!selectedContractRef.value) return false;
  
  return selectedContractRef.value.expiry === contract.expiry && 
         selectedContractRef.value.strike === contract.strike &&
         selectedContractRef.value.type === contract.type;
}

// Add these new refs and computed properties after the existing ones
const investorProfile = ref('balanced')

const contractsNeeded = computed(() => {
  if (!calcPremium.value || calcPremium.value <= 0) return 0;
  // Each contract controls 100 shares
  const premiumPerContract = calcPremium.value * 100;
  return Math.ceil(targetMonthlyIncome.value / premiumPerContract);
})

const recommendedStrategy = computed(() => {
  switch(investorProfile.value) {
    case 'conservative':
      return 'Cash-Secured Puts on Blue Chips';
    case 'balanced':
      return selectedStrategy.value === 'covered-call' ? 'Covered Calls' : 'Cash-Secured Puts';
    case 'aggressive':
      return 'High IV Cash-Secured Puts';
    default:
      return 'Balanced Strategy';
  }
})

const recommendedDelta = computed(() => {
  switch(investorProfile.value) {
    case 'conservative':
      return '0.15 - 0.25';
    case 'balanced':
      return '0.25 - 0.35';
    case 'aggressive':
      return '0.35 - 0.45';
    default:
      return '0.25 - 0.35';
  }
})

const recommendedDTE = computed(() => {
  switch(investorProfile.value) {
    case 'conservative':
      return '45 - 60 days';
    case 'balanced':
      return '30 - 45 days';
    case 'aggressive':
      return '14 - 30 days';
    default:
      return '30 - 45 days';
  }
})

const strategyRecommendation = computed(() => {
  const profile = investorProfile.value;
  const income = targetMonthlyIncome.value;
  const accountVal = accountSize.value;
  
  if (income / accountVal > 0.05) {
    return `Your target income of ${formatUSD(income)}/month represents more than 5% of your account. This may require higher-risk strategies or more capital.`;
  }
  
  switch(profile) {
    case 'conservative':
      return `Focus on lower delta (${recommendedDelta.value}) puts on stable blue-chip stocks with longer expirations (${recommendedDTE.value}).`;
    case 'balanced':
      return `Sell ${recommendedDelta.value} delta options with ${recommendedDTE.value} to expiration on a mix of established growth and value stocks.`;
    case 'aggressive':
      return `Target higher premium opportunities with ${recommendedDelta.value} delta options on higher IV stocks, using ${recommendedDTE.value} expirations.`;
    default:
      return 'Select an investor profile for personalized recommendations.';
  }
})

async function fetchContracts(contract) {
  try {
    const response = await api.get('/options/history/contracts', {
      params: { ticker: contract.symbol }
    })
    if (response.data.success) {
      return response.data.contracts
    }
    throw new Error(response.data.error || 'Failed to fetch contracts')
  } catch (error) {
    console.error('Error fetching contracts:', error)
    throw error
  }
}

async function fetchTickerContracts(ticker) {
  try {
    const response = await api.get('/options/history/contracts', {
      params: { ticker }
    })
    if (response.data.success) {
      return response.data.contracts
    }
    throw new Error(response.data.error || 'Failed to fetch contracts')
  } catch (error) {
    console.error('Error fetching contracts:', error)
    throw error
  }
}
</script>

<style scoped>
.options-view {
  font-family: 'JetBrains Mono', monospace;
  background: var(--terminal-bg);
  color: var(--terminal-text);
  border: 1px solid var(--terminal-border);
}

.terminal-header {
  text-align: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--terminal-border);
}

.tool-header h2 {
  color: var(--terminal-text);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

/* Controls row layout */
.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: var(--terminal-bg-darker);
  border-bottom: 1px solid var(--terminal-border);
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px;
}

.dashboard-panel {
  background-color: var(--panel-bg);
  border: 1px solid var(--terminal-border);
  padding: 15px;
  border-radius: 5px;
}

.tool-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--terminal-border);
}

.input-group {
  font-size: 0.9rem;
}

.strategy-buttons, .template-buttons {
  display: flex;
  gap: 0.5rem;
}

.strategy-buttons button, .template-buttons .template-btn {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-dim);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.8rem;
  cursor: pointer;
}

.strategy-buttons button:hover, .template-buttons .template-btn:hover {
  border-color: var(--terminal-text);
  color: var(--terminal-text);
}

.strategy-buttons button.active {
  border-color: var(--status-healthy);
  color: var(--status-healthy);
}

.input-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.input-group {
  flex: 1;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--terminal-dim);
}

input, select {
  width: 100%;
  padding: 0.5rem;
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  border-radius: 4px;
}

input:focus, select:focus {
  outline: none;
  border-color: var(--terminal-text);
}

.visualization {
  height: 200px;
  margin-top: 1rem;
}

.results {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--terminal-border);
}

.result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.result-item span:first-child {
  color: var(--terminal-dim);
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.template-btn {
  padding: 0.75rem;
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.template-btn:hover {
  border-color: var(--terminal-text);
  background: rgba(51, 255, 51, 0.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-container {
    grid-template-columns: 1fr;
  }
  
  .terminal-content {
    padding: 1rem;
  }
}

.screener-section {
  background: var(--panel-bg);
  border: 1px solid var(--terminal-border);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.screener-section h3 {
  color: var(--terminal-text);
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.table-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--terminal-border);
  border-radius: 5px;
}

.contracts-table {
  width: 100%;
  border-collapse: collapse;
}

.contracts-table th, .contracts-table td {
  border: 1px solid var(--terminal-border);
  padding: 8px;
  text-align: left;
  color: var(--terminal-text);
}

.contracts-table th {
  background: var(--terminal-bg-darker);
  font-weight: bold;
}

.contracts-table td {
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9rem;
}

.contracts-table th {
  cursor: pointer;
}

/* Styles for clickable contracts */
.contract-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.contract-row:hover {
  background-color: rgba(51, 255, 51, 0.1);
}

.selected-contract {
  background-color: rgba(51, 255, 51, 0.2);
  border-left: 3px solid var(--status-healthy);
}

.strategy-recommendation {
  margin-top: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  background-color: rgba(51, 255, 51, 0.05);
}

.strategy-recommendation h3 {
  color: var(--terminal-text);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.recommendation-content p {
  color: var(--terminal-dim);
  font-size: 0.85rem;
  margin-bottom: 0.75rem;
}

.recommendation-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.detail-item span:first-child {
  color: var(--terminal-dim);
  font-size: 0.8rem;
}

.detail-item span:last-child {
  color: var(--status-healthy);
  font-weight: bold;
  font-size: 0.9rem;
}
</style>
