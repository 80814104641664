<template>
  <div class="terminal">
    <TerminalHeader 
      title="termFi Loan Terminal"
      @connection-change="handleConnectionChange"
    >
      <template #extra>
        <div class="time-selector" style="margin-right: 20px;">
          <select id="timeHorizon" v-model="selectedTimeHorizon" style="background: #1a1a1a; color: #33ff33; border: 1px solid #333; padding: 0.25rem;">
            <option value="30">30 Days</option>
            <option value="180">180 Days</option>
            <option value="365">1 Year</option>
            <option value="730">2 Years</option>
            <option value="1460">4 Years</option>
          </select>
        </div>
      </template>
    </TerminalHeader>

    <div class="loan-grid">
      <!-- Loan Summary Panel -->
      <LoanSummary :summary="currentSummary" />

      <!-- Risk Scenarios -->
      <RiskScenarios 
        :scenarios="currentScenarios"
      />

      <!-- Price Chart Panel -->
      <PriceChart 
        ref="priceChart"
        :data="lastLoanData"
        :timeHorizon="selectedTimeHorizon"
      />

      <!-- Terminal Output -->
      <TerminalOutput 
        ref="terminal"
        containerClass="loan-panel"
        containerStyle="grid-column: span 2;"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import PriceChart from '@/components/loan/PriceChart.vue'
import RiskScenarios from '@/components/loan/RiskScenarios.vue'
import LoanSummary from '@/components/loan/LoanSummary.vue'
import TerminalOutput from '@/components/shared/TerminalOutput.vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'

defineOptions({
  name: 'LoanView'
})

const selectedTimeHorizon = ref('30')
const isConnected = ref(true)
const priceChart = ref(null)
const lastLoanData = ref(null)
const currentScenarios = ref([{}])
const currentSummary = ref({
  collateralBTC: 0,
  loanAmount: 0,
  currentLTV: 0,
  interestRate: 0
})

// Add ref for terminal component
const terminal = ref(null)
let pollInterval = null

// Update watch effect
watch(selectedTimeHorizon, () => {
  if (lastLoanData.value?.simulations) {
    const selectedSimulation = lastLoanData.value.simulations[selectedTimeHorizon.value]
    if (selectedSimulation?.scenarios) {
      currentScenarios.value = selectedSimulation.scenarios
    }
  }
})

async function fetchLoanData() {
  try {
    const response = await fetch('http://localhost:3000/api/loan/simulation')
    if (!response.ok) {
      throw new Error('Failed to fetch loan data')
    }
    const data = await response.json()
    
    console.log('Received loan update:', data)
    lastLoanData.value = data
    
    if (data.summary) {
      currentSummary.value = data.summary
    }
    if (data.simulations) {
      if (priceChart.value) {
        priceChart.value.updateChart(data)
      }
      // Get scenarios from the selected time horizon
      const selectedSimulation = data.simulations[selectedTimeHorizon.value]
      if (selectedSimulation?.scenarios) {
        currentScenarios.value = selectedSimulation.scenarios
      }
    }

    // Log simulation statistics for all time horizons
    if (data.summary) {
      const timeHorizons = ['30', '180', '365', '730', '1460']
      const totalSims = data.simulations[timeHorizons[0]].allSimulations.length
      const timestamp = new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        month: 'numeric',
        day: 'numeric',
        year: '2-digit'
      })

      // Log header with processing stats
      terminal.value?.appendLog('\x1b[32m=== New Simulation Results ===\x1b[0m')
      terminal.value?.appendLog(`\x1b[37mRan ${totalSims} simulations at ${timestamp}\x1b[0m`)
      if (data.stats) {
        terminal.value?.appendLog(`\x1b[36mProcessing time: ${data.stats.processing_time}\x1b[0m`)
        terminal.value?.appendLog(`\x1b[36mMemory usage: ${Math.round(data.stats.memory_usage)}MB\x1b[0m`)
      }

      // Process each time horizon
      timeHorizons.forEach(horizon => {
        const simData = data.simulations[horizon]
        if (simData && simData.allSimulations) {
          
          const healthyCount = simData.allSimulations.filter(price =>
            data.summary.loanAmount / (price * data.summary.collateralBTC) < 0.65
          ).length

          const marginCallCount = simData.allSimulations.filter(price => {
            const ltv = data.summary.loanAmount / (price * data.summary.collateralBTC)
            return ltv >= 0.65 && ltv < 0.75
          }).length

          const liquidationCount = simData.allSimulations.filter(price =>
            data.summary.loanAmount / (price * data.summary.collateralBTC) >= 0.75
          ).length

          // Format the time horizon label
          let timeLabel
          switch (horizon) {
            case '30': timeLabel = '30 day risk   '; break
            case '180': timeLabel = '180 day risk  '; break
            case '365': timeLabel = '1 year risk   '; break
            case '730': timeLabel = '2 year risk   '; break
            case '1460': timeLabel = '4 year risk   '; break
          }

          // Build the risk message
          let riskMessage = `\x1b[37m${timeLabel}- `
          if (healthyCount === totalSims) {
            riskMessage += '\x1b[32mall simulations healthy\x1b[0m'
          } else {
            let parts = []
            if (healthyCount > 0) {
              parts.push(`\x1b[32m${healthyCount} healthy\x1b[0m`)
            }
            if (marginCallCount > 0) {
              parts.push(`\x1b[33m${marginCallCount} margin calls\x1b[0m`)
            }
            if (liquidationCount > 0) {
              parts.push(`\x1b[31m${liquidationCount} liquidations\x1b[0m`)
            }
            riskMessage += parts.join(', ')
          }
          terminal.value?.appendLog(riskMessage)
        }
      })
    }

    handleConnectionChange(true)
  } catch (error) {
    console.error('Error fetching loan data:', error)
    handleConnectionChange(false)
    terminal.value?.appendLog('\x1b[31m=== Error fetching loan data ===\x1b[0m')
  }
}

onMounted(() => {
  // Display initial connection information
  terminal.value?.appendLog('\x1b[32m=== Welcome to termFi Loan Terminal ===\x1b[0m')
  terminal.value?.appendLog('\x1b[37mConnecting to simulation engine...\x1b[0m')

  const timestamp = new Date().toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    month: 'numeric',
    day: 'numeric',
    year: '2-digit'
  })

  terminal.value?.appendLog('\x1b[36mSession started at ' + timestamp + '\x1b[0m')
  terminal.value?.appendLog('\x1b[37mWaiting for initial simulation data...\x1b[0m')

  // Fetch initial data
  fetchLoanData()

  // Set up polling interval (every 5 minutes)
  pollInterval = setInterval(fetchLoanData, 5 * 60 * 1000)
})

onUnmounted(() => {
  if (pollInterval) {
    clearInterval(pollInterval)
  }
})

// Handle connection status changes from TerminalHeader
function handleConnectionChange(connected) {
  isConnected.value = connected
  if (connected) {
    terminal.value?.appendLog('\x1b[32m=== Connected to loan simulation engine ===\x1b[0m')
  } else {
    terminal.value?.appendLog('\x1b[31m=== Disconnected from loan simulation engine ===\x1b[0m')
  }
}
</script>

<style>
.loan-panel h3 {
  font-size: 14px;
}
.terminal-content {
  height: 100px;
  overflow-y: auto;
}

.time-selector {
  display: flex;
  align-items: center;
}

.time-selector select {
  background: #1a1a1a;
  color: #33ff33;
  border: 1px solid #333;
  padding: 0.25rem;
  font-family: monospace;
  cursor: pointer;
}

.time-selector select:hover {
  border-color: #33ff33;
}

.time-selector select:focus {
  outline: none;
  border-color: #33ff33;
  box-shadow: 0 0 2px #33ff33;
}
</style>