<template>
  <div class="treemap-container" ref="chartContainer"></div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'

const props = defineProps({
  assets: {
    type: Object,
    required: true
  }
})

const chartContainer = ref(null)

function calculateTreeData() {
  const sortedAssets = Object.entries(props.assets)
    .sort((a, b) => b[1].value - a[1].value)

  const labels = []
  const parents = []
  const values = []
  const text = []

  // Add root
  labels.push('Assets')
  parents.push('')
  values.push(0)
  text.push('')

  // Add assets
  sortedAssets.forEach(([key, asset]) => {
    labels.push(formatLabel(key))
    parents.push('Assets')
    values.push(asset.value / 1000)
    text.push(`$${(asset.value / 1000).toLocaleString()}K`)
  })

  return [{
    type: 'treemap',
    labels,
    parents,
    values,
    text: text.map((t, i) => {
      if (labels[i] === 'Assets') return ''
      const value = values[i]
      const percent = ((value * 1000) / Object.values(props.assets).reduce((sum, asset) => sum + asset.value, 0) * 100).toFixed(1)
      return `${percent}%`
    }),
    textinfo: 'label+text',
    textposition: 'middle center',
    outsidetextfont: {
      family: 'JetBrains Mono, monospace',
      size: 11,
      color: '#33ff33'
    },
    marker: {
      line: {
        color: '#33ff33',
        width: 2
      },
      colors: labels.map(label => {
        if (label === 'Assets') return 'rgba(0,0,0,0)'
        const key = Object.keys(props.assets).find(k => formatLabel(k) === label)
        return key === 'stocks' ? '#000000' :
               key === 'realEstate' ? '#000000' :
               key === 'business' ? '#000000' :
               key === 'bitcoin' ? '#000000' :
               key === 'moneyMarket' ? '#000000' :
               'rgba(0,0,0,0)'
      })
    },
    textfont: {
      family: 'JetBrains Mono, monospace',
      size: 12,
      color: '#33ff33'
    },
    hoverinfo: 'label+value+percent',
    hovertemplate: '<b>%{label}</b><br>$%{value:,.0f}K<br>%{percent}<extra></extra>',
    hoverlabel: {
      bgcolor: '#333333',
      bordercolor: '#33ff33',
      font: {
        family: 'JetBrains Mono, monospace',
        size: 12,
        color: '#33ff33'
      }
    }
  }]
}

const chartLayout = {
  paper_bgcolor: 'rgba(0,0,0,1)',
  plot_bgcolor: 'rgba(0,0,0,1)',
  margin: {
    l: 0,
    r: 0,
    t: 30,
    b: 0
  },
  font: {
    family: 'JetBrains Mono, monospace',
    size: 11,
    color: '#33ff33'
  },
  showlegend: false,
  height: 300,
  title: {
    text: 'Asset Allocation',
    font: {
      family: 'JetBrains Mono, monospace',
      size: 12,
      color: '#33ff33'
    },
    y: 0.98
  }
}

function formatLabel(key) {
  return key.replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function initChart() {
  if (!chartContainer.value) return
  
  const data = calculateTreeData()
  const layout = {
    ...chartLayout,
    width: chartContainer.value.offsetWidth
  }

  Plotly.newPlot(chartContainer.value, data, layout, {
    displayModeBar: false,
    responsive: true
  })
}

// Add resize handler
let resizeTimeout
function handleResize() {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    if (chartContainer.value) {
      Plotly.relayout(chartContainer.value, {
        width: chartContainer.value.offsetWidth
      })
    }
  }, 100)
}

// Lifecycle hooks
onMounted(() => {
  window.addEventListener('resize', handleResize)
  initChart()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})

// Watch for changes in assets
watch(() => props.assets, () => {
  initChart()
}, { deep: true })
</script>

<style scoped>
.treemap-container {
  width: 100%;
  height: 300px;
}
</style> 