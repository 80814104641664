<template>
  <div class="loan-panel" style="grid-column: span 2;">
    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
      <h3>BTC Price Movement</h3>
    </div>
    <div ref="chartContainer" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'

const chartContainer = ref(null)
const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => ({})
  },
  timeHorizon: {
    type: String,
    required: true
  }
})

// Chart configuration (matching terminal.js theme)
const chartLayout = {
  paper_bgcolor: 'rgba(0,0,0,0)',
  plot_bgcolor: 'rgba(0,0,0,0)',
  font: {
    color: '#33ff33',
    family: 'JetBrains Mono, monospace',
    size: 12
  },
  margin: {
    l: 50,
    r: 30,
    t: 30,
    b: 30
  },
  showlegend: true,
  legend: {
    x: 0.02,
    y: 0.98,
    bgcolor: 'rgba(0,0,0,0)',
    bordercolor: '#1a1a1a',
    font: {
      color: '#33ff33',
      size: 10
    }
  },
  xaxis: {
    showgrid: true,
    gridcolor: '#1a1a1a',
    gridwidth: 1,
    zerolinecolor: '#1a1a1a',
    tickfont: {
      color: '#33ff33'
    },
    title: 'LTV Ratio'
  },
  yaxis: {
    showgrid: true,
    gridcolor: '#1a1a1a',
    gridwidth: 1,
    zerolinecolor: '#1a1a1a',
    tickprefix: '$',
    tickformat: ',',
    tickfont: {
      color: '#33ff33'
    },
    title: 'BTC Price'
  }
}

const chartConfig = {
  responsive: true,
  displayModeBar: false
}

// Initialize price chart with risk zones
function initChart() {
  if (!chartContainer.value) return

  // Initial empty distribution
  const data = [{
    name: 'Distribution',
    type: 'histogram',
    x: [],
    marker: {
      color: '#33ff33',
      line: {
        color: '#1a1a1a',
        width: 1
      }
    },
    opacity: 0.8,
    showlegend: true,
    autobinx: false,
    nbinsx: 40,
    histnorm: 'probability density',
    histfunc: 'count',
    yaxis: 'y',
  }]

  const layout = {
    ...chartLayout,
    title: {
      text: 'Risk Distribution',
      font: { color: '#33ff33' }
    },
    xaxis: {
      ...chartLayout.xaxis,
      title: {
        text: 'LTV Ratio',
        font: { color: '#33ff33' }
      },
      range: [0, 1],
      dtick: 0.05
    },
    yaxis: {
      ...chartLayout.yaxis,
      title: {
        text: 'Probability Density',
        font: { color: '#33ff33' }
      },
      tickprefix: '',
      showgrid: true,
      autorange: true
    },
    yaxis2: {
      title: {
        text: 'Cumulative %',
        font: { color: '#ffffff' }
      },
      overlaying: 'y',
      side: 'right',
      range: [0, 100],
      ticksuffix: '%',
      showgrid: false,
      tickfont: { color: '#ffffff' }
    },
    shapes: [{
      type: 'line',
      x0: 0.65,
      x1: 0.65,
      y0: 0,
      y1: 1,
      yref: 'paper',
      line: {
        color: '#ffff33',
        width: 2,
        dash: 'dash'
      }
    }, {
      type: 'line',
      x0: 0.75,
      x1: 0.75,
      y0: 0,
      y1: 1,
      yref: 'paper',
      line: {
        color: '#ff3333',
        width: 2,
        dash: 'dash'
      }
    }],
    annotations: [{
      x: 0.65,
      y: 1,
      xref: 'x',
      yref: 'paper',
      text: 'Margin Call',
      showarrow: false,
      font: {
        color: '#ffff33',
        size: 12
      },
      bgcolor: 'rgba(0,0,0,0.7)',
      borderpad: 4,
      yshift: 20
    }, {
      x: 0.75,
      y: 1,
      xref: 'x',
      yref: 'paper',
      text: 'Liquidation',
      showarrow: false,
      font: {
        color: '#ff3333',
        size: 12
      },
      bgcolor: 'rgba(0,0,0,0.7)',
      borderpad: 4,
      yshift: 20
    }]
  }

  Plotly.newPlot(chartContainer.value, data, layout, chartConfig)
}

const updateChart = (data) => {
  if (!chartContainer.value || !data || !data.summary) return

  const loanAmount = data.summary.loanAmount
  const collateralBTC = data.summary.collateralBTC

  try {
    if (!data.simulations?.[props.timeHorizon]?.allSimulations) {
      console.error('No valid simulation data available')
      return
    }

    const prices = data.simulations[props.timeHorizon].allSimulations
    const ltvRatios = prices.map(price => loanAmount / (price * collateralBTC))

    // Sort LTV ratios for cumulative distribution
    const sortedRatios = [...ltvRatios].sort((a, b) => a - b)
    const totalCount = sortedRatios.length
    
    // Create cumulative distribution data
    const numPoints = 100
    const step = totalCount / numPoints
    const cumulativeData = { x: [], y: [] }

    for (let i = 0; i < numPoints; i++) {
      const index = Math.floor(i * step)
      cumulativeData.x.push(sortedRatios[index])
      cumulativeData.y.push((index / totalCount) * 100)
    }
    cumulativeData.x.push(sortedRatios[totalCount - 1])
    cumulativeData.y.push(100)

    // Create histogram data
    const binSize = 0.05
    const binCount = 20
    const binEdges = Array.from({ length: binCount + 1 }, (_, i) => i * binSize)
    const binCenters = binEdges.slice(0, -1).map(edge => edge + binSize / 2)

    // Calculate histogram data
    const counts = new Array(binCount).fill(0)
    ltvRatios.forEach(ltv => {
      const binIndex = Math.min(Math.floor(ltv / binSize), binCount - 1)
      counts[binIndex]++
    })

    const total = counts.reduce((a, b) => a + b, 0)
    const percentages = counts.map(count => (count / total) * 100)

    // Generate bar colors
    const barColors = binCenters.map(center => {
      if (center >= 0.75) return '#ff3333'
      if (center >= 0.65) return '#ffff33'
      return '#33ff33'
    })

    const plotData = [{
      x: binCenters,
      y: percentages,
      type: 'bar',
      name: 'Distribution',
      marker: {
        color: barColors,
        line: {
          color: '#1a1a1a',
          width: 1
        }
      },
      opacity: 0.8,
      width: binSize * 0.9,
      yaxis: 'y'
    }, {
      x: cumulativeData.x,
      y: cumulativeData.y,
      type: 'scatter',
      name: 'Cumulative %',
      line: {
        color: '#ffffff',
        width: 2
      },
      opacity: 0.6,
      yaxis: 'y2'
    }]

    const layout = {
      ...chartLayout,
      title: {
        text: `Risk Distribution (${props.timeHorizon} Day Projection)`,
        font: { color: '#33ff33' }
      },
      xaxis: {
        ...chartLayout.xaxis,
        title: {
          text: 'LTV Ratio',
          font: { color: '#33ff33' }
        },
        range: [0, 1],
        dtick: 0.05
      },
      yaxis2: {
        title: {
          text: 'Cumulative %',
          font: { color: '#ffffff' }
        },
        overlaying: 'y',
        side: 'right',
        range: [0, 100],
        ticksuffix: '%',
        showgrid: false,
        tickfont: { color: '#ffffff' }
      },
      shapes: [{
        type: 'line',
        x0: 0.65,
        x1: 0.65,
        y0: 0,
        y1: 1,
        yref: 'paper',
        line: {
          color: '#ffff33',
          width: 2,
          dash: 'dash'
        }
      }, {
        type: 'line',
        x0: 0.75,
        x1: 0.75,
        y0: 0,
        y1: 1,
        yref: 'paper',
        line: {
          color: '#ff3333',
          width: 2,
          dash: 'dash'
        }
      }]
    }

    Plotly.react(chartContainer.value, plotData, layout, chartConfig)
  } catch (error) {
    console.error('Error updating chart:', error)
  }
}

// Watch for changes in props
watch(() => props.timeHorizon, () => {
  if (props.data) {
    updateChart(props.data)
  }
})

defineExpose({
  updateChart
})

onMounted(() => {
  initChart()
  if (props.data) {
    updateChart(props.data)
  }
})

onUnmounted(() => {
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})
</script>