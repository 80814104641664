<template>
  <div class="terminal">
    <TerminalHeader 
      title="Cost Basis Calculator"
      @connection-change="handleConnectionChange"
    />
    <div class="dashboard-container">
      <!-- Result Panel -->
      <div class="dashboard-panel result-panel">
        <div class="result-value">
          New Cost Basis: ${{ newCostBasis.toFixed(2) }}
        </div>
      </div>

      <!-- Input Panel -->
      <div class="dashboard-panel calculator-panel">
        <h3 class="panel-title">Cost Basis Calculator</h3>
        <div class="input-group">
          <div class="input-row">
            <div class="input-label">Existing Shares</div>
            <div class="input-wrapper">
              <input 
                type="text" 
                v-model="existingSharesInput"
                @blur="handleBlur('existingShares')"
              >
            </div>
          </div>
          <div class="input-row">
            <div class="input-label">Cost Basis ($)</div>
            <div class="input-wrapper">
              <span class="currency-symbol">$</span>
              <input 
                type="text" 
                v-model="costBasisInput"
                @blur="handleBlur('costBasis')"
              >
            </div>
          </div>
          <div class="input-row">
            <div class="input-label">Future Quantity</div>
            <div class="input-wrapper">
              <input 
                type="text" 
                v-model="futureQuantityInput"
                @blur="handleBlur('futureQuantity')"
              >
            </div>
          </div>
          <div class="input-row">
            <div class="input-label">Future Price per Share ($)</div>
            <div class="input-wrapper">
              <span class="currency-symbol">$</span>
              <input 
                type="text" 
                v-model="futurePriceInput"
                @blur="handleBlur('futurePrice')"
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Results Table -->
      <div class="dashboard-panel results-table">
        <div class="results-table">
          <table>
            <thead>
              <tr>
                <th>Quantity</th>
                <th v-for="price in tablePrices" :key="price">
                  ${{ formatNumber(price) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="qty in tableQuantities" :key="qty">
                <td>{{ formatNumberWithCommas(qty) }}</td>
                <td 
                  v-for="price in tablePrices" 
                  :key="price"
                  :class="{
                    'current-scenario': Number(qty) === Number(futureQuantity) && Number(price) === Number(futurePrice),
                    'better-scenario': calculateNewBasis(qty, price) < newCostBasis,
                    'worse-scenario': calculateNewBasis(qty, price) > newCostBasis
                  }"
                >
                  ${{ formatNumber(calculateNewBasis(qty, price)) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'

defineOptions({
  name: 'CostBasisView'
})

// Input state
const existingShares = ref(100)
const costBasis = ref(10.30)
const futureQuantity = ref(50)
const futurePrice = ref(9.75)

// Raw input state
const existingSharesInput = ref(String(existingShares.value))
const costBasisInput = ref(String(costBasis.value))
const futureQuantityInput = ref(String(futureQuantity.value))
const futurePriceInput = ref(String(futurePrice.value))

// Computed results
const newCostBasis = computed(() => {
  return calculateNewBasis(futureQuantity.value, futurePrice.value)
})

// Table data
const tableQuantities = computed(() => {
  const baseQty = Number(futureQuantity.value)
  return [
    baseQty,
    Math.round(baseQty * 1.5),
    Math.round(baseQty * 2),
    Math.round(baseQty * 2.5),
    Math.round(baseQty * 3)
  ]
})

const tablePrices = computed(() => {
  const basePrice = Number(futurePrice.value)
  return [
    basePrice,
    basePrice * 0.9,
    basePrice * 0.8,
    basePrice * 0.7,
    basePrice * 0.6
  ]
})

function calculateNewBasis(qty, price) {
  const totalShares = Number(existingShares.value) + Number(qty)
  const totalCost = (Number(existingShares.value) * Number(costBasis.value)) + 
                   (Number(qty) * Number(price))
  return totalCost / totalShares
}

function formatNumber(value) {
  return Number(value).toFixed(2)
}

function formatNumberWithCommas(value) {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

// Input handling
function handleBlur(field) {
  const inputMap = {
    existingShares: existingSharesInput,
    costBasis: costBasisInput,
    futureQuantity: futureQuantityInput,
    futurePrice: futurePriceInput
  }

  const value = inputMap[field].value.replace(/[^\d.-]/g, '')
  const parsed = parseFloat(value)

  if (!isNaN(parsed)) {
    if (field === 'existingShares' || field === 'futureQuantity') {
      // Ensure whole numbers for quantities
      const rounded = Math.max(1, Math.round(parsed))
      if (field === 'futureQuantity') {
        futureQuantity.value = rounded
        futureQuantityInput.value = String(rounded)
      } else {
        existingShares.value = rounded
        existingSharesInput.value = String(rounded)
      }
    } else {
      // Keep 2 decimal places for prices and ensure positive
      const fixed = Math.max(0.01, parseFloat(parsed.toFixed(2)))
      if (field === 'costBasis') {
        costBasis.value = fixed
        costBasisInput.value = String(fixed)
      } else {
        futurePrice.value = fixed
        futurePriceInput.value = String(fixed)
      }
    }
  } else {
    // Reset to previous valid value if input is invalid
    inputMap[field].value = String(field === 'existingShares' ? existingShares.value :
                                 field === 'costBasis' ? costBasis.value :
                                 field === 'futureQuantity' ? futureQuantity.value :
                                 futurePrice.value)
  }
}

// Handle connection status changes
function handleConnectionChange(connected) {
  // Update UI or state based on connection status
  console.log('Connection status:', connected ? 'Connected' : 'Disconnected')
}
</script>

<style scoped>
* {
  font-family: 'JetBrains Mono', monospace;
}
.dashboard-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  gap: 1rem;
  padding: 1rem;
  height: calc(100vh - 60px);
}

.dashboard-panel {
  background: var(--panel-background);
  border: 1px solid var(--terminal-border);
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
}

.panel-title {
  color: var(--status-healthy);
  font-size: 1rem;
  margin: 0 0 1rem 0;
}

.result-panel {
  grid-column: span 2;
  text-align: center;
}

.result-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--terminal-text);
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.input-row {
  display: grid;
  grid-template-columns: 180px 1fr;
  align-items: center;
  gap: 1rem;
}

.input-label {
  color: var(--terminal-dim);
  font-size: 0.9rem;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.currency-symbol {
  position: absolute;
  left: 0.5rem;
  color: var(--terminal-dim);
  font-family: monospace;
}

input {
  width: 100%;
  background: var(--input-background);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9rem;
}

input:focus {
  outline: none;
  border-color: var(--terminal-text);
  box-shadow: 0 0 0 1px var(--terminal-text);
}

input[type="text"] {
  padding-left: 1.5rem;
}

.results-table {
  grid-column: 2;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: monospace;
}

th, td {
  padding: 0.5rem 0.75rem;
  text-align: right;
  border: 1px solid var(--terminal-border);
}

th {
  background: var(--panel-background);
  color: var(--terminal-dim);
  font-size: 0.9rem;
}

td {
  color: var(--terminal-text);
  font-weight: normal;
  font-size: 0.85rem;
}

td:nth-child(1) {
  color: var(--terminal-dim);
  font-size: 0.9rem;
}

tr:nth-child(even) td {
  background: rgba(51, 255, 51, 0.02);
}

.current-scenario {
  background: rgba(51, 255, 51, 0.1) !important;
  font-weight: bold;
}

.better-scenario {
  color: var(--status-healthy);
}

.worse-scenario {
  color: #00ffff;
}

td:hover {
  background: rgba(51, 255, 51, 0.05) !important;
}
</style> 