<template>
  <div class="terminal-stats">
    <div>Average: {{ formatNumber(overallAverage) }}%</div>
    <div>Median: {{ formatNumber(overallMedian) }}%</div>
  </div>
</template>

<script>
export default {
  name: 'HeatmapStats',
  
  props: {
    summary: {
      type: Object,
      required: true
    }
  },

  computed: {
    overallAverage() {
      if (!this.summary?.averages) return null
      const values = this.summary.averages.filter(v => v !== null)
      return values.length ? values.reduce((a, b) => a + b, 0) / values.length : null
    },
    overallMedian() {
      if (!this.summary?.medians) return null
      const values = this.summary.medians.filter(v => v !== null)
      return values.length ? values.reduce((a, b) => a + b, 0) / values.length : null
    }
  },

  methods: {
    formatNumber(num) {
      if (num === null || num === undefined) return '0.00'
      return Number(num).toFixed(1)
    }
  }
}
</script>

<style scoped>
.terminal-stats {
  display: flex;
  gap: 2rem;
  padding: 0.5rem;
  justify-content: center;
}
</style> 