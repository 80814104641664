<template>
  <div class="loan-panel">
    <h3>Loan Summary</h3>
    <div class="loan-stats">
      <div class="stat-label">Collateral:</div>
      <div class="stat-value" id="collateral-amount">0 BTC</div>
      <div class="stat-label">Loan Amount:</div>
      <div class="stat-value" id="loan-amount">$0</div>
      <div class="stat-label">Current LTV:</div>
      <div class="stat-value" id="current-ltv">0%</div>
      <div class="stat-label">Interest Rate:</div>
      <div class="stat-value" id="interest-rate">0%</div>
    </div>
    <div class="risk-indicator">
      <span>LTV Risk:</span>
      <div class="risk-bar">
        <div class="risk-level" id="ltv-risk" style="width: 0%"></div>
      </div>
      <span id="risk-percentage">0%</span>
    </div>
  </div>
</template>

<script setup>
import { watch, nextTick } from 'vue'

const props = defineProps({
  summary: {
    type: Object,
    required: true
  }
})

const updateSummary = (summary) => {
  // Wait for next tick to ensure DOM elements are mounted
  nextTick(() => {
    try {
      // Update loan summary panel
      document.getElementById('collateral-amount').textContent = `${summary.collateralBTC} BTC`
      document.getElementById('loan-amount').textContent = `$${summary.loanAmount.toLocaleString()}`
      document.getElementById('current-ltv').textContent = `${(summary.currentLTV * 100).toFixed(2)}%`
      document.getElementById('interest-rate').textContent = `${(summary.interestRate * 100).toFixed(2)}%`

      // Update LTV risk bar
      const riskBar = document.getElementById('ltv-risk')
      const riskPercentage = document.getElementById('risk-percentage')
      const riskLevel = summary.currentLTV * 100

      riskBar.style.width = `${riskLevel}%`
      riskPercentage.textContent = `${riskLevel.toFixed(2)}%`

      // Update risk bar color based on LTV level
      if (riskLevel < 65) {
        riskBar.style.backgroundColor = '#33ff33'
      } else if (riskLevel < 75) {
        riskBar.style.backgroundColor = '#ffff33'
      } else {
        riskBar.style.backgroundColor = '#ff3333'
      }
    } catch (error) {
      console.error('Error updating loan summary:', error)
    }
  })
}

watch(() => props.summary, (newSummary) => {
  if (newSummary) {
    updateSummary(newSummary)
  }
}, { immediate: true })
</script> 