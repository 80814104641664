<template>
  <div class="terminal">
    <TerminalHeader 
      title="Net Worth Terminal"
      @connection-change="handleConnectionChange"
    >
      <template #extra>
        <span>${{ formatCurrency(currentNetWorth) }} / $10M</span>
      </template>
    </TerminalHeader>

    <div class="dashboard-container">
      <!-- Middle Row: Asset Inputs and Summary -->
      <div class="dashboard-panel">
        <h3>Portfolio Assets</h3>
        <div class="input-group">
          <div class="asset-row" v-for="(asset, key) in assets" :key="key">
            <div class="asset-label">{{ formatLabel(key) }}</div>
            <div class="input-container">
              <div class="input-wrapper value-input">
                <span class="currency-symbol">$</span>
                <input 
                  type="text" 
                  :value="formatInputCurrency(asset.value)"
                  @input="e => updateAssetValue(key, e.target.value)"
                  @blur="e => formatOnBlur(key, e.target.value)"
                >
              </div>
              <div class="input-wrapper return-input">
                <input 
                  type="number" 
                  v-model="asset.return" 
                  step="0.1" 
                  @input="calculateProjections"
                >
                <span class="percentage-symbol">%</span>
              </div>
              <div class="scenario-buttons">
                <button 
                  v-for="(scenario, name) in scenarios[key]" 
                  :key="name"
                  :class="{ active: selectedScenarios[key] === name }"
                  :data-scenario="name"
                  @click="updateScenario(key, name)"
                  :title="scenario.description"
                >
                  {{ name.charAt(0).toUpperCase() + name.slice(1) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-panel">
        <ProjectionChart 
          title="Net Worth Growth Projection"
          :assets="assets"
        />
      </div>
      <div class="dashboard-panel">
        <div class="summary-stats">
          <div class="stat-row">
            <div class="stat-label">Current Net Worth</div>
            <div class="stat-value positive">${{ formatCurrency(currentNetWorth) }}</div>
          </div>
          <div class="stat-row">
            <div class="stat-label">Expected Annual Return</div>
            <div class="stat-value positive">${{ formatCurrency(expectedAnnualReturn) }}</div>
          </div>
          <div class="stat-row">
            <div class="stat-label">5-Year Projection</div>
            <div class="stat-value positive">${{ formatCurrency(fiveYearProjection) }}</div>
          </div>
          <div class="stat-row">
            <div class="stat-label">Goal Progress</div>
            <div class="stat-value">{{ formatPercent(goalProgress) }}%</div>
          </div>
        </div>
        <div class="goal-tracker">
          <h4>Path to $10M</h4>
          <div class="progress-bar">
            <div class="progress-fill" :style="{ width: `${goalProgress}%` }"></div>
          </div>
          <div class="goal-stats">
            <div class="stat">
              <div class="label">Potential Shortfall</div>
              <div class="value">${{ formatCurrency(potentialShortfall) }}</div>
            </div>
            <div class="stat">
              <div class="label">Required Annual CAGR</div>
              <div class="value">{{ formatPercent(requiredAnnualReturn) }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-panel">
        <div class="panel-header">
          <h3>Net Worth Summary</h3>
          <div class="viz-selector">
            <button 
              :class="{ active: selectedViz === 'pie' }"
              @click="selectedViz = 'pie'"
            >Donut</button>
            <button 
              :class="{ active: selectedViz === 'treemap' }"
              @click="selectedViz = 'treemap'"
            >Tree Map</button>
            <button 
              :class="{ active: selectedViz === 'waffle' }"
              @click="selectedViz = 'waffle'"
            >Waffle</button>
          </div>
        </div>
        <component 
          :is="selectedViz === 'pie' ? AssetPieChart : 
               selectedViz === 'treemap' ? AssetTreeMap : 
               AssetWaffleChart"
          :assets="assets"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'
import ProjectionChart from '@/components/shared/ProjectionChart.vue'
import AssetPieChart from '@/components/shared/AssetPieChart.vue'
import AssetTreeMap from '@/components/shared/AssetTreeMap.vue'
import AssetWaffleChart from '@/components/shared/AssetWaffleChart.vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'

defineOptions({
  name: 'NetWorthView'
})

// State
const scenarios = {
  stocks: {
    bear: { return: 9, description: 'Decade low appreciation' },
    base: { return: 11, description: 'Lower range appreciation' },
    expected: { return: 13, description: 'Mid range appreciation' },
    growth: { return: 18, description: 'High range appreciation' }
  },
  realEstate: {
    bear: { return: 6, description: 'Decade low appreciation' },
    base: { return: 8, description: 'Lower range appreciation' },
    expected: { return: 9, description: 'Mid range appreciation' },
    growth: { return: 11, description: 'High range appreciation' }
  },
  business: {
    bear: { return: 2.7, description: '$6.3M val (3x $2.1M ARR)' },
    base: { return: 12, description: '$11.4M val (3.5x $3.2M ARR)' },
    expected: { return: 17, description: '$16M val (4x $4M ARR)' },
    growth: { return: 27, description: '$30M val (6x $5M ARR)' }
  },
  bitcoin: {
    bear: { return: 14, description: 'BTC @ $200k' },
    base: { return: 23, description: 'BTC @ $300k' },
    expected: { return: 30, description: 'BTC @ $400k' },
    growth: { return: 36, description: 'BTC @ $500k' }
  },
  moneyMarket: {
    bear: { return: 0, description: 'Fed @ 0%' },
    base: { return: 1, description: 'Fed @ 1%' },
    expected: { return: 3, description: 'Fed @ 3%' },
    growth: { return: 4, description: 'Fed @ 4%' }
  }
}

const assets = ref({
  stocks: { value: 600000, return: scenarios.stocks.expected.return },
  realEstate: { value: 650000, return: scenarios.realEstate.expected.return },
  business: { value: 1000000, return: scenarios.business.expected.return },
  bitcoin: { value: 2000000, return: scenarios.bitcoin.base.return },
  moneyMarket: { value: 300000, return: scenarios.moneyMarket.expected.return }
})

const selectedScenarios = ref({
  stocks: 'expected',
  realEstate: 'expected',
  business: 'expected',
  bitcoin: 'base',
  moneyMarket: 'expected'
})

function updateScenario(asset, scenario) {
  selectedScenarios.value[asset] = scenario
  assets.value[asset].return = scenarios[asset][scenario].return
}

// Chart reference
const chartContainer = ref(null)

// Add visualization state
const selectedViz = ref('treemap') // 'pie', 'treemap', or 'waffle'

// Computed values
const currentNetWorth = computed(() => {
  return Object.values(assets.value).reduce((sum, asset) => sum + Number(asset.value), 0)
})

const expectedAnnualReturn = computed(() => {
  let totalReturn = 0
  Object.values(assets.value).forEach(asset => {
    totalReturn += (Number(asset.value) * Number(asset.return)) / 100
  })
  return totalReturn
})

const fiveYearProjection = computed(() => {
  let projection = 0
  Object.values(assets.value).forEach(asset => {
    projection += asset.value * Math.pow(1 + asset.return / 100, 5)
  })
  return projection
})

const goalProgress = computed(() => {
  const progress = (currentNetWorth.value / 10000000) * 100
  return Math.min(Math.max(progress, 0), 100)
})

const potentialShortfall = computed(() => {
  const goalAmount = 10000000
  const projectedAmount = fiveYearProjection.value
  return Math.max(goalAmount - projectedAmount, 0)
})

const requiredAnnualReturn = computed(() => {
  const goalAmount = 10000000
  const currentAmount = currentNetWorth.value
  const years = 5

  if (currentAmount <= 0) return 0
  
  const rate = Math.pow(goalAmount / currentAmount, 1/years) - 1
  return rate * 100
})

// Formatting functions
function formatCurrency(value) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value)
}

function formatPercent(value) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }).format(value)
}

function formatLabel(key) {
  return key.replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// Update the asset value handling
function formatInputCurrency(value) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true
  }).format(value)
}

function updateAssetValue(key, value) {
  // Remove all non-numeric characters except decimal point
  const numericValue = value.replace(/[^0-9.]/g, '')
  assets.value[key].value = Number(numericValue)
}

function formatOnBlur(key, value) {
  // Ensure the display value is properly formatted when the input loses focus
  const numericValue = Number(value.replace(/[^0-9.]/g, ''))
  assets.value[key].value = numericValue
}

// Chart data calculation
function calculateProjectionData() {
  const startDate = new Date('2025-01-01')
  const months = Array.from({ length: 61 }, (_, i) => {
    const date = new Date(startDate)
    date.setMonth(startDate.getMonth() + i)
    return date
  })

  // Sort assets by their initial value (largest to smallest)
  const sortedAssets = Object.entries(assets.value)
    .sort((a, b) => b[1].value - a[1].value) // Sort largest to smallest
    .map(([key]) => key)

  const lineData = []
  const totalValues = new Array(months.length).fill(0)

  // Create individual lines for each asset
  sortedAssets.forEach(key => {
    const asset = assets.value[key]
    const values = months.map((_, i) => {
      const value = asset.value * Math.pow(1 + asset.return / 100 / 12, i) / 1000 // Convert to thousands
      totalValues[i] += value
      return value
    })

    lineData.push({
      x: months,
      y: values,
      type: 'scatter',
      mode: 'lines',
      name: formatLabel(key),
      line: {
        color: key === 'stocks' ? '#0000ff' :
               key === 'realEstate' ? '#008080' :
               key === 'business' ? '#ff0000' :
               key === 'bitcoin' ? '#ffff00' :
               '#ff00ff',
        width: 2
      }
    })
  })

  // Add total line
  lineData.push({
    x: months,
    y: totalValues,
    type: 'scatter',
    mode: 'lines',
    name: 'Net Worth',
    line: {
      color: '#33ff33',
      width: 2,
      dash: 'dot'
    }
  })

  // Calculate max value for y-axis range
  const maxValue = Math.max(...totalValues)
  const yAxisMax = Math.ceil(maxValue / 1000) * 1000 // Round up to nearest thousand

  return {
    data: lineData,
    yAxisMax
  }
}

// Chart configuration
const chartLayout = {
  paper_bgcolor: 'rgba(0,0,0,1)',
  plot_bgcolor: 'rgba(0,0,0,1)',
  font: {
    color: '#33ff33',
    family: 'JetBrains Mono, monospace',
    size: 10
  },
  margin: {
    l: 60,
    r: 20,
    t: 20,
    b: 60
  },
  xaxis: {
    showgrid: true,
    gridcolor: '#333333',
    gridwidth: 1,
    type: 'date',
    tickformat: '%b %Y',
    tickangle: -45,
    dtick: 'M6',
    showline: false,
    tickfont: {
      family: 'JetBrains Mono, monospace',
      color: '#33ff33'
    }
  },
  yaxis: {
    showgrid: true,
    gridcolor: '#333333',
    gridwidth: 1,
    tickprefix: '$',
    ticksuffix: 'K',
    range: [0, 12],
    showline: false,
    tickfont: {
      family: 'JetBrains Mono, monospace',
      color: '#33ff33'
    },
    tickformat: ',d'
  },
  showlegend: true,
  legend: {
    orientation: 'h',
    y: -0.15,
    x: 0.5,
    xanchor: 'center',
    yanchor: 'top',
    font: {
      size: 10,
      family: 'JetBrains Mono, monospace',
      color: '#33ff33'
    },
    bgcolor: 'rgba(0,0,0,1)',
    tracegroupgap: 5
  },
  hovermode: 'x unified',
  autosize: true
}

// Chart initialization and updates
function initChart() {
  if (!chartContainer.value) return

  const { data, yAxisMax } = calculateProjectionData()
  const layout = { 
    ...chartLayout, 
    width: chartContainer.value.offsetWidth,
    yaxis: {
      ...chartLayout.yaxis,
      range: [0, yAxisMax]
    }
  }
  
  Plotly.newPlot(chartContainer.value, data, layout, {
    displayModeBar: false,
    responsive: true
  })
}

// Add resize handler
let resizeTimeout
function handleResize() {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    if (chartContainer.value) {
      Plotly.relayout(chartContainer.value, {
        width: chartContainer.value.offsetWidth
      })
    }
  }, 100)
}

// Lifecycle hooks
onMounted(() => {
  window.addEventListener('resize', handleResize)
  initChart()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})

// Watch for changes in assets to update chart
watch(assets, () => {
  initChart()
}, { deep: true })

// Handle connection status changes
function handleConnectionChange(connected) {
  // Update UI or state based on connection status
  console.log('Connection status:', connected ? 'Connected' : 'Disconnected')
}
</script>

<style scoped>
.dashboard-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 10px;
  padding: 10px;
  height: calc(100vh - 60px);
}

.dashboard-panel {
  background-color: var(--panel-bg);
  border: 1px solid var(--terminal-border);
  padding: 15px;
  border-radius: 5px;
}

.dashboard-panel.full-width {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.terminal-status > span:nth-child(1) {
  color: var(--terminal-dim);
  padding-right: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.asset-row {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--terminal-border);
}

.asset-row:last-child {
  border-bottom: none;
}

.asset-label {
  width: 120px;
  color: var(--terminal-text);
  font-size: 0.9rem;
}

.input-container {
  display: grid;
  grid-template-columns: 180px 100px auto;
  gap: 1rem;
  align-items: center;
  flex: 1;
}

.value-input {
  width: 180px;
}

.return-input {
  width: 100px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.currency-symbol,
.percentage-symbol {
  position: absolute;
  color: var(--terminal-dim);
}

.currency-symbol {
  left: 0.5rem;
}

.percentage-symbol {
  right: 0.5rem;
}

.input-wrapper input {
  width: 100%;
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem;
  border-radius: 4px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9rem;
}

.value-input input {
  text-align: right;
  padding-right: 0.5rem;
  padding-left: 1.5rem;
}

.return-input input {
  text-align: center;
  padding-right: 1.5rem;
}

label {
  color: var(--terminal-text);
  font-size: 0.9rem;
}

.summary-stats {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: var(--terminal-bg);
  border-radius: 4px;
}

.stat-label {
  color: var(--terminal-dim);
  font-size: 0.9rem;
}

.stat-value {
  font-size: 1.1rem;
  font-weight: bold;
}

.stat-value.positive {
  color: var(--status-healthy);
}

.goal-tracker {
  margin-top: 2rem;
  padding: .75rem .4rem 0;
  border-radius: 4px;
}

.progress-bar {
  height: 6px;
  background: var(--terminal-border);
  border-radius: 3px;
  margin: 1rem 0;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: var(--status-healthy);
  transition: width 0.3s ease;
}

.goal-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.stat {
  text-align: center;
}

.stat .label {
  color: var(--terminal-dim);
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.stat .value {
  font-size: 1.1rem;
  font-weight: bold;
}

h3, h4 {
  color: var(--terminal-text);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.goal-section {
  padding: 1rem;
  margin-bottom: 0;
}

.chart-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--terminal-bg);
  min-height: 450px;
}

.chart-container {
  flex: 1;
  min-height: 450px;
  border-radius: 4px;
  padding: 0;
  margin-top: 0;
  position: relative;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.viz-selector {
  display: flex;
  gap: 0.5rem;
}

.viz-selector button {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-dim);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.8rem;
  cursor: pointer;
}

.viz-selector button:hover {
  border-color: var(--terminal-text);
  color: var(--terminal-text);
}

.viz-selector button.active {
  border-color: var(--status-healthy);
  color: var(--status-healthy);
}

.scenario-buttons {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
}

.scenario-buttons button {
  background: var(--terminal-bg);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-dim);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.7rem;
  cursor: pointer;
  min-width: 60px;
}

.scenario-buttons button[data-scenario="bear"]:hover,
.scenario-buttons button[data-scenario="bear"].active {
  border-color: var(--status-error);
  color: var(--status-error);
}

.scenario-buttons button[data-scenario="base"]:hover,
.scenario-buttons button[data-scenario="base"].active {
  border-color: var(--status-warning);
  color: var(--status-warning);
}

.scenario-buttons button[data-scenario="expected"]:hover,
.scenario-buttons button[data-scenario="expected"].active {
  border-color: #00ffff;
  color: #00ffff;
}

.scenario-buttons button[data-scenario="growth"]:hover,
.scenario-buttons button[data-scenario="growth"].active {
  border-color: var(--status-healthy);
  color: var(--status-healthy);
}
</style>