<template>
  <div class="dashboard-panel">
    <div class="panel-header">Price Projections</div>
    <div class="panel-content">
      <div ref="priceChart"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import Plotly from 'plotly.js-dist-min'

const priceChart = ref(null)

const PLOTLY_CONFIG = {
  responsive: true,
  displayModeBar: false,
  displaylogo: false
}

// Chart configuration (matching terminal.js theme)
const layout = {
  paper_bgcolor: 'rgba(0,0,0,0)',
  plot_bgcolor: 'rgba(0,0,0,0)',
  font: {
    color: '#33ff33',
    family: 'JetBrains Mono, monospace',
    size: 14
  },
  margin: {
    l: 60,
    r: 40,
    t: 40,
    b: 60
  },
  showlegend: true,
  legend: {
    x: 0.02,
    y: 0.98,
    bgcolor: 'rgba(0,0,0,0)',
    bordercolor: '#1a1a1a',
    font: {
      color: '#33ff33',
      size: 12
    }
  },
  xaxis: {
    showgrid: true,
    gridcolor: '#1a1a1a',
    gridwidth: 1,
    zerolinecolor: '#1a1a1a',
    automargin: true,
    tickfont: {
      family: 'JetBrains Mono, monospace',
      size: 14,
      color: '#33ff33'
    },
    title: {
      text: 'Time Horizon',
      font: {
        color: '#33ff33',
        size: 12
      }
    }
  },
  yaxis: {
    showgrid: true,
    gridcolor: '#1a1a1a',
    gridwidth: 1,
    zerolinecolor: '#1a1a1a',
    tickprefix: '$',
    tickformat: ',',
    tickfont: {
      family: 'JetBrains Mono, monospace',
      size: 12,
      color: '#33ff33'
    }
  },
  hovermode: 'x unified',
  hoverlabel: {
    bgcolor: '#1a1a1a',
    bordercolor: '#33ff33',
    font: {
      color: '#33ff33',
      family: 'JetBrains Mono, monospace'
    }
  }
}

function initChart() {
  if (!priceChart.value) return

  const trace = {
    x: ['Current'],
    y: [0],
    type: 'scatter',
    mode: 'lines+markers',
    name: 'Price',
    line: {
      color: '#33ff33',
      width: 2
    },
    marker: {
      color: '#33ff33',
      size: 8
    }
  }

  Plotly.newPlot(priceChart.value, [trace], layout, PLOTLY_CONFIG)
}

function updateChart(data) {
  if (!priceChart.value || !data) return

  try {
    // Extract price data from the simulation results
    const timePoints = ['Current', '30d', '180d', '365d', '730d', '1460d']
    const days = ['30', '180', '365', '730', '1460']

    // Initialize data arrays
    const targetPrices = {
      x: timePoints,
      y: [parseFloat(data.current_price)]
    }

    const highestPrices = {
      x: timePoints,
      y: [parseFloat(data.current_price)]
    }

    const averagePrices = {
      x: timePoints,
      y: [parseFloat(data.current_price)]
    }

    // Add projections for each timepoint
    days.forEach(days => {
      const projection = data.projections[days]
      if (projection) {
        targetPrices.y.push(parseFloat(projection.target))
        highestPrices.y.push(parseFloat(projection.highest))
        averagePrices.y.push(parseFloat(projection.average))
      } else {
        targetPrices.y.push(null)
        highestPrices.y.push(null)
        averagePrices.y.push(null)
      }
    })

    // Calculate percentage changes for hover text
    const getHoverText = (prices) => {
      return prices.y.map((price, i) => {
        if (price === null || i === 0) return ''
        const change = ((price - prices.y[0]) / prices.y[0] * 100).toFixed(2)
        const sign = change >= 0 ? '+' : ''
        return `${sign}${change}% from current`
      })
    }

    // Calculate y-axis range
    const allPrices = [...targetPrices.y, ...highestPrices.y, ...averagePrices.y]
      .filter(p => p !== null && !isNaN(p))
    const maxPrice = Math.max(...allPrices) * 1.1
    const minPrice = Math.min(...allPrices) * 0.9

    const traces = [
      {
        x: targetPrices.x,
        y: targetPrices.y,
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Target Price',
        line: {
          color: '#33ff33',
          width: 2
        },
        marker: {
          color: '#33ff33',
          size: 8
        },
        hovertemplate:
          'Target: $%{y:,.0f}<br>' +
          '%{text}<br>' +
          '<extra></extra>',
        text: getHoverText(targetPrices)
      },
      {
        x: highestPrices.x,
        y: highestPrices.y,
        type: 'scatter',
        mode: 'lines',
        name: 'Highest Price',
        line: {
          color: '#ff3333',
          width: 2,
          dash: 'dot'
        },
        hovertemplate:
          'Highest: $%{y:,.0f}<br>' +
          '%{text}<br>' +
          '<extra></extra>',
        text: getHoverText(highestPrices)
      },
      {
        x: averagePrices.x,
        y: averagePrices.y,
        type: 'scatter',
        mode: 'lines',
        name: 'Average Price',
        line: {
          color: '#3333ff',
          width: 2,
          dash: 'dash'
        },
        hovertemplate:
          'Average: $%{y:,.0f}<br>' +
          '%{text}<br>' +
          '<extra></extra>',
        text: getHoverText(averagePrices)
      }
    ]

    const updatedLayout = {
      ...layout,
      yaxis: {
        ...layout.yaxis,
        range: [minPrice, maxPrice]
      }
    }

    Plotly.react(priceChart.value, traces, updatedLayout, PLOTLY_CONFIG)
  } catch (error) {
    console.error('Error updating chart:', error)
  }
}

defineExpose({
  updateChart
})

onMounted(() => {
  initChart()
})

onUnmounted(() => {
  if (priceChart.value) {
    Plotly.purge(priceChart.value)
  }
})
</script> 