<template>
  <div class="csp-screener-view">
    <TerminalHeader title="Cash Secured Puts Screener" />
    
    <CollapsiblePanel 
      title="🔍 Screening Criteria"
      :initial-collapsed="isFiltersCollapsed"
      @collapse-change="isFiltersCollapsed = $event"
    >
      <template #header-right>
        <div class="strategy-buttons">
          <button 
            v-for="preset in presets"
            :key="preset.name"
            class="strategy-btn" 
            :data-scenario="preset.name"
            :class="{ active: activePreset === preset.name }"
            @click="applyPreset(preset.name)"
          >{{ preset.label }}</button>
        </div>
      </template>

      <div class="filter-groups">
        <div v-for="group in filterGroups" :key="group.title" class="filter-group">
          <h3>{{ group.title }}</h3>
          <div class="filter-row">
            <div v-for="filter in group.filters" :key="filter.key" class="filter-item">
              <label>{{ filter.label }}</label>
              <template v-if="filter.type === 'toggle'">
                <div class="toggle-switch">
                  <input 
                    type="checkbox" 
                    v-model="filters[filter.key]" 
                    :id="filter.key + '-toggle'"
                  >
                  <label :for="filter.key + '-toggle'"></label>
                </div>
              </template>
              <template v-else>
                <input 
                  type="number" 
                  v-model="filters[filter.key]"
                  :min="filter.min"
                  :max="filter.max"
                  :step="filter.step"
                >
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="filter-actions">
        <button class="action-btn" @click="applyFilters">Apply Filters</button>
        <button class="action-btn reset" @click="resetFilters">Reset</button>
      </div>
    </CollapsiblePanel>

    <CollapsiblePanel 
      title="💰 Cash Secured Puts Opportunities"
      :initial-collapsed="isResultsCollapsed"
      @collapse-change="isResultsCollapsed = $event"
    >
      <template #header-right>
        <div class="results-meta" v-if="results.length">
          <span>{{ results.length }} results found</span>
          <span v-if="loading" class="loading-indicator">Refreshing...</span>
        </div>
      </template>

      <ResultsTable
        :results="results"
        :columns="columns"
        :loading="loading"
        :error="error"
        :loadingMessage="'Searching for opportunities...'"
        @row-click="showDetails"
        @retry="applyFilters"
      />
    </CollapsiblePanel>

    <div v-if="selectedOption" class="detail-panel">
      <div class="detail-header">
        <h3>{{ selectedOption.ticker }} - ${{ selectedOption.strike }} Put ({{ formatDate(selectedOption.expiry) }})</h3>
        <button class="close-btn" @click="selectedOption = null">×</button>
      </div>
      <div class="detail-content">
        <div class="detail-section">
          <h4>Company Information</h4>
          <div class="detail-row">
            <span>Company:</span>
            <span>{{ selectedOption.company_name }}</span>
          </div>
          <div class="detail-row">
            <span>Sector:</span>
            <span>{{ selectedOption.sector }}</span>
          </div>
          <div class="detail-row">
            <span>Industry:</span>
            <span>{{ selectedOption.industry }}</span>
          </div>
        </div>

        <div class="detail-section">
          <h4>Option Details</h4>
          <div class="detail-row">
            <span>Strike Price:</span>
            <span>${{ selectedOption.strike.toFixed(2) }}</span>
          </div>
          <div class="detail-row">
            <span>Current Stock Price:</span>
            <span>${{ selectedOption.stock_price.toFixed(2) }}</span>
          </div>
          <div class="detail-row">
            <span>Option Premium:</span>
            <span>${{ selectedOption.option_price.toFixed(2) }}</span>
          </div>
          <div class="detail-row">
            <span>Days to Expiry:</span>
            <span>{{ Math.round(selectedOption.days_to_expiry) }}</span>
          </div>
          <div class="detail-row">
            <span>Implied Volatility:</span>
            <span>{{ selectedOption.implied_volatility.toFixed(2) }}%</span>
          </div>
          <div class="detail-row">
            <span>Delta:</span>
            <span>{{ selectedOption.delta.toFixed(4) }}</span>
          </div>
        </div>

        <div class="detail-section">
          <h4>Return Analysis</h4>
          <div class="detail-row">
            <span>Premium Percent:</span>
            <span>{{ selectedOption.premium_percent.toFixed(2) }}%</span>
          </div>
          <div class="detail-row">
            <span>Annualized Return:</span>
            <span>{{ selectedOption.annualized_return.toFixed(2) }}%</span>
          </div>
          <div class="detail-row">
            <span>Discount to Current Price:</span>
            <span>{{ selectedOption.discount_to_current_price.toFixed(2) }}%</span>
          </div>
          <div class="detail-row">
            <span>Break-Even Price:</span>
            <span>${{ (selectedOption.strike - selectedOption.option_price).toFixed(2) }}</span>
          </div>
          <div class="detail-row">
            <span>Downside Protection:</span>
            <span>{{ ((selectedOption.option_price / selectedOption.strike) * 100).toFixed(2) }}%</span>
          </div>
        </div>

        <div class="detail-section">
          <h4>Fundamental Metrics</h4>
          <div class="detail-row">
            <span>P/E Ratio:</span>
            <span>{{ selectedOption.pe_ratio ? selectedOption.pe_ratio.toFixed(2) : 'N/A' }}</span>
          </div>
          <div class="detail-row">
            <span>P/B Ratio:</span>
            <span>{{ selectedOption.pb_ratio ? selectedOption.pb_ratio.toFixed(2) : 'N/A' }}</span>
          </div>
          <div class="detail-row">
            <span>Debt/Equity Ratio:</span>
            <span>{{ selectedOption.debt_equity_ratio ? selectedOption.debt_equity_ratio.toFixed(2) : 'N/A' }}</span>
          </div>
          <div class="detail-row">
            <span>Current Ratio:</span>
            <span>{{ selectedOption.current_ratio ? selectedOption.current_ratio.toFixed(2) : 'N/A' }}</span>
          </div>
          <div class="detail-row">
            <span>Dividend Yield:</span>
            <span>{{ selectedOption.dividend_yield ? (selectedOption.dividend_yield * 100).toFixed(2) + '%' : 'N/A' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
import CollapsiblePanel from '@/components/shared/CollapsiblePanel.vue'
import ResultsTable from '@/components/shared/ResultsTable.vue'
import api from '@/utils/api'

export default {
  name: 'CashSecuredPutsScreener',
  components: {
    TerminalHeader,
    CollapsiblePanel,
    ResultsTable
  },
  data() {
    return {
      selectedOption: null,
      isFiltersCollapsed: false,
      isResultsCollapsed: false,
      activePreset: null,
      loading: false,
      error: null,
      results: [],
      filters: { ...this.defaultFilters },
      defaultFilters: {
        minAnnualizedReturn: 12,
        minPremiumPercent: 0.5,
        maxDelta: 0.30,
        otmOnly: false,
        minDaysToExpiry: 14,
        maxDaysToExpiry: 60,
        minOpenInterest: 50,
        minVolume: 10,
        minStockPrice: 1,
        maxStockPrice: null,
        maxPeRatio: null,
        maxDebtEquityRatio: null,
      },
      presets: [
        {
          name: 'conservative',
          label: 'Conservative',
          filters: {
            minAnnualizedReturn: 8,
            minPremiumPercent: 0.5,
            maxDelta: 0.20,
            otmOnly: true,
            minDaysToExpiry: 30,
            maxDaysToExpiry: 60,
            minOpenInterest: 100,
            minVolume: 20,
            minStockPrice: 1,
            maxStockPrice: null,
            maxPeRatio: 25,
            maxDebtEquityRatio: 1.0,
          }
        },
        {
          name: 'balanced',
          label: 'Balanced',
          filters: {
            minAnnualizedReturn: 12,
            minPremiumPercent: 0.8,
            maxDelta: 0.35,
            otmOnly: true,
            minDaysToExpiry: 21,
            maxDaysToExpiry: 45,
            minOpenInterest: 50,
            minVolume: 10,
            minStockPrice: 1,
            maxStockPrice: null,
            maxPeRatio: 35,
            maxDebtEquityRatio: 1.5,
          }
        },
        {
          name: 'aggressive',
          label: 'Aggressive',
          filters: {
            minAnnualizedReturn: 30,
            minPremiumPercent: 1.0,
            maxDelta: 0.60,
            otmOnly: false,
            minDaysToExpiry: 7,
            maxDaysToExpiry: 30,
            minOpenInterest: 25,
            minVolume: 5,
            minStockPrice: 1,
            maxStockPrice: null,
            maxPeRatio: 50,
            maxDebtEquityRatio: 2.0,
          }
        },
        {
          name: 'yolo',
          label: 'YOLO',
          filters: {
            minAnnualizedReturn: 50,
            minPremiumPercent: 2.0,
            maxDelta: 0.80,
            otmOnly: false,
            minDaysToExpiry: 30,
            maxDaysToExpiry: 90,
            minOpenInterest: 10,
            minVolume: 1,
            minStockPrice: 1,
            maxStockPrice: null,
            maxPeRatio: null,
            maxDebtEquityRatio: null,
          }
        }
      ],
      filterGroups: [
        {
          title: 'Return Requirements (%)',
          filters: [
            { key: 'minAnnualizedReturn', label: 'Min Return / yr', min: 0, step: 1 },
            { key: 'minPremiumPercent', label: 'Min Premium', min: 0, step: 0.1 }
          ]
        },
        {
          title: 'Risk Management',
          filters: [
            { key: 'maxDelta', label: 'Max Delta', min: 0, max: 1, step: 0.01 },
            { key: 'otmOnly', label: 'OTM Only', type: 'toggle' }
          ]
        },
        {
          title: 'Expiration Timeframe',
          filters: [
            { key: 'minDaysToExpiry', label: 'Min DTE', min: 0, step: 1 },
            { key: 'maxDaysToExpiry', label: 'Max DTE', min: 0, step: 1 }
          ]
        },
        {
          title: 'Liquidity Requirements',
          filters: [
            { key: 'minOpenInterest', label: 'Min OI', min: 0, step: 10 },
            { key: 'minVolume', label: 'Min Volume', min: 0, step: 1 }
          ]
        },
        {
          title: 'Stock Price ($)',
          filters: [
            { key: 'minStockPrice', label: 'Min Stock Price', min: 0, step: 1 },
            { key: 'maxStockPrice', label: 'Max Stock Price', min: 0, step: 1 }
          ]
        },
        {
          title: 'Fundamental Ratio Filters',
          filters: [
            { key: 'maxPeRatio', label: 'Max P/E', min: 0, step: 1 },
            { key: 'maxDebtEquityRatio', label: 'Max Debt/Equity', min: 0, step: 0.1 }
          ]
        }
      ],
      columns: [
        { key: 'ticker', label: 'Ticker' },
        { key: 'company_name', label: 'Company' },
        { key: 'sector', label: 'Sector' },
        { key: 'expiry', label: 'Expiry', format: (val) => val ? this.formatDate(val) : 'N/A' },
        { key: 'days_to_expiry', label: 'DTE', format: (val) => val !== null ? Math.round(val) : 'N/A' },
        { key: 'strike', label: 'Strike', format: (val) => val !== null ? `$${val.toFixed(2)}` : 'N/A' },
        { key: 'stock_price', label: 'Stock Price', format: (val) => val !== null ? `$${val.toFixed(2)}` : 'N/A' },
        { key: 'option_price', label: 'Option Price', format: (val) => val !== null ? `$${val.toFixed(2)}` : 'N/A' },
        { 
          key: 'breakEvenPrice', 
          label: 'Break-Even',
          format: (_, row) => row.strike !== null && row.option_price !== null ? 
            `$${(row.strike - row.option_price).toFixed(2)}` : 'N/A'
        },
        { 
          key: 'downsideProtection', 
          label: 'Protection %',
          format: (_, row) => row.option_price !== null && row.strike !== null ? 
            `${((row.option_price / row.strike) * 100).toFixed(2)}%` : 'N/A'
        },
        { key: 'premium_percent', label: 'Premium %', format: (val) => val !== null ? `${val.toFixed(2)}%` : 'N/A' },
        { key: 'annualized_return', label: 'Ann. Return', format: (val) => val !== null ? `${val.toFixed(2)}%` : 'N/A' },
        { key: 'delta', label: 'Delta', format: (val) => val !== null ? val.toFixed(4) : 'N/A' },
        { 
          key: 'moneyness', 
          label: 'Status',
          format: (val) => val ? `<span class="${val === 'OTM' ? 'otm' : 'itm'}">${val}</span>` : 'N/A'
        },
        { key: 'open_interest', label: 'OI' }
      ]
    }
  },
  methods: {
    async applyFilters() {
      this.loading = true;
      this.error = null;
      
      try {
        const params = {};
        Object.entries(this.filters).forEach(([key, value]) => {
          if (value !== null && value !== '') {
            params[key] = value;
          }
        });
        
        const response = await api.get('/options/screener', { 
          params: {
            ...params,
            limit: 200
          }
        });
        
        if (response.data.success) {
          this.results = response.data.results;
        } else {
          this.error = response.data.error || 'Failed to fetch results';
        }
      } catch (error) {
        console.error('Error fetching screener results:', error);
        this.error = error.message || 'An error occurred while fetching results';
      } finally {
        this.loading = false;
      }
    },
    
    resetFilters() {
      this.activePreset = null;
      this.filters = { ...this.defaultFilters };
      this.applyFilters();
    },
    
    applyPreset(presetName) {
      this.activePreset = presetName;
      const preset = this.presets.find(p => p.name === presetName);
      if (preset && preset.filters) {
        this.filters = { ...preset.filters };
        this.applyFilters();
      }
    },
    
    showDetails(option) {
      this.selectedOption = option;
    },
    
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      });
    }
  },
  mounted() {
    this.applyFilters();
  }
};
</script>

<style scoped>
.csp-screener-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #33ff33;
  background-color: #111;
  padding: 0 20px 20px;
  gap: 20px;
}

/* Strategy buttons */
.strategy-buttons {
  display: flex;
  gap: 0.5rem;
}

.strategy-btn {
  background: var(--terminal-bg, rgba(0, 0, 0, 0.4));
  border: 1px solid var(--terminal-border, rgba(51, 255, 51, 0.5));
  color: var(--terminal-dim, #aaffaa);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s;
}

.strategy-btn:hover {
  border-color: var(--terminal-text, #33ff33);
  color: var(--terminal-text, #33ff33);
}

.strategy-btn:active {
  transform: translateY(1px);
}

/* Filter groups */
.filter-groups {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.filter-group {
  margin-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px;
  border-left: 3px solid rgba(51, 255, 51, 0.5);
}

.filter-group h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 1em;
  border-bottom: 1px solid rgba(51, 255, 51, 0.3);
  padding-bottom: 6px;
  color: #33ff33;
}

.filter-row {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

.filter-item {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filter-item label {
  margin-bottom: 6px;
  font-size: 0.85em;
  color: #aaffaa;
}

/* Input styles */
input[type="number"] {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(51, 255, 51, 0.5);
  color: #33ff33;
  padding: 8px 10px;
  border-radius: 3px;
  height: 36px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s, box-shadow 0.2s;
}

input[type="number"]:focus {
  outline: none;
  border-color: #33ff33;
  box-shadow: 0 0 5px rgba(51, 255, 51, 0.5);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Toggle switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(51, 255, 51, 0.5);
  border-radius: 24px;
  transition: .3s;
}

.toggle-switch label:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: #33ff33;
  border-radius: 50%;
  transition: .3s;
}

.toggle-switch input:checked + label {
  background-color: rgba(51, 255, 51, 0.2);
}

.toggle-switch input:checked + label:before {
  transform: translateX(26px);
}

/* Filter actions */
.filter-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid rgba(51, 255, 51, 0.2);
}

.action-btn {
  background: var(--terminal-bg, rgba(0, 0, 0, 0.4));
  border: 1px solid var(--terminal-border, rgba(51, 255, 51, 0.5));
  color: var(--terminal-text, #33ff33);
  padding: 10px 18px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.action-btn:hover {
  border-color: var(--terminal-text, #33ff33);
}

.action-btn:active {
  transform: translateY(1px);
}

.action-btn.reset {
  color: #ff5555;
}

.action-btn.reset:hover {
  border-color: var(--status-error, #ff3333);
  color: var(--status-error, #ff3333);
}

/* Results meta */
.results-meta {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
}

.loading-indicator {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

/* Detail panel */
.detail-panel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 90%;
  background-color: #222;
  border: 1px solid #33ff33;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #33ff33;
}

.close-btn {
  background: none;
  border: none;
  color: #33ff33;
  font-size: 1.5em;
  cursor: pointer;
}

.detail-content {
  padding: 15px;
  max-height: 70vh;
  overflow-y: auto;
}

.detail-section {
  margin-bottom: 20px;
}

.detail-section h4 {
  color: #33ff33;
  margin-bottom: 10px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 0;
  border-bottom: 1px solid rgba(51, 255, 51, 0.1);
}

.detail-row span:first-child {
  font-weight: bold;
  color: #aaffaa;
}

@media (max-width: 768px) {
  .detail-panel {
    width: 95%;
  }
  
  .filter-groups {
    grid-template-columns: 1fr;
  }
  
  .filter-row {
    flex-direction: column;
    gap: 10px;
  }
  
  .filter-actions {
    flex-direction: column;
    gap: 10px;
  }
}

/* Preset button colors */
.strategy-buttons button[data-scenario="conservative"]:hover,
.strategy-buttons button[data-scenario="conservative"].active {
  border-color: var(--status-error, #ff3333);
  color: var(--status-error, #ff3333);
}

.strategy-buttons button[data-scenario="balanced"]:hover,
.strategy-buttons button[data-scenario="balanced"].active {
  border-color: var(--status-warning, #ffff33);
  color: var(--status-warning, #ffff33);
}

.strategy-buttons button[data-scenario="aggressive"]:hover,
.strategy-buttons button[data-scenario="aggressive"].active {
  border-color: var(--status-healthy, #33ff33);
  color: var(--status-healthy, #33ff33);
}

.strategy-buttons button[data-scenario="yolo"]:hover,
.strategy-buttons button[data-scenario="yolo"].active {
  border-color: #ff00ff;
  color: #ff00ff;
}

/* Status colors */
:deep(.otm) {
  color: #33ff33;
}

:deep(.itm) {
  color: #ff3333;
}
</style> 