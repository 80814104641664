<template>
  <div class="terminal">
    <TerminalHeader 
      title="Monte Carlo Dashboard"
      @connection-change="handleConnectionChange"
    />

    <div class="dashboard-container">
      <!-- Price Chart Panel -->
      <PriceProjections ref="priceChart" />

      <!-- Performance Panel -->
      <ProjectionValues ref="projectionValues" />

      <!-- Stats Panel -->
      <KeyStatistics ref="keyStats" />

      <!-- Server Log Panel -->
      <TerminalOutput 
        ref="serverLog"
        title="Server Log"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import PriceProjections from '@/components/terminal/PriceProjections.vue'
import ProjectionValues from '@/components/terminal/ProjectionValues.vue'
import KeyStatistics from '@/components/terminal/KeyStatistics.vue'
import TerminalOutput from '@/components/shared/TerminalOutput.vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
import api from '@/utils/api'

defineOptions({
  name: 'SimulationView'
})

let lastUpdate = Date.now()
let connectionAttempts = 0
const isConnected = ref(false)
const MAX_RETRY_ATTEMPTS = 3
const priceChart = ref(null)
const projectionValues = ref(null)
const keyStats = ref(null)
const serverLog = ref(null)

// Handle connection status changes from TerminalHeader
function handleConnectionChange(connected) {
  isConnected.value = connected
  if (connected) {
    serverLog.value?.appendLog('\x1b[32m=== Connected to simulation engine ===\x1b[0m')
  } else {
    serverLog.value?.appendLog('\x1b[31m=== Connection lost. Attempting to reconnect... ===\x1b[0m')
  }
}

async function fetchLogs() {
  try {
    const response = await api.get('/logs', {
      params: { since: lastUpdate }
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    
    if (!isConnected.value) {
      // Log first successful connection
      serverLog.value?.appendLog('\x1b[32m=== Connected to simulation engine ===\x1b[0m')
    }
    
    if (data.logs && data.logs.length > 0) {
      data.logs.forEach(log => {
        serverLog.value?.appendLog(log)
      })
      lastUpdate = data.timestamp || Date.now()

      // Refresh dashboard data when new logs arrive
      fetchDashboardData()
    }
    connectionAttempts = 0
  } catch (error) {
    console.error('Error fetching logs:', error)
    connectionAttempts++
    if (connectionAttempts >= MAX_RETRY_ATTEMPTS) {
      serverLog.value?.appendLog('\x1b[31m=== Maximum retry attempts reached. Please refresh the page to try again. ===\x1b[0m')
      return
    }
  }
}

async function fetchDashboardData() {
  try {
    const response = await api.get('/dashboard')
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()

    // Update chart
    priceChart.value?.updateChart(data)

    // Update stats
    keyStats.value?.updateStats({
      currentPrice: `$${data.current_price.toLocaleString()}`,
      projections: data.projections,
      totalSimulations: data.stats.total_simulations.toLocaleString(),
      processingTime: data.stats.processing_time
    })

    // Update projections table
    projectionValues.value?.updateTable(data)
  } catch (error) {
    console.error('Error fetching dashboard data:', error)
  }
}

onMounted(() => {
  // Initial welcome message
  serverLog.value?.appendLog('\x1b[32m=== Welcome to termFi Terminal ===\x1b[0m')
  serverLog.value?.appendLog('\x1b[37mConnecting to simulation engine...\x1b[0m')

  // Initial data fetch
  fetchDashboardData()

  // Poll for new logs every second, but only if we haven't exceeded retry attempts
  const pollInterval = setInterval(() => {
    if (connectionAttempts < MAX_RETRY_ATTEMPTS) {
      fetchLogs()
    } else {
      clearInterval(pollInterval)
    }
  }, 1000)

  // Add keyboard shortcut for clearing the terminal
  const handleKeydown = (e) => {
    // Ctrl+L or Cmd+K to clear terminal
    if ((e.ctrlKey && e.key === 'l') || (e.metaKey && e.key === 'k')) {
      e.preventDefault()
      if (serverLog.value?.logContent) {
        serverLog.value.logContent.innerHTML = ''
        serverLog.value.appendLog('\x1b[32m=== Terminal cleared ===\x1b[0m')
      }
    }
  }
  document.addEventListener('keydown', handleKeydown)

  // Poll for dashboard updates
  const dashboardInterval = setInterval(fetchDashboardData, 1000)

  // Cleanup
  onUnmounted(() => {
    clearInterval(pollInterval)
    clearInterval(dashboardInterval)
    document.removeEventListener('keydown', handleKeydown)
  })
})
</script>

<style>
.dashboard-panel h3 {
  font-size: 14px
}
</style>

