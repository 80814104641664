<template>
  <div class="waffle-container" ref="chartContainer"></div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Plotly from 'plotly.js-dist-min'

const props = defineProps({
  assets: {
    type: Object,
    required: true
  }
})

const chartContainer = ref(null)

function calculateWaffleData() {
  const sortedAssets = Object.entries(props.assets)
    .sort((a, b) => b[1].value - a[1].value)

  const total = Object.values(props.assets).reduce((sum, asset) => sum + asset.value, 0)
  const TOTAL_SQUARES = 100
  
  const x = []
  const y = []
  const text = []
  const hovertext = []
  const colors = []

  let currentSquare = 0
  sortedAssets.forEach(([key, asset]) => {
    const squareCount = Math.round((asset.value / total) * TOTAL_SQUARES)
    const label = formatLabel(key)
    const percent = ((asset.value / total) * 100).toFixed(1)
    const value = (asset.value / 1000).toLocaleString()
    
    const color = key === 'stocks' ? '#0000ff' :
                 key === 'realEstate' ? '#008080' :
                 key === 'business' ? '#ff0000' :
                 key === 'bitcoin' ? '#ffff00' :
                 '#ff00ff'
    
    for (let i = 0; i < squareCount && currentSquare < TOTAL_SQUARES; i++) {
      x.push(currentSquare % 10)
      y.push(9 - Math.floor(currentSquare / 10))
      text.push('■')
      hovertext.push(`${label}<br>$${value}K<br>${percent}%`)
      colors.push(color)
      currentSquare++
    }
  })

  return [{
    type: 'scatter',
    x,
    y,
    mode: 'text',
    text,
    hovertext,
    hoverinfo: 'text',
    textfont: {
      family: 'JetBrains Mono, monospace',
      size: 16,
      color: colors
    },
    hoverlabel: {
      bgcolor: '#333333',
      bordercolor: '#33ff33',
      font: {
        family: 'JetBrains Mono, monospace',
        size: 12,
        color: '#33ff33'
      }
    }
  }]
}

const chartLayout = {
  paper_bgcolor: 'rgba(0,0,0,1)',
  plot_bgcolor: 'rgba(0,0,0,1)',
  margin: {
    l: 20,
    r: 20,
    t: 40,
    b: 20
  },
  xaxis: {
    showgrid: false,
    zeroline: false,
    showticklabels: false,
    range: [-1, 10]
  },
  yaxis: {
    showgrid: false,
    zeroline: false,
    showticklabels: false,
    range: [-1, 10]
  },
  font: {
    family: 'JetBrains Mono, monospace',
    size: 11,
    color: '#33ff33'
  },
  showlegend: false,
  height: 300,
  title: {
    text: 'Asset Allocation',
    font: {
      family: 'JetBrains Mono, monospace',
      size: 12,
      color: '#33ff33'
    },
    y: 0.98
  }
}

function formatLabel(key) {
  return key.replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function initChart() {
  if (!chartContainer.value) return
  
  const data = calculateWaffleData()
  const layout = {
    ...chartLayout,
    width: chartContainer.value.offsetWidth
  }

  Plotly.newPlot(chartContainer.value, data, layout, {
    displayModeBar: false,
    responsive: true
  })
}

// Add resize handler
let resizeTimeout
function handleResize() {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    if (chartContainer.value) {
      Plotly.relayout(chartContainer.value, {
        width: chartContainer.value.offsetWidth
      })
    }
  }, 100)
}

// Lifecycle hooks
onMounted(() => {
  window.addEventListener('resize', handleResize)
  initChart()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  if (chartContainer.value) {
    Plotly.purge(chartContainer.value)
  }
})

// Watch for changes in assets
watch(() => props.assets, () => {
  initChart()
}, { deep: true })
</script>

<style scoped>
.waffle-container {
  width: 100%;
  height: 300px;
}
</style> 