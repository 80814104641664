/**
 * TODO: BTCExit Enhancement Plan
 * 
 * Strategy Comparisons:
 * - Compare covered calls to cash-secured puts (CSPs)
 *   - Show potential entry points back into BTC position
 *   - Compare premiums and break-even points
 *   - Analyze risk/reward profiles of each strategy
 * 
 * Long-term Analysis:
 * - Add comparison to long-term hold scenario
 *   - Calculate potential lost upside at different BTC price targets
 *   - Show historical BTC performance over similar timeframes
 *   - Add Monte Carlo simulation for potential BTC price paths
 * 
 * Tax Analysis:
 * - Add long-term capital gains analysis
 *   - Calculate tax implications of immediate sale vs holding
 *   - Compare after-tax returns of different strategies
 *   - Consider tax-loss harvesting opportunities with options
 * 
 * Loan Comparison:
 * - Compare strategy to BTC collateralized loan
 *   - Show interest costs vs option premiums
 *   - Analyze liquidation risks vs assignment risks
 *   - Calculate effective cost of capital
 * 
 * Personal Financial Impact Analysis:
 * - Analyze impact of short-term profit taking on family financial needs
 *   - Calculate monthly/annual income potential from premium collection
 *   - Compare immediate quality of life improvements vs long-term wealth building
 *   - Model different withdrawal rates and their impact on long-term returns
 *   - Consider inflation and purchasing power over time
 *   - Add lifestyle goal planning and milestone analysis
 * 
 * Additional Enhancements:
 * - Add volatility analysis and IV rank/percentile for options
 * - Implement rolling strategy analysis for covered calls
 * - Add risk metrics (Sharpe ratio, max drawdown, etc.)
 * - Create scenario analysis for different market conditions
 * - Add portfolio margin analysis for larger positions
 * - Implement dynamic strike selection based on technical levels
 * - Add correlation analysis between BTC and IBIT tracking error
 * - Create visualization for risk/reward profiles of each strategy
 * - Add alerts for key price levels and expiration dates
 * - Implement backtesting capability using historical data
 */

<template>
  <div class="terminal">
    <TerminalHeader 
      title="BTC Exit Plan Terminal"
      @connection-change="handleConnectionChange"
    />

    <div class="dashboard-container">
      <!-- Parameter Input Panel -->
      <div class="dashboard-panel">
        <h3>Exit Plan Parameters</h3>
        <div class="parameter-inputs">
          <div class="input-group">
            <label>BTC to Sell:</label>
            <input type="number" v-model="params.btcToSell" step="0.1" min="0.1" />
          </div>
          <div class="input-group">
            <label>Target DTE:</label>
            <input type="number" v-model="params.targetDTE" step="1" min="1" />
          </div>
          <div class="input-group">
            <label>Target Strike:</label>
            <input type="number" v-model="params.targetStrike" step="1" min="1" />
          </div>
          <div class="input-group">
            <label>Min Delta:</label>
            <input type="number" v-model="params.minDelta" step="0.05" min="0" max="1" />
          </div>
          <div class="input-group">
            <label>Max Delta:</label>
            <input type="number" v-model="params.maxDelta" step="0.05" min="0" max="1" />
          </div>
          <button @click="runAnalysis" :disabled="isAnalyzing">
            {{ isAnalyzing ? 'Analyzing...' : 'Run Analysis' }}
          </button>
        </div>
      </div>

      <!-- Market Data Panel -->
      <div class="dashboard-panel">
        <h3>Current Market Data</h3>
        <div class="market-data" v-if="marketData">
          <div class="data-row">
            <span class="label">BTC Price:</span>
            <span class="value">${{ formatNumber(marketData.btc) }}</span>
          </div>
          <div class="data-row">
            <span class="label">IBIT Price:</span>
            <span class="value">${{ formatNumber(marketData.ibit) }}</span>
          </div>
          <div class="data-row">
            <span class="label">IBIT Shares:</span>
            <span class="value">{{ formatNumber(marketData.shares) }}</span>
          </div>
          <div class="data-row">
            <span class="label">USD Value:</span>
            <span class="value">${{ formatNumber(marketData.usdValue) }}</span>
          </div>
        </div>
      </div>

      <!-- Strategy Results Panel -->
      <div class="dashboard-panel full-width">
        <h3>Covered Call Strategies</h3>
        <div class="strategy-table" v-if="strategies.length">
          <table>
            <thead>
              <tr>
                <th>Strike</th>
                <th>DTE</th>
                <th>Expiration</th>
                <th>Premium</th>
                <th>Premium/K</th>
                <th>Annual Yield</th>
                <th>Protection</th>
                <th>Break Even</th>
                <th>BTC Break Even</th>
                <th>Max Profit</th>
                <th>Total Proceeds</th>
                <th>Contracts</th>
                <th>Delta</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(strategy, index) in strategies" :key="index">
                <td>${{ formatNumber(strategy.strike) }}</td>
                <td>{{ strategy.daysToExpiry }}</td>
                <td>{{ new Date(strategy.expiration).toLocaleDateString() }}</td>
                <td>${{ formatCurrency(strategy.premium, true) }}</td>
                <td>${{ formatNumber(strategy.premium_last_quote) }}</td>
                <td>{{ formatNumber(strategy.annualizedYield) }}%</td>
                <td>{{ formatNumber(strategy.downsideProtection) }}%</td>
                <td>${{ formatNumber(strategy.breakEven) }}</td>
                <td>${{ formatNumber((strategy.breakEven / marketData.ibit) * marketData.btc) }}</td>
                <td>${{ formatCurrencyCeil(strategy.maxProfit) }}</td>
                <td>${{ formatCurrency(
                  (marketData.shares * strategy.strike) + // Value at strike price
                  (strategy.premium), // Total premium from contracts
                  true) }}</td>
                <td>{{ strategy.contractsWritten }}</td>
                <td>{{ formatNumber(strategy.delta * 100) }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TerminalHeader from '@/components/shared/TerminalHeader.vue'
import api from '@/utils/api'

defineOptions({
  name: 'BTCExitView'
})

// State
const params = ref({
  btcToSell: 1.0,
  targetDTE: 200,
  targetStrike: 60,
  minDelta: 0.2,
  maxDelta: 0.6
})

const isAnalyzing = ref(false)
const marketData = ref(null)
const strategies = ref([])
const serverLog = ref(null)

// Format numbers for display
function formatNumber(num) {
  return Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

// Format currency with commas and rounding
function formatCurrency(num, round = false) {
  const value = round ? Math.round(num) : num
  return Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

// Format currency with commas and ceiling
function formatCurrencyCeil(num) {
  return Number(Math.ceil(num)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

// Run the analysis
async function runAnalysis() {
  try {
    isAnalyzing.value = true
    serverLog.value?.appendLog('\x1b[37mInitiating BTC Exit Plan analysis...\x1b[0m')

    const response = await api.post('/btc/exit-plan', params.value)

    if (response.data) {
      // Update market data
      marketData.value = {
        btc: response.data.currentPrices.btc,
        ibit: response.data.currentPrices.ibit,
        shares: response.data.conversion.ibitShares,
        usdValue: response.data.conversion.usdValue
      }

      // Update strategies
      strategies.value = response.data.strategies

      // Log success
      serverLog.value?.appendLog('\x1b[32m=== Analysis completed successfully ===\x1b[0m')
      
      // Log strategy details
      if (response.data.strategies.length > 0) {
        serverLog.value?.appendLog('\n\x1b[36mTop Strategy Details:\x1b[0m')
        const top = response.data.strategies[0]
        serverLog.value?.appendLog(`Strike: $${formatNumber(top.strike)}`)
        serverLog.value?.appendLog(`DTE: ${top.daysToExpiry} days`)
        serverLog.value?.appendLog(`Premium: $${formatCurrency(top.premium, true)}`)
        serverLog.value?.appendLog(`Annual Yield: ${formatNumber(top.annualizedYield)}%`)
        serverLog.value?.appendLog(`Downside Protection: ${formatNumber(top.downsideProtection)}%`)
        serverLog.value?.appendLog(`Delta: ${formatNumber(top.delta * 100)}%`)
      }
    }
  } catch (error) {
    console.error('Error running analysis:', error)
    serverLog.value?.appendLog(`\x1b[31mError: ${error.message}\x1b[0m`)
  } finally {
    isAnalyzing.value = false
  }
}

// Handle connection status changes
function handleConnectionChange(connected) {
  if (connected) {
    serverLog.value?.appendLog('\x1b[32m=== Connected to BTC Exit Plan engine ===\x1b[0m')
  } else {
    serverLog.value?.appendLog('\x1b[31m=== Disconnected from BTC Exit Plan engine ===\x1b[0m')
  }
}

onMounted(() => {
  // Initial welcome message
  serverLog.value?.appendLog('\x1b[32m=== Welcome to BTC Exit Plan Terminal ===\x1b[0m')
  serverLog.value?.appendLog('\x1b[37mAdjust parameters and click Run Analysis to begin.\x1b[0m')
})
</script>

<style scoped>
.dashboard-container {
    font-size: 12px;
    display: grid;
    grid-template-rows: auto 1fr; /* First row auto-sized to content, second takes remaining space */
    gap: 10px;
    padding: 10px;
    height: calc(100vh - 60px);
}

.dashboard-panel.full-width {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.strategy-table {
    flex: 1;
    overflow-y: auto;
}

.parameter-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  color: var(--terminal-text);
  font-size: 0.9rem;
}

.input-group input {
  background: var(--terminal-bg-light);
  border: 1px solid var(--terminal-border);
  color: var(--terminal-text);
  padding: 0.5rem;
  border-radius: 4px;
}

button {
  grid-column: span 2;
  background: var(--terminal-accent);
  color: var(--terminal-text);
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.market-data {
  padding: 1rem;
}

.data-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--terminal-border);
}

.strategy-table {
  padding: 1rem;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem;
  text-align: right;
  border-bottom: 1px solid var(--terminal-border);
}

th {
  background: var(--terminal-bg-light);
  font-weight: bold;
}

tr:hover {
  background: var(--terminal-bg-light);
}
</style> 